import { Tabs } from "../Components/Tabs";
import { ExpiredSubscriptionPopup } from '../Components/Popups/ExpiredSubscriptionPopup';
import { NoPermissionPopup } from '../Components/Popups/NoPermissionPopup';
import { EditSearchListPopup } from '../Components/Popups/EditSearchListPopup';
import { SaveSearchPopup } from '../Components/Popups/SaveSearchPopup';
import { SavedSearchesPopup } from '../Components/Popups/SavedSearchesPopup';
import { SavedSearchConfirmDelete } from '../Components/Popups/SavedSearchConfirmDelete';
import SearchColumn from '../Components/SearchColumn';
import GraphColumn from '../Components/GraphColumn';
import { useState, useEffect, useRef } from 'react';
import $ from 'jquery';
import { Constants, VOLUME_SEARCH_FAILED_TEXT } from '../Components/Constants';
import React from "react";
import { withRouter } from 'react-router-dom';
import { Footer } from '../Components/Footer';
import '../Css/Search.css';
import '../Css/Tabs.css';
import '../Css/SearchVolume.css';
import { SubscribersOnly } from '../Components/Popups/SubscribersOnly';
import { checkPageAllowed, createPowerpointDownloadButtons, editSearchesOk, fillSavedSearch, generateTable, getClientInfo, handleMultiSubmit, minDate, months, propertyFormatText, resetCharts, resetSearchWords, saveSearchOk, savedSearchDeleteFunc, setStickyHeader, showAllSavedSearchesFunc, tabHandler } from "../Helpers/HelpersConstants";
import { NoPermissionLimitReachedPopup } from "../Components/Popups/NoVolumePermissionPopup";

const SearchVolumeNew = ({ location }) => {
    const { environment, userInfo } = location.state || {};
    if (!userInfo) window.location.href = ("/login");

    const [refactoredPost, setRefactoredPost] = useState({ searchTerms: [], property: "web" });
    const [showEditList, setShowEditList] = useState(false);
    const [showSavedSearchDelete, setShowSavedSearchDelete] = useState(false);
    const [savedSearchesToRender, setSavedSearchesToRender] = useState([]);
    const [showSubscribersOnly, setShowSubscribersOnly] = useState(false);
    const [showSaveSearch, setShowSaveSearch] = useState(false);
    const [saveSearchSubmit, setSaveSearchSubmit] = useState(false);
    const [saveSearchNameInput, setSaveSearchNameInput] = useState('');
    const [savedSearchToDelete, setSavedSearchToDelete] = useState({});
    const [showSavedSearches, setShowSavedSearches] = useState(false);
    const [showNoPermission, setShowNoPermission] = useState(false);
    const [scrollDown, setScrollDown] = useState(false);
    const [numberOfRunSearches, setNumberOfRunSearches] = useState(0);
    const [responseToPostVolume, setResponseToPostVolume] = useState();
    const [dateArray, setDateArray] = useState([]);
    const [volumeArray, setVolumeArray] = useState([]);
    const [searchDataObj, setSearchDataObj] = useState({});
    const [chartDataObject, setChartDataObject] = useState();
    const [showGraphText, setShowGraphText] = useState(true);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [selectedYearlyChartTerm, setSelectedYearlyChartTerm] = useState();
    const [selectedYearlyPercentChartTerm, setSelectedYearlyPercentChartTerm] = useState();
    const chartRef = useRef()
    const areaChartRef = useRef()
    const barChartRef = useRef()
    const volumeChartRef = useRef()
    const barSliderChartRef = useRef()
    const yearlyChartRef = useRef()
    const yearlyPercentChartRef = useRef()
    const [chartsLoaded, setChartsLoaded] = useState(false);

    useEffect(() => {
        addToRefactoredPost('email', userInfo.email);
        addToRefactoredPost('accountId', userInfo.accountId);
        tabHandler(window.location.pathname);
        getClientInfo(addToRefactoredPost);
        var header = document.getElementById("navBar");
        var sticky = header.offsetTop;
        window.onscroll = async () => await setStickyHeader(header, sticky, setScrollDown);
    }, []);

    useEffect(async () => {
        if (saveSearchSubmit) {
            const response = await fetch(environment.url + 'setsavedsearch', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ post: refactoredPost }),
            });
            let responseBool = await response.json();
            setSaveSearchSubmit(false);
        }
    }, [saveSearchSubmit])

    useEffect(() => {
        if (numberOfRunSearches > 0) {
            handleMultiSubmit({
                environment,
                userInfo,
                numberOfRunSearches,
                setShowGraphText,
                refactoredPost,
                chartRef,
                barChartRef,
                volumeChartRef,
                areaChartRef,
                setSearchDataObj,
                setResponseToPostVolume,
                setChartsLoaded,
                setFormSubmitted,
                setNumberOfRunSearches,
                setChartDataObject,
                setDateArray,
                setVolumeArray,
                searchType: Constants.VOLUME_TYPE
            });
        }
    }, [numberOfRunSearches]);

    useEffect(() => {
        createPowerpointDownloadButtons({
            refactoredPost,
            downloadContainerElement: document.getElementById("downloadContainer"),
            page: Constants.VOLUME_TYPE,
            isSubscriber: userInfo.isSubscriber,
            setShowSubscribersOnly,
            selectedYearlyChartTerm,
            selectedYearlyPercentChartTerm
        }
        );
    }, [refactoredPost, selectedYearlyChartTerm, selectedYearlyPercentChartTerm, chartsLoaded])

    const addToRefactoredPost = (field, value) => {
        setRefactoredPost(prevState => ({ ...prevState, [field]: value }));
    }
    //         alert("Not Allowed")
    //         return;
    //     }

    //     setShowGraphText(false);
    //     if (numberOfRunSearches === 0) {
    //         $("#loadingIcon").css('display', 'flex');
    //         resetCharts({ refactoredPost, chartRef, barChartRef, volumeChartRef, areaChartRef, page: Constants.VOLUME_TYPE });
    //     } else {
    //         $("#loadingDataText").append('.');
    //     }

    //     await fetch(environment.url + 'searchvolume/searchvolumedata', {
    //         method: 'POST',
    //         headers: {
    //             'Content-Type': 'application/json',
    //         },
    //         body: JSON.stringify({ post: refactoredPost }),
    //     }).then(response => response.json()).then(body => {

    //         if (body.status === 'error' && numberOfRunSearches < 4) {
    //             setNumberOfRunSearches(numberOfRunSearches + 1)
    //             return;
    //         } else if (body.status === 'error') {
    //             const writeToLogTableObj = { 'searchTerms': refactoredPost.searchTerms, 'searchCountry': refactoredPost.country, 'searchCategory': refactoredPost.category, 'searchStartDate': refactoredPost.startDate, 'searchEndDate': refactoredPost.endDate, 'email': refactoredPost.email, 'searchState': refactoredPost.state, 'searchRegion': refactoredPost.region, 'searchProperty': refactoredPost.property, 'status': body.status }

    //             writeToLogTable(writeToLogTableObj);

    //             fetch(`https://script.google.com/macros/s/AKfycbwZ3xpZfyU9adFSmokgREhxdkr1LklYD3qu9YA_79DYwLrJ9kKhuMnICLG8BgMoimwc/exec?firstname=${'Search Error - Index'}&&lastname=${sessionStorage.getItem('user')}&&email=${new Date()}&&ip=${refactoredPost.searchTerms}&&creation=${refactoredPost.country + " : " + refactoredPost.category + " : " + refactoredPost.startDate + " - " + refactoredPost.endDate}`)
    //             alert(VOLUME_SEARCH_FAILED_TEXT);
    //             window.location.reload();
    //             return;
    //         }

    //         const writeToLogTableObj = { 'searchTerms': refactoredPost.searchTerms, 'searchCountry': refactoredPost.country, 'searchCategory': refactoredPost.category, 'searchStartDate': refactoredPost.startDate, 'searchEndDate': refactoredPost.endDate, 'email': refactoredPost.email, 'searchState': refactoredPost.state, 'searchRegion': refactoredPost.region, 'searchProperty': refactoredPost.property, 'status': body.status }

    //         writeToLogTable(writeToLogTableObj);

    //         //Reduce user search volume count;

    //         const svi = body.response[0].result.map(item => {
    //             if (!item.monthly_searches) {
    //                 return null
    //             }
    //             const sviData = item.monthly_searches.map(data => ({ data: data.search_volume_index, date: `${months()[data.month - 1]} ${data.year}` })).reverse();
    //             return ({ keyword: item.keyword, sviData })
    //         }).filter(item => item);

    //         const returnedSearchTerms = body.response[0].result.map(item => item.keyword);
    //         setResponseToPostVolume(body.response[0].result);

    //         setSearchDataObj({ searchedFor: returnedSearchTerms, country: refactoredPost.country, category: refactoredPost.category, startDate: refactoredPost.startDate, endDate: refactoredPost.endDate, stateShorthand: refactoredPost.stateShorthand, regionShorthand: refactoredPost.regionShorthand, stateName: refactoredPost.state, region: refactoredPost.region, property: refactoredPost.property });

    //         const searchDataObjTemp = { searchedFor: returnedSearchTerms, country: refactoredPost.country, category: refactoredPost.category, startDate: refactoredPost.startDate, endDate: refactoredPost.endDate, stateShorthand: refactoredPost.stateShorthand, regionShorthand: refactoredPost.regionShorthand, stateName: refactoredPost.state, region: refactoredPost.region, property: refactoredPost.property }


    //         setTimeout(function () {
    //             var responseArray = body.response[0].result;
    //             const keywords = [];
    //             const monthlySearches = [];
    //             const monthlySearchesConcluded = [];
    //             const dateArrayConcluded = []
    //             const dateArray = [];
    //             const failedKeywords = [];
    //             let reduceNumber = 0;
    //             responseArray.forEach((searchData, x) => {
    //                 if (searchData.monthly_searches) {
    //                     keywords.push(searchData.keyword);
    //                     searchData.monthly_searches.forEach((item, i) => {
    //                         if (!monthlySearches[i]) { monthlySearches.push([]) }
    //                         if (!dateArray[i]) { dateArray.push([]) }
    //                         monthlySearches[i].push(item.search_volume);
    //                         if (x === 0) dateArray[i].push(months()[(item.month - 1)] + " " + item.year);
    //                     })
    //                 } else {
    //                     // No Data For This Keyword.
    //                     reduceNumber += 1;
    //                     failedKeywords.push(searchData.keyword);
    //                 }
    //             });
    //             if (body.noResultsWords.length > 0) {
    //                 alert("Sorry, no data returned for: " + body.noResultsWords.join(", ") + " - displaying remaining results.");
    //             }
    //             monthlySearches.forEach(searches => monthlySearchesConcluded.push(searches.join(",")));
    //             dateArray.forEach(dates => dateArrayConcluded.push(dates.join(",")));

    //             if (dateArrayConcluded.length === 0 || monthlySearchesConcluded.length === 0) {
    //                 alert("Sorry, there has been an error. This may be due to a far too restricted/small or far too large (greater than 30 queries) search or an issue with the Google API response.");
    //                 window.location.reload();
    //             }
    //             dateArrayConcluded.reverse(); // Needed for searchVolume page charts
    //             monthlySearchesConcluded.reverse(); // Needed for searchVolume page charts
    //             setDateArray(dateArrayConcluded);
    //             setVolumeArray(monthlySearchesConcluded);
    //             $(".GraphContainer").width('100%');
    //             setChartDataObject({ nameArray: keywords, volumeArray: monthlySearchesConcluded, dateArray: dateArrayConcluded, property: refactoredPost.property, page: Constants.VOLUME_TYPE })
    //             generateTable({ svi, country: refactoredPost.country, start: refactoredPost.startDate, end: refactoredPost.endDate });
    //             $("#loadingIcon").css('display', 'none');
    //             setNumberOfRunSearches(0)
    //             setFormSubmitted(false);
    //             setChartsLoaded(true)
    //             window.scrollTo({ top: 0, behavior: 'smooth' });
    //         }, 4000);
    //     }).catch(error => console.log("There has been an error: " + error));
    // };

    const requirementsObj = { countries: true, categories: false, statesRegions: false, startDate: true, endDate: true, properties: false, saveSearch: true, retrieveSavedSearches: true, wideNarrow: false, singleSearchTerm: false, minDate: minDate() }

    return (
        <div>
            {userInfo.isExpired && <ExpiredSubscriptionPopup />}
            {showSubscribersOnly && <SubscribersOnly setShowSubscribersOnly={setShowSubscribersOnly} />}
            {!userInfo.verified && <NoPermissionPopup />}
            {(userInfo.dailyLimitsReached.includes(Constants.VOLUME_TYPE) || showNoPermission) && <NoPermissionLimitReachedPopup dailyLimitReached={true} searchType={Constants.VOLUME_TYPE} />}
            {showSavedSearchDelete && <SavedSearchConfirmDelete savedSearchToDelete={savedSearchToDelete} showAllSavedSearchesFunc={() => showAllSavedSearchesFunc({ environment, setShowSavedSearchDelete, setShowSavedSearches, savedSearchDeleteFunc: savedSearchDeleteFunc, setSavedSearchesToRender, addToRefactoredPost, resetSearchWords: resetSearchWords({ addToRefactoredPost }) })} setShowSavedSearchDelete={setShowSavedSearchDelete} environment={environment} />}
            {showSaveSearch && <SaveSearchPopup setSaveSearchNameInput={setSaveSearchNameInput} setShowSaveSearch={setShowSaveSearch} saveSearchOk={saveSearchOk} addToRefactoredPost={addToRefactoredPost} setSaveSearchSubmit={setSaveSearchSubmit} />}
            {showEditList && (<EditSearchListPopup editSearchesOk={() => editSearchesOk} />)}
            {showSavedSearches && <SavedSearchesPopup savedSearchesOk={setShowSavedSearches} showAllSavedSearchesFunc={showAllSavedSearchesFunc} savedSearchesProps={{ environment, setShowSavedSearchDelete, setShowSavedSearches, savedSearchDeleteFunc: savedSearchDeleteFunc, setSavedSearchesToRender, addToRefactoredPost, setSavedSearchToDelete }} savedSearchesToRender={savedSearchesToRender} />}

            <Tabs window={window} selected={"searchVolume"} environment={environment} userInfo={userInfo} />
            <div class="SearchColumns">
                {userInfo && <SearchColumn refactoredPost={refactoredPost} setRefactoredPost={setRefactoredPost} environment={environment} requirementsObj={requirementsObj} isSubscriber={userInfo.isSubscriber} setShowSubscribersOnly={setShowSubscribersOnly} setShowSavedSearches={setShowSavedSearches} showAllSavedSearchesFunc={() => showAllSavedSearchesFunc({ environment, setShowSavedSearchDelete, setShowSavedSearches, savedSearchDeleteFunc: savedSearchDeleteFunc, setSavedSearchesToRender, addToRefactoredPost, resetSearchWords, setSavedSearchToDelete })} setShowEditList={setShowEditList} setShowSaveSearch={setShowSaveSearch} formSubmitted={formSubmitted} setFormSubmitted={setFormSubmitted} handleMultiSubmit={() => handleMultiSubmit({
                    environment,
                    userInfo,
                    numberOfRunSearches,
                    setShowGraphText,
                    refactoredPost,
                    chartRef,
                    barChartRef,
                    volumeChartRef,
                    areaChartRef,
                    setSearchDataObj,
                    setResponseToPostVolume,
                    setChartsLoaded,
                    setFormSubmitted,
                    setNumberOfRunSearches,
                    setChartDataObject,
                    setDateArray,
                    setVolumeArray,
                    searchType: Constants.VOLUME_TYPE
                })} userInfo={userInfo} setShowSavedSearchDelete={setShowSavedSearchDelete} />}

                <GraphColumn chartRef={chartRef} areaChartRef={areaChartRef} barChartRef={barChartRef} volumeChartRef={volumeChartRef} yearlyChartRef={yearlyChartRef} yearlyPercentChartRef={yearlyPercentChartRef} showGraphText={showGraphText} page={Constants.VOLUME_TYPE} barSliderChartRef={barSliderChartRef} chartDataObject={chartDataObject} searchDataObj={searchDataObj} downloadContainerElement={document.getElementById("downloadContainer")} isSubscriber={userInfo.isSubscriber} setShowSubscribersOnly={setShowSubscribersOnly} refactoredPost={refactoredPost} setSelectedYearlyChartTerm={setSelectedYearlyChartTerm} setSelectedYearlyPercentChartTerm={setSelectedYearlyPercentChartTerm} userInfo={userInfo} />
            </div>
            <Footer withExtras />
        </div>
    )
}

export default withRouter(SearchVolumeNew);