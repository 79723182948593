import { Link } from 'react-router-dom';
import '../Css/Tabs.css';
import { useHistory } from 'react-router-dom';

export const Tabs = ({ window, selected, environment, userInfo, changeUserInfo }) => {
    const history = useHistory();

    const handleDivClick = (toPathName) => {
        const variables = {
            environment,
            userInfo
        };

        // Use history.push to navigate to the new route and pass state
        history.push({
            pathname: toPathName,
            state: variables,
        });
    };

    return (
        <div id="tabContainer">
            <button type="button" id="volumeSearchTab" onClick={(e) => selected !== "searchVolume" && handleDivClick("/volumesearch")}>VOLUME TRENDS</button>
            <button type="button" id="volumeSearchPlusTab" onClick={(e) => selected !== "searchVolume+" && handleDivClick("/countrysearch")}>COUNTRY TRENDS</button>
            <button type="button" id="similarSearchTab" onClick={(e) => selected !== "similarSearch" && handleDivClick("/similarsearch")}>SIMILAR TERMS</button>
            <button type="button" id="reportsTab" onClick={(e) => selected !== "reports" && handleDivClick("/myreports")}>MY REPORTS</button>
            <button type="button" id="multiQueryTab" onClick={(e) => selected !== "searchIndex" && handleDivClick("/search")}>INDEX TRENDS</button>
        </div>
    )
};