import { Tabs } from "../Components/Tabs";
import { ExpiredSubscriptionPopup } from '../Components/Popups/ExpiredSubscriptionPopup';
import { NoPermissionPopup } from '../Components/Popups/NoPermissionPopup';
import { EditSearchListPopup } from '../Components/Popups/EditSearchListPopup';
import { SaveSearchPopup } from '../Components/Popups/SaveSearchPopup';
import { SavedSearchesPopup } from '../Components/Popups/SavedSearchesPopup';
import { SavedSearchConfirmDelete } from '../Components/Popups/SavedSearchConfirmDelete';
import SearchColumn from '../Components/SearchColumn';
import GraphColumn from '../Components/GraphColumn';
import { useState, useEffect, useRef } from 'react';
import $ from 'jquery';
import { Constants, SEARCH_FAILED_TEXT } from '../Components/Constants';
import React from "react";
import { Footer } from '../Components/Footer';
import '../Css/Search.css';
import '../Css/Tabs.css';
import { SubscribersOnly } from '../Components/Popups/SubscribersOnly';
import { createPowerpointDownloadButtons, editSearchesOk, fillSavedSearch, getClientInfo, getYYYYMMDDFromDate, handleMultiSubmit, minDate, months, propertyFormatText, removeEndCommas, resetCharts, saveSearchOk, savedSearchDeleteFunc, searchTermsTooLong, setStickyHeader, showAllSavedSearchesFunc, tabHandler, resetSearchWords, checkPageAllowed } from "../Helpers/HelpersConstants";
import { NoPermissionLimitReachedPopup } from "../Components/Popups/NoVolumePermissionPopup";
import { withRouter } from 'react-router-dom';

const SimilarSearchesNew = ({ location }) => {
    const { environment, userInfo } = location.state || {};
    if (!userInfo) window.location.href = ("/login");

    const [refactoredPost, setRefactoredPost] = useState({ narrowSearch: true, searchTerms: [], property: "web" });
    const [showEditList, setShowEditList] = useState(false);
    const [showSavedSearches, setShowSavedSearches] = useState(false);
    const [showSavedSearchDelete, setShowSavedSearchDelete] = useState(false);
    const [savedSearchesToRender, setSavedSearchesToRender] = useState([]);
    const [showSubscribersOnly, setShowSubscribersOnly] = useState(false);
    const [showSaveSearch, setShowSaveSearch] = useState(false);
    const [saveSearchSubmit, setSaveSearchSubmit] = useState(false);
    const [saveSearchNameInput, setSaveSearchNameInput] = useState('');
    const [savedSearchToDelete, setSavedSearchToDelete] = useState({});
    const [showNoPermission, setShowNoPermission] = useState(false);
    const [showExpiredSubscription, setShowExpiredSubscription] = useState(false);
    const [scrollDown, setScrollDown] = useState(false);
    const [isSubscriber, setIsSubscriber] = useState(false);
    const [numberOfRunSearches, setNumberOfRunSearches] = useState(0);
    const [responseToPostVolume, setResponseToPostVolume] = useState();
    const [dateArray, setDateArray] = useState([]);
    const [volumeArray, setVolumeArray] = useState([]);
    const [searchDataObj, setSearchDataObj] = useState({});
    const [chartDataObject, setChartDataObject] = useState();
    const [showGraphText, setShowGraphText] = useState(true);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [showDailyLimitReached, setShowDailyLimitReached] = useState(false);
    const [selectedYearlyChartTerm, setSelectedYearlyChartTerm] = useState();
    const [selectedYearlyPercentChartTerm, setSelectedYearlyPercentChartTerm] = useState();
    const chartRef = useRef()
    const areaChartRef = useRef()
    const barChartRef = useRef()
    const volumeChartRef = useRef()
    const barSliderChartRef = useRef()
    const yearlyChartRef = useRef()
    const yearlyPercentChartRef = useRef()
    const [chartsLoaded, setChartsLoaded] = useState(false);

    useEffect(() => {
        const email = userInfo.email;
        const accountId = userInfo.accountId;
        addToRefactoredPost('email', email);
        addToRefactoredPost('accountId', accountId);
        tabHandler(window.location.pathname);
        getClientInfo(addToRefactoredPost);

        var header = document.getElementById("navBar");
        var sticky = header.offsetTop;
        window.onscroll = async () => await setStickyHeader(header, sticky, setScrollDown);
    }, []);

    useEffect(() => {
        if (numberOfRunSearches > 0) {
            handleMultiSubmit({
                environment,
                userInfo,
                numberOfRunSearches,
                setShowGraphText,
                refactoredPost,
                chartRef,
                barChartRef,
                volumeChartRef,
                areaChartRef,
                setSearchDataObj,
                setResponseToPostVolume,
                setChartsLoaded,
                setFormSubmitted,
                setNumberOfRunSearches,
                setChartDataObject,
                setDateArray,
                setVolumeArray,
                searchType: Constants.SIMILAR_TYPE
            });
        }
    }, [numberOfRunSearches]);

    useEffect(() => {
        createPowerpointDownloadButtons({
            refactoredPost,
            downloadContainerElement: document.getElementById("downloadContainer"),
            page: Constants.SIMILAR_TYPE,
            isSubscriber: userInfo.isSubscriber,
            setShowSubscribersOnly,
            selectedYearlyChartTerm,
            selectedYearlyPercentChartTerm
        });
    }, [refactoredPost, selectedYearlyChartTerm, selectedYearlyPercentChartTerm, chartsLoaded])

    const addToRefactoredPost = (field, value) => {
        setRefactoredPost(prevState => ({ ...prevState, [field]: value }));
    }

    const requirementsObj = { countries: true, categories: false, statesRegions: false, startDate: true, endDate: true, properties: false, saveSearch: false, retrieveSavedSearches: false, wideNarrow: true, singleSearchTerm: true, minDate: minDate() }

    return (
        <div>
            {showExpiredSubscription && <ExpiredSubscriptionPopup setShowExpiredSubscription={setShowExpiredSubscription} />}
            {showSubscribersOnly && <SubscribersOnly setShowSubscribersOnly={setShowSubscribersOnly} />}
            {(userInfo.dailyLimitsReached.includes(Constants.SIMILAR_TYPE) || showNoPermission) && <NoPermissionPopup setShowNoPermission={setShowNoPermission} />}
            {showSavedSearchDelete && <SavedSearchConfirmDelete savedSearchToDelete={savedSearchToDelete}
                showAllSavedSearchesFunc={showAllSavedSearchesFunc} setShowSavedSearchDelete={setShowSavedSearchDelete} environment={environment} />}
            {showDailyLimitReached && <NoPermissionLimitReachedPopup close={setShowDailyLimitReached} dailyLimitReached={true} searchType={Constants.SIMILAR_TYPE} />}
            {showSaveSearch && <SaveSearchPopup setSaveSearchNameInput={setSaveSearchNameInput} setShowSaveSearch={setShowSaveSearch} saveSearchOk={saveSearchOk} addToRefactoredPost={addToRefactoredPost} setSaveSearchSubmit={setSaveSearchSubmit} />}
            {showEditList && (<EditSearchListPopup editSearchesOk={(e) => editSearchesOk} />)}
            {showSavedSearches && <SavedSearchesPopup savedSearchesOk={setShowSavedSearches} showAllSavedSearchesFunc={showAllSavedSearchesFunc} savedSearchesToRender={savedSearchesToRender} />}

            <Tabs window={window} selected={"similar"} environment={environment} userInfo={userInfo} />
            <div class="SearchColumns">
                {userInfo && <SearchColumn refactoredPost={refactoredPost} setRefactoredPost={setRefactoredPost} environment={environment} requirementsObj={requirementsObj} isSubscriber={userInfo.isSubscriber} setShowSubscribersOnly={setShowSubscribersOnly} setShowSavedSearches={setShowSavedSearches} showAllSavedSearchesFunc={() => showAllSavedSearchesFunc({ environment, setShowSavedSearchDelete, setShowSavedSearches, savedSearchDeleteFunc: savedSearchDeleteFunc, setSavedSearchesToRender, addToRefactoredPost, resetSearchWords })} setShowEditList={setShowEditList} setShowSaveSearch={setShowSaveSearch} formSubmitted={formSubmitted} setFormSubmitted={setFormSubmitted} handleMultiSubmit={() => handleMultiSubmit({
                    environment,
                    userInfo,
                    numberOfRunSearches,
                    setShowGraphText,
                    refactoredPost,
                    chartRef,
                    barChartRef,
                    volumeChartRef,
                    areaChartRef,
                    setSearchDataObj,
                    setResponseToPostVolume,
                    setChartsLoaded,
                    setFormSubmitted,
                    setNumberOfRunSearches,
                    setChartDataObject,
                    setDateArray,
                    setVolumeArray,
                    searchType: Constants.SIMILAR_TYPE
                })} userInfo={userInfo} setShowSavedSearchDelete={setShowSavedSearchDelete} />}

                <GraphColumn chartRef={chartRef} areaChartRef={areaChartRef} barChartRef={barChartRef} volumeChartRef={volumeChartRef} yearlyChartRef={yearlyChartRef} yearlyPercentChartRef={yearlyPercentChartRef} showGraphText={showGraphText} page={Constants.SIMILAR_TYPE} barSliderChartRef={barSliderChartRef} chartDataObject={chartDataObject} searchDataObj={searchDataObj} downloadContainerElement={document.getElementById("downloadContainer")} isSubscriber={userInfo.isSubscriber} setShowSubscribersOnly={setShowSubscribersOnly} refactoredPost={refactoredPost} setSelectedYearlyChartTerm={setSelectedYearlyChartTerm} setSelectedYearlyPercentChartTerm={setSelectedYearlyPercentChartTerm} userInfo={userInfo} />
            </div>
            <Footer withExtras />
        </div>
    )
}

export default withRouter(SimilarSearchesNew);