import React, { useEffect, useState } from 'react';
import '../Css/AccountPage.css';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import paymentsImage from '../../images/paymentsImage.png';
import { Footer } from "../Components/Footer.js";
import HelperFunctions from '../Helpers/HelperFunctions.js';


const AccountPageNew = () => {
  const helperFunctions = new HelperFunctions;
  var environmentUrl = 'https://servershareofsearch.herokuapp.com/api/';
  var isLocal = false;
  const environment = helperFunctions.testEnvironment(window.location.hostname);
  environmentUrl = environment.url;
  isLocal = environment.localhost;

  const [paypalUrl, setPaypalUrl] = useState("https://croftanalytics.com/shareofsearchingint")
  const [emailError, setEmailError] = useState()

  useEffect(() => {
    getAccountInfo(this);
  }, []);


  const getAccountInfo = async e => {
    document.getElementsByTagName("BODY")[0].style.cursor = "wait";
    var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    var accountInfo = [];
    var email = sessionStorage.getItem('user');
    var sessionId = parseInt(sessionStorage.getItem('id'));
    sessionStorage.setItem('user', email);
    if (email === null || email === "null") {
      window.location.replace("/login");
    }
    if (email === "ofcharrison@gmail.com" || email === "chetwode@hotmail.com" || email === "frank@croftanalytics.com" || email === "wfiharrison@gmail.com") {
      $("#admin").append("<a href='/admin'>Admin Page</a><br></br>")
    }
    accountInfo.push(email);

    const response = await fetch(environmentUrl + 'getaccountinfobyemail', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ post: { email: email } }),
    });

    const accountInfoResponse = await response.json();
    if (accountInfoResponse.length === 0 || (accountInfoResponse.Id * 500) !== sessionId) {
      alert("Error: Cannot find account.");
      window.location.replace("/login");
    }

    document.getElementById("firstNameReceived").value = accountInfoResponse.FirstName;
    document.getElementById("lastNameResponded").value = accountInfoResponse.LastName;
    document.getElementById("emailReceived").value = email;
    document.getElementById("countryReceived").value = accountInfoResponse.Country;
    document.getElementById("companyResponded").value = accountInfoResponse.Company;
    document.getElementById("jobTitleReceived").value = accountInfoResponse.JobTitle;
    var allowSearch = accountInfoResponse.AllowSearch;
    if (accountInfoResponse.Country === "United Kingdom") {
      setPaypalUrl("https://croftanalytics.com/shareofsearchinguk");
    }
    if (allowSearch === 1) {
      allowSearch = "Verified"
    } else { allowSearch = "Not Verified" }
    document.getElementById("accountVerifiedReceived").value = allowSearch;

    document.getElementById("subscriptionReceived").value = accountInfoResponse.Subscription;
    var subStartDate = new Date(accountInfoResponse.SubscriptionStartDate.replace("T", " ").replace("Z", " ").substring(0, 10));
    var subEndDate = new Date(accountInfoResponse.SubscriptionEndDate.replace("T", " ").replace("Z", " ").substring(0, 10));
    document.getElementById("subscriptionStartDateResponded").value = subStartDate.getDate() + " " + months[subStartDate.getMonth()] + " " + subStartDate.getFullYear();
    var subEndDateString = subEndDate.getDate() + " " + months[subEndDate.getMonth()] + " " + subEndDate.getFullYear();
    document.getElementById("subscriptionEndDateResponded").value = subEndDateString;

    document.getElementsByTagName("BODY")[0].style.cursor = "auto";
  }

  const setPaidDeleteAccountText = (subscription, subEndDate) => {
    if (subscription === "PAID") {
      $("#smallDeleteAccountText").html("Are you sure you want to deactivate your <b>paid subscription account</b>" +
        " which expires on: <br /><i>" + subEndDate + "</i>")
    }
  }

  const cancel = async e => {
    e.preventDefault();
    $(".changeEmailPopup").css("display", "none");
    $(".changePasswordPopup").css("display", "none");
    $(".DeleteAccountPopup").css("display", "none")
  }

  const emailChangedOk = async e => {
    e.preventDefault();
    $(".EmailChangedPopup").css("display", "none")
    window.location.replace("/login");
  }

  const deletedAccountOk = async e => {
    e.preventDefault();
    $(".ThankYouAndGoodbyePopup").css("display", "none")
    window.location.replace("/login");
  }

  const showEmailPopup = async e => {
    $(".changeEmailPopup").css("display", "block")
  }
  const showPasswordPopup = async e => {
    $(".changePasswordPopup").css("display", "block")
  }

  const showDeleteAccountPopup = async e => {
    $(".DeleteAccountPopup").css("display", "block")
  }

  const changeEmailPopup = async e => {
    e.preventDefault();
    var email = $("#emailInput").val();
    if (email != null || email != "") {
      $(".changeEmailPopup").css("display", "none");
      // http://localhost:5000/api/updateemail
    } else {
      alert("Email not entered");
      window.location.reload();
    }
  }

  const makeid = (length) => {
    var result = [];
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result.push(characters.charAt(Math.floor(Math.random() *
        charactersLength)));
    }
    return result.join('');
  }

  const changeEmail = async (e) => {
    e.preventDefault();
    var accountInfo = [];
    var newAccountInfoLink = {};
    var newAccountEmail = [];
    var oldEmail = sessionStorage.getItem('user');
    var newEmail = $("#emailInput").val();
    if (!newEmail || newEmail === '' || newEmail === null || !newEmail.includes('@')) {
      setEmailError(true);
      return;
    };
    setEmailError(false);
    var randomNumber = Math.ceil(Math.random() * 10) + 3;
    var randomCode = makeid(randomNumber);
    newAccountEmail.push(newEmail);
    accountInfo.push(oldEmail);
    accountInfo.push(newEmail);
    newAccountInfoLink.accountEmail = newEmail;
    newAccountInfoLink.randomCode = randomCode;
    newAccountInfoLink.userName = sessionStorage.getItem('userName');

    const response = await fetch(environmentUrl + 'updateemail', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ post: accountInfo }),
    });
    const emailChangedResponse = await response.json();

    const responseAllowSearch = await fetch(environmentUrl + 'setallowedsearchfalse', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ post: newAccountEmail }),
    });
    const allowSearchResponse = await responseAllowSearch.json();

    const setLinkResponse = await fetch(environmentUrl + 'setemailverificationlink', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ post: newAccountInfoLink }),
    });
    const setLinkResponseJson = await setLinkResponse.json();
    if (setLinkResponseJson === true || setLinkResponseJson === "true") {
    } else {
      alert("Error: Verification email was unable to be sent.")
    }

    const emailVerificationResponse = await fetch(environmentUrl + 'sendVerificationEmail', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ post: newAccountInfoLink }),
    });
    const emailVerificationResponseJson = await emailVerificationResponse.json();
    if (emailVerificationResponseJson === true || emailVerificationResponseJson === "true") {
    } else {
      alert("Error: Verification email was unable to be sent.");
    }

    if (emailChangedResponse === true) {
      $(".changeEmailPopup").css("display", "none");
      $(".EmailChangedPopup").css("display", "block");
    } else {
      alert("Error: Email already associated to account.");
      window.location.reload();
    }
  }

  const changePassword = async (e) => {
    e.preventDefault();
    var accountInfo = [];
    var email = sessionStorage.getItem('user');
    var newPassword1 = $("#newPass1").val();
    var newPassword2 = $("#newPass2").val();

    if (newPassword1 != newPassword2) {
      alert("Error: New passwords do not match.")
    } else if (newPassword1 === "" && newPassword2 === "") {
      alert("Error: Password cannot be empty.")
    } else {
      accountInfo.push(email);
      accountInfo.push(newPassword1);
      const response = await fetch(environmentUrl + 'updatepassword', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ post: { email: email, password: newPassword1 } }),
      });

      const passwordChangedResponse = await response.json();

      if (passwordChangedResponse === true) {
        alert("Success: Password changed!");
        window.location.replace("/login");
      } else {
        alert("Error: Password not changed");
        window.location.reload();
      }
    }
  }

  const wipeAccount = async (e) => {
    var accountInfo = [];
    var email = sessionStorage.getItem('user');
    accountInfo.push(email);

    $(".DeleteAccountPopup").css("display", "none")
    const response = await fetch(environmentUrl + 'wipeaccountbyemail', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ post: accountInfo }),
    });
    const deleteAccountResponse = await response.json();
    if (deleteAccountResponse === true) {
      $(".ThankYouAndGoodbyePopup").css("display", "block")
    } else {
      alert("Error: Account not deleted.");
      window.location.reload();
    }
  }



  return (
    <div className="AccountPage">
      <div className="AccountInfoSection">
        <h3 id="myAccountText">My Account</h3>

        <div className="changeEmailPopup">
          <div id="changeEmailPopupBox">
            <h2 id="popupHeaderEmailText">Please enter your new email address below.</h2>
            <p id="smallEmailPopupText"></p><br />
            <form id="emailInputForm" onSubmit={(e) => changeEmail(e)}>
              <input type="email" id="emailInput" placeholder="email@email.com" style={{ border: emailError ? 'solid red' : '' }} />
              <button type="submit" id="emailSubmitButton">CHANGE MY EMAIL</button>
              <p id="cancel" onClick={(e) => cancel(e)}>Cancel</p>
            </form>
          </div>
        </div>
        <div className="changePasswordPopup">
          <div id="changePasswordPopupBox">
            <p id="popupHeaderPasswordText">Please enter your new password below & confirm it in the second field.</p>
            <p id="smallPasswordPopupText"></p><br />
            <form id="changePasswordForm" onSubmit={(e) => changePassword(e)}>
              <p id="newPasswordDefaultText">New Password: </p> <input type="password" id="newPass1" minLength="4" /><br />
              <p id="newPasswordConfirmationText">New Password Confirmation:  </p><input type="password" id="newPass2" minLength="4" /><br />
              <button id="passwordSubmitButton" type="submit">Confirm</button>
              <p id="cancel" onClick={(e) => cancel(e)}>Cancel</p>
            </form>
          </div>
        </div>
        <div className="DeleteAccountPopup">
          <div id="deleteAccountPopupBox">
            <p id="popupDeleteAccountText">DELETE ACCOUNT</p>
            <p id="smallDeleteAccountText">Please email  <a href="mailto:frank@croftanalytics.com">frank@croftanalytics.com</a> if you would like your account to be deleted.<br /><br /> On receiving your request your account will be deleted and an email will be sent back to you confirming this.</p>
            <p id="cancelDeleteAccountPopup" onClick={(e) => cancel(e)}>Close</p>
          </div>
        </div>
        <div className="ThankYouAndGoodbyePopup">
          <div id="thankYouAndGoodbyePopupBox">
            <p id="popupDeleteAccountText">YOUR ACCOUNT HAS BEEN DEACTIVATED</p>
            <p id="smallDeleteAccountTextGoodbye">Thank you and goodbye</p><br />
            <button id="cancelOk" onClick={(e) => deletedAccountOk(e)}>OK</button>
          </div>
        </div>
        <div className="EmailChangedPopup">
          <div id="emailChangedPopupBox">
            <p id="popupEmailChangedText"><b>FINAL STEP TO CHANGE YOUR EMAIL ADDRESS</b></p>
            <p id="smallEmailChangedText">An email has been sent to your new email address - open the email and click on the link to validate your new email address.</p>
            <p id="smallHeaderPopupTextSub">If you cannot find the email, please check your junk/spam inbox or contact frank@croftanalytics.com</p><br />
            <button id="cancelOk" onClick={(e) => emailChangedOk(e)}>LOG IN</button>
          </div>
        </div>

        <div id="container">

          <div className="AccountInfoLeftTextSection">
            <h3 id="profileInformationText">Profile Information:</h3>
            <div id="admin"></div>
            <p id="firstNameDisplayText">First Name:</p> <input type="text" id="firstNameReceived" readonly disabled /><br /><br />
            <p id="lastNameDisplayText">Last Name:</p> <input type="text" id="lastNameResponded" readonly disabled /><br /><br />
            <p id="countryDisplayText">Country:</p> <input type="text" id="countryReceived" readonly disabled /><br /><br />
            <p id="companyDisplayText">Company:</p> <input type="text" id="companyResponded" readonly disabled /><br /><br />
            <p id="jobTitleDisplayText">Job Title:</p><input type="text" id="jobTitleReceived" readonly disabled /><br /><br />
            <p id="emailDisplayText">Email:</p> <input type="text" id="emailReceived" readonly disabled /><button type="button" id="changeEmailButton" onClick={(e) => showEmailPopup(e)}>Change Email</button><br /><br />
            <p id="accountVerifiedText">Email Verification:</p><input type="text" id="accountVerifiedReceived" readonly disabled /><br /><br /><br />

            <button type="button" id="changePasswordButton" onClick={(e) => showPasswordPopup(e)}>Change Password</button><br /><br />
            <button type="button" id="deleteAccountPageButton" onClick={(e) => showDeleteAccountPopup(e)}>Delete Account</button><br /><br />
            <Link to='/' style={{ textDecoration: 'none' }} id="logoutLink">
              <p id="logoutText">Logout</p>
            </Link>

          </div>

          <div className="SubscriptionInfoTextSection">
            <h3 id="subscriptionInfoHeaderText">Subscription Information:</h3>
            <p id="subscriptionTypeDisplayText">Subscription Type:</p> <input type="text" id="subscriptionReceived" readonly disabled /><br /><br />
            <p id="subscriptionStartDateDisplayText">Subscription Start Date:</p> <input type="text" id="subscriptionStartDateResponded" readonly disabled /><br /><br />
            <p id="subscriptionEndDateDisplayText">Subscription End Date:</p> <input type="text" id="subscriptionEndDateResponded" readonly disabled /><br /><br />

            <h4 id="textForPaidSubscription" onClick={(e) => window.open(paypalUrl)}><a id="paymentLink" href={paypalUrl}>Buy one year subscription for £500</a></h4>
            <img src={paymentsImage} alt="Logo" id="paymentsImage" />

          </div>

        </div>

        <Footer withExtras />

      </div>
    </div>
  );

}

export default AccountPageNew;
