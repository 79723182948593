import React from 'react';
import './Css/Home.css';
import './Css/Privacy&TC.css';
import { Footer } from "./Components/Footer.js";



function Privacy() {
  document.title = "Share of Searching";
  return (
    <div className="Privacy">
      <div id="privacyText">
        <h1 id="privacyTitleText">Privacy Policy</h1>
        <p id="privacyBaseText">
          The Croft Analytics Share of Searching app stores your email address, session IP address (and city and country derived from it), your search queries, your saved search criteria, your saved reports criteria, and your other account details which are used for the following purposes:
          <ul>
            <li>To manage your subscription to Share of Searching.</li>
            <li>To retrieve your saved search/report criteria for your use in the app.</li>
            <li>To log your use of Share of Searching so we can better understand how to improve the app.</li>
            <li>To inform you of major updates.</li>
          </ul>
          <br />None of the above information is shared with anyone. You can email <a href="mailto:frank@croftanalytics.com">frank@croftanalytics.com</a> if you would like any of your information to be removed.
          <br /><br />Croft Analytics does not own any of the data provided within the app, just the code used to make the app function.
          <br /><br />Users are free to use the app however they like.
          <br /><br />We are using Google Analytics to track the performance of Share of Searching.
          <br /><br /><br /><b>* The Share of Searching Google Extension does not store any information.</b>
          <br /><br /><br />
        </p>
        <p id="homeBaseBottomText">For more information about Share of Searching or Croft Analytics please email <a href="mailto:frank@croftanalytics.com">frank@croftanalytics.com</a></p>
      </div>
      <br /><br /><br />
      <Footer withExtras />
    </div>
  );
}

export default Privacy;
