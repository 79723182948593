import React, { Component, useEffect, useState } from 'react';
import './Css/ResetPassword.css';
import { Link } from 'react-router-dom';
import logo from '../images/ShareOfSearching.png';
import $ from 'jquery';
import HelperFunctions from './Helpers/HelperFunctions';

const ResetPassword = () => {

  const helperFunctions = new HelperFunctions;
  const [userEmail, setUserEmail] = useState("");
  const [environmentUrl, setEnvironmentUrl] = useState('https://servershareofsearch.herokuapp.com/api/')
  const [password1, setPassword1] = useState("1")
  const [password2, setPassword2] = useState("2")
  const environment = helperFunctions.testEnvironment(window.location.hostname);

  useEffect(() => {
    setEnvironmentUrl(environment.url);
    checkUrlLink(this);
    document.getElementById('navBar')?.remove();
  }, [])

  const checkUrlLink = async e => {
    const response = await fetch(environmentUrl + 'getrandomlink', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    });
    const body = await response.json();
    var linkExtension = body[0].Code;
    var email = body[0].Email;
    setUserEmail(email);
    var currentUrl = window.location.href;
    if (!currentUrl.includes(linkExtension) || linkExtension === "null" || linkExtension === null || email === null || email === "" || email === "null") {
      alert("Error: Access Denied");
      window.location.replace("https://www.shareofsearching.com/login");
    }

    $("#resetPasswordHeader").html("Reset Password for: " + email);
  }

  const changePassword = async e => {
    e.preventDefault();

    if (password1 != password2) {
      alert("Error: New passwords do not match.")
    } else if (password1 === "" && password2 === "") {
      alert("Error: Password cannot be empty.")
    } else {
      var accountInfo = { email: userEmail, password: password1 };
      const response = await fetch(environmentUrl + 'updatepassword', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ post: accountInfo }),
      });

      const passwordChangedResponse = await response.json();

      if (passwordChangedResponse === true) {
        alert("Success: Password changed!");
        window.location.replace("https://www.shareofsearching.com/login");
      } else {
        alert("Error: Password not changed");
        window.location.reload();
      }
    }
  }


  return (
    <div className="ResetPassword">
      <img src={logo} alt="Logo" id="sosingImage" />
      <div id="resetPasswordBox">
        <form id="resetPasswordForm" onSubmit={(e) => changePassword(e)}>
          <p id="resetPasswordHeader">Reset Password </p>
          <p id="newPasswordText">New Password: </p>
          <input type="password" id="newPassword1" minLength="4" placeholder="* * * * *" onChange={(e) => setPassword1(e.target.value)} /><br />
          <p id="confirmationText">New Password Confirmation: </p>
          <input type="password" id="newPassword2" minLength="4" placeholder="* * * * *" onChange={(e) => setPassword2(e.target.value)} /><br />
          <button id="submit" type="submit">Confirm</button>
        </form>
      </div>
    </div>
  );

}

export default ResetPassword;
