import $ from 'jquery';
import { Constants } from '../Constants';
import HelperFunctions from '../../Helpers/HelperFunctions';
import { useEffect, useState } from 'react';

export const AddReportPopup = ({ setAddingReport, setReportData, setHasReports, environmentUrl }) => {

  var helperFunctions = new HelperFunctions;

  $(document).unbind().click(function (event) {
    event.preventDefault();
    var text = $(event.target);
    if (text.get(0).id === "singlePreviousSearch" || text.get(0).className.includes("singleSavedSearch")) {
      fillSavedSearch(text.get(0), event);
    }
  });

  useEffect((e) => {
    helperFunctions.getCountries(e, environmentUrl);
    helperFunctions.getCategories(e, environmentUrl);
    getSavedSearches(e);
  }, [])

  const [disableCategories, setDisableCategories] = useState(false);

  async function fillSavedSearch(savedSearch, e) {
    e.preventDefault();
    for (var i = 0; i < savedSearch.childNodes.length; i++) {
      for (var b = 0; b < savedSearch.childNodes[i].childNodes.length; b++) {
        if (savedSearch.childNodes[i].childNodes[b] instanceof Element === true) {
          if (savedSearch.childNodes[i].childNodes[b].tagName.toLowerCase() == 'span') {
            var previousSearchSpanNode = savedSearch.childNodes[i].childNodes[b]

            if (previousSearchSpanNode.id === "savedCountryReceived") {
              var country = previousSearchSpanNode.textContent.split(',')[0];
              for (var x = 0; x < document.getElementById("countriesId").options.length; x++) {
                if (document.getElementById("countriesId").options[x].text === country) {
                  document.getElementById("countriesId").selectedIndex = x;
                  break;
                }
              }
            }

            // if (previousSearchSpanNode.id === "savedPropertyReceived") {
            //   var reportSearchProperty = document.getElementById("addReportSearchProperty");
            //   var selectedProperty = previousSearchSpanNode.textContent;
            //   for (var x = 0; x < reportSearchProperty.options.length; x++) {
            //     if (selectedProperty === reportSearchProperty.options[x].text) {
            //       reportSearchProperty.selectedIndex = x;
            //       break;
            //     }
            //   }
            // }

            // if (previousSearchSpanNode.id === "savedCategoryReceived") {
            //   for (var x = 0; x < document.getElementById("categories").options.length; x++) {
            //     if (previousSearchSpanNode.textContent === 'undefined') {
            //       document.getElementById("categories").selectedIndex = 0;
            //       break;
            //     }
            //     else if (document.getElementById("categories").options[x].text === previousSearchSpanNode.textContent) {
            //       document.getElementById("categories").selectedIndex = x;
            //       break;
            //     }
            //   }
            // }

            if (previousSearchSpanNode.id === "savedSearchedForReceived") {
              const words = helperFunctions.removeEndCommas(previousSearchSpanNode.textContent).split(",");
              helperFunctions.searchTermsTooLong(words, "One or more of your search terms is too long, please reduce.")
              document.getElementById("addReportSearchTerms").value = helperFunctions.removeEndCommas(previousSearchSpanNode.textContent);
            }

            if (previousSearchSpanNode.id === "savedSearchNameReceived") {
              document.getElementById("addReportName").value = previousSearchSpanNode.textContent;
            }

          }
        } else { }
      }
    }
  }

  function searchTypeChanged(type) {
    if (type === "VOLUME") {
      var selectobject = document.getElementById("searchPeriod");
      var selectSearchPropertyObject = document.getElementById("addReportSearchProperty");
      for (var i = 0; i < selectobject.length; i++) {
        if (selectobject.options[i].value == 'LAST_WEEK' || selectobject.options[i].value == 'LAST_MONTH') {
          selectobject.remove(i);
          i--;
        }
      }
      for (var i = 0; i < selectSearchPropertyObject.length; i++) {
        if (selectSearchPropertyObject.options[i].value == 'youtube')
          selectSearchPropertyObject.remove(i);
      }
      setDisableCategories(true);
    } else {
      var selectobject = document.getElementById("searchPeriod");
      var selectSearchPropertyObject = document.getElementById("addReportSearchProperty");
      var containsLastWeek = false;
      var containsYoutube = false
      for (var i = 0; i < selectobject.length; i++) {
        if (selectobject.options[i].value == 'LAST_WEEK') containsLastWeek = true;
      }
      for (var i = 0; i < selectSearchPropertyObject.length; i++) {
        if (selectSearchPropertyObject.options[i].value == "youtube") containsYoutube = true;
      }
      if (!containsLastWeek) {
        var x = document.getElementById("searchPeriod");
        var option = document.createElement("option");
        option.value = "LAST_WEEK";
        option.text = "Last week";
        x.add(option, x[0]);
      }
      if (!containsYoutube) {
        var x = document.getElementById("addReportSearchProperty");
        var option = document.createElement("option");
        option.value = "youtube";
        option.text = "Youtube";
        x.add(option, x[0]);
      }
      setDisableCategories(false);
    }
  }

  async function getSavedSearches(e) {
    const id = sessionStorage.getItem("id");
    const email = sessionStorage.getItem("user");
    var accountInfo = {};
    accountInfo.email = email;
    accountInfo.id = (id);

    $("#mySavedSearches").empty();

    const response = await fetch(environmentUrl + 'getsavedsearches', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ post: accountInfo }),
    });
    const savedSearches = await response.json();

    var numberOfSearches;
    if (savedSearches.length < 30) {
      numberOfSearches = 0;
    } else {
      numberOfSearches = savedSearches.length - 30;
    }
    if (savedSearches.length > 0) {
      for (var x = savedSearches.length - 1; x >= numberOfSearches; x--) {
        var searchId = savedSearches[x].Id;
        var searchName = savedSearches[x].search_name;
        var searchedFor = savedSearches[x].search_terms;
        var searchedCountry = savedSearches[x].country;
        var searchedState = savedSearches[x].state;
        var searchedRegion = savedSearches[x].region;
        var searchedCategory = savedSearches[x].category;
        var searchedStart = savedSearches[x].start_date.substring(0, 10);
        searchedStart = new Date(searchedStart).toJSON().slice(0, 10).split('-');
        searchedStart[1] = helperFunctions.months()[parseInt(searchedStart[1]) - 1];
        searchedStart = searchedStart.reverse().join("-");
        var searchedEnd = savedSearches[x].end_date.substring(0, 10);
        searchedEnd = new Date(searchedEnd).toJSON().slice(0, 10).split('-');
        searchedEnd[1] = helperFunctions.months()[parseInt(searchedEnd[1]) - 1];
        searchedEnd = searchedEnd.reverse().join("-");
        var searchEndArray = searchedEnd.split("-");
        searchEndArray[2] = searchEndArray[2] - 1;
        var endDay = parseInt(searchEndArray[2]) + 1;
        searchEndArray[2] = endDay.toString();
        var searchProperty = helperFunctions.propertyFormatText(savedSearches[x].property);

        if (savedSearches[x].SearchType !== Constants.SIMILAR_TYPE) {
          $("#addReportSavedSearches").append("<div class='singleSavedSearch" + x + "'>" +
            "<p id='savedSearchName" + x + "' style='color: blue'> <span id='savedSearchNameReceived'>" + searchName + "</span></p>" +
            "<p style='font-size: 13px'><b> Country:</b> <span id='savedCountryReceived'>" + searchedCountry + "</span> | <b> State:</b> <span id='savedStateReceived'>" + searchedState + "</span> | <b> Region:</b> <span id='savedRegionReceived'>" + searchedRegion + "</span></p>" +
            "<p style='font-size: 13px'><b> Category:</b> <span id='savedCategoryReceived'>" + searchedCategory + "</span></p>" +
            "<p style='font-size: 13px'><b> Start Date:</b> <span id='savedStartReceived'>" + searchedStart + "</span> | <b> End Date:</b> <span id='savedEndReceived'>" + searchEndArray.join("-") + "</span></p>" +
            "<p style='font-size: 13px'><b>Search Property:</b> <span id='savedPropertyReceived'>" + searchProperty + "</span></p>" +
            "<p style='font-size: 13px'><b>Search List:</b> <span id='savedSearchedForReceived'>" + searchedFor + "</span></p>" +
            "</div><p class='LastSearchBreaker" + x + "'></p>");
        }
      }
    } else {
      $("#addReportSavedSearches").append("<div class='NoSavedSearches'><p>You have no saved searches. Please create your report below</p></div>")
    }
  }

  return (<div className="AddReportPopup">
    <div id="addReportPopupBox">
      <h3 id="addReportHeaderText"> Add Report </h3>
      <p id="addReportSubHeaderText"> Click on a previously saved search to retrieve the search criteria or create a new report. </p>
      <div id="addReportSavedSearches">
      </div>
      <div id="myReport">

        <label id="addReportNameLabel" for="addReportName">Report Name:</label>
        <input
          id="addReportName"
          type="text"
          defaultValue=""
          required
        />

        {/* <label id="addReportSearchTypeLabel" for="addReportSearchType">Type:</label>
        <select id="addReportSearchType" onChange={(e) => searchTypeChanged(e.target.value)}>
          <option value="INDEX">INDEX</option>
          <option value="VOLUME">VOLUME</option>
        </select>

        <label id="addReportSearchPropertyLabel" for="addReportSearchProperty" style={{ textDecoration: `${disableCategories ? 'line-through' : ''}` }}>Search Property:</label>
        <select id="addReportSearchProperty">
          <option value="web">Google</option>
          <option value="youtube">YouTube</option>
        </select> */}

        <label id="addReportSearchTermsLabel" for="addReportSearchTerms">Search Terms:</label>
        <input
          id="addReportSearchTerms"
          type="text"
          defaultValue=""
          required
        />

        <label id="countriesIdLabel" for="countriesId">Country:</label>
        <select name="countries" id="countriesId">
          <option value="">Worldwide</option>
        </select>

        {/* <label id="categoriesLabel" for="categories" style={{ textDecoration: `${disableCategories ? 'line-through' : ''}` }}>Category:</label>
        <select name="categories" id="categories" disabled={disableCategories} style={{ textDecoration: `${disableCategories ? 'line-through' : ''}` }}>
          <option value="0">All</option>
        </select> */}

        <label id="searchPeriodLabel" for="searchPeriod">Period:</label>
        <select name="searchPeriod" id="searchPeriod">
          <option value="LAST_THREE_MONTHS">Last 3 months</option>
          <option value="LAST_SIX_MONTHS">Last 6 months</option>
          <option value="LAST_TWELVE_MONTHS">Last 12 months</option>
          <option value="LAST_TWENTY_FOUR_MONTHS">Last 24 months</option>
          <option value="LAST_THIRTY_SIX_MONTHS">Last 36 months</option>
          <option value="LAST_FOURTY_EIGHT_MONTHS">Last 48 months</option>
        </select>

        {/* <label id="reportFrequencyLabel" for="reportFrequency">Frequency:</label>
        <select name="reportFrequency" id="reportFrequency">
          <option value="WEEKLY">WEEKLY</option>
          <option value="MONTHLY">MONTHLY</option>
        </select> */}

      </div>
      <div className="AddReportSubmitButtons">
        <button id="cancelOk" onClick={(e) => dataToSave(e, setReportData, setAddingReport, setHasReports, environmentUrl)}>Save</button>
        <button id="cancelOk" onClick={(e) => setAddingReport(false)}>Close</button>
      </div>
    </div>
  </div>
  )
};

async function dataToSave(e, setReportData, setAddingReport, setHasReports, environmentUrl) {
  const id = sessionStorage.getItem("id");
  const email = sessionStorage.getItem("user");

  const reportName = $("#addReportName").val();
  const reportCreatedEdited = new Date().toISOString().slice(0, 19).replace('T', ' ');
  const searchTerms = $("#addReportSearchTerms").val();
  const searchType = "VOLUME";
  const searchCountryShorthand = $('#countriesId').find(":selected").val();
  const searchCountry = $('#countriesId').find(":selected").text();
  const searchCategoryId = (searchType === "VOLUME" ? null : parseInt($('#categories').find(":selected").val()));
  const searchCategory = (searchType === "VOLUME" ? "-" : $('#categories').find(":selected").text());
  const searchPeriod = $('#searchPeriod').find(":selected").val();
  const reportFrequency = "MONTHLY";
  const property = "web";

  if (reportName.length < 1 || searchTerms.length < 1) {
    $("#addReportNameLabel").css({ 'font-weight': 'bold' });
    $('#addReportSearchTermsLabel').css({ 'font-weight': 'bold' });
    alert("Report name and search terms cannot be empty.");
    return;
  }

  const reportData = {
    reportName,
    reportCreatedEdited,
    searchTerms,
    searchType,
    searchCountry,
    searchCountryShorthand,
    searchCategory,
    searchCategoryId,
    searchPeriod,
    property,
    reportFrequency
  }

  const response = await fetch(environmentUrl + 'reports/addreport', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ post: { email: email, id: id, report: reportData } }),
  });
  const addedResponse = await response.json();

  reportData.reportId = addedResponse.results.insertId;

  setReportData(prevData => [...prevData, reportData]);
  setHasReports(true);

  if (addedResponse === false) {
    alert("Error: Report not created.");
    window.location.reload();
  }

  setAddingReport(false);
}
