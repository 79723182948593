export const SubscribersOnly = ({ setShowSubscribersOnly }) => (
    <div className="NoPermissionPopup">
        <div id="noPermissionPopupBox">
            <p id="popupNoPermissionText">SORRY, THIS IS FOR SUBSCRIBERS ONLY</p>
            <p id="smallNoPermissionText">Please contact <a href="mailto:frank@croftanalytics.com">frank@croftanalytics.com</a> if you have questions.</p><br />
            <button id="cancelOk" onClick={(e) => {
                setShowSubscribersOnly(false)
            }}>OK</button>
        </div>
    </div>
)