import React from 'react';
import './Css/Home.css';
import './Css/Privacy&TC.css';
import { Footer } from "./Components/Footer.js";



function TermsConditions() {
  document.title = "Share Of Searching";
  return (
    <div className="TermsConditions">
      <div id="termsConditionsText">
        <h1 id="termsConditionsTitleText">Share of Searching - Subscription Terms and Conditions</h1>
        <p id="termsConditionsBaseText">
          <ol>
            <li id="tcbp">The Share of Searching subscription is for a fixed number of individual users and for a fixed period (for example, three users for 12 months). Users may not share their access details (username/password) with anyone else. Users may not transfer their subscription to another person within their Subscriber company without prior agreement with Croft Analytics.</li>
            <li id="tcbp">All present and future Intellectual Property Rights relating to Share of Searching, the documentation, the underlying technology, methodology and principles are the sole and exclusive property of Croft Analytics. </li>
            <li id="tcbp">Subscribers will not reverse engineer, decompile, disassemble or translate the subscription software, or otherwise attempt to derive source code, trade secrets or know-how in or underlying the subscription software.</li>
            <li id="tcbp">Subscribers will not copy, sell, rent, lease, sub-subscription, distribute, redistribute, syndicate, create derivative works of, assign or otherwise transfer or provide access to, in whole or in part, the subscription software or data to any third party. </li>
            <li id="tcbp">Subscribers will not provide use of the subscription software on a service bureau, rental or managed services basis.</li>
            <li id="tcbp">Unless indicated otherwise on the invoice, the Subscriber shall pay the subscription invoice within 30 days of the date of receipt of the invoice.</li>
            <li id="tcbp">Croft Analytics reserves the right to terminate the subscription at any time without cause upon 30 days’ written notice to the Subscriber. If the termination is not due to a Subscriber breach of these conditions, Croft Analytics will refund the Subscriber a pro-rata amount based on the remaining number of days of the subscription from the termination date.</li>
            <li id="tcbp">No press release or other public announcement of the agreement between Croft Analytics and the Subscriber or any element of the agreement may be issued or made without advance written consent from Croft Analytics.</li>
            <li id="tcbp">No public disclosure of Share of Searching information or data may be made, for example on a web site, in a public forum or conference, in a press release, in a public report (including academic reports or circulars), or in any other public way, without advance written consent from Croft Analytics. Croft Analytics acknowledges the importance of academic publishing to some Subscribers and will not unreasonably refuse consent where the Subscriber wishes to use Share of Searching information or data in academic publications or circulars.</li>
            <li id="tcbp">The Subscriber must inform recipients of Share of Searching information or data (for example, employees or clients of the Subscriber) that they may not disclose information about Share of Searching or data from Share of Searching in any public way without advance written consent from Croft Analytics.</li>
          </ol>
        </p>
        <p id="homeBaseBottomText">For more information about Share-Of-Searching or Croft Analytics please email <a href="mailto:frank@croftanalytics.com">frank@croftanalytics.com</a></p>
      </div>
      <br /><br /><br />
      <Footer withExtras />
    </div>
  );
}

export default TermsConditions;
