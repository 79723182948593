import React from 'react';
import '../Css/Home.css';
import '../Css/About.css';
import { Footer } from "../Components/Footer.js";



const About = () => {
  document.title = "Share Of Searching";
  return (
    <div className="About">
      {/* <h1 id="aboutHeaderText">INFORMATION</h1> */}
      <div id="bookletTextDiv">
        <a href="https://img1.wsimg.com/blobby/go/10ab9f80-7651-45a5-b821-244016eea05e/downloads/Share%20of%20Searching%20-%20User%20Guide.pdf?ver=1669367594089" target="_blank" id="bookletText" rel="noreferrer">
          USER GUIDE
        </a>
      </div>
      <div id="bookletTextDiv">
        <a href="https://img1.wsimg.com/blobby/go/10ab9f80-7651-45a5-b821-244016eea05e/downloads/Share%20of%20Searching%20-%20Introduction.pdf" target="_blank" id="bookletText" rel="noreferrer">
          ABOUT SHARE OF SEARCHING
        </a>
      </div>
      {/* <div className='YoutubeVideoDiv'>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/xYdKY3FU7wM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen" allowfullscreen></iframe>
      </div> */}
      <div id="aboutMainText">
        <br />
        <h1 id="aboutTitleText">About Share of Searching</h1>
        <p id="aboutBaseText">Share of Searching is a search trends tool that charts Google search volumes and % share of search for lists
          of keywords/ search terms entered by users (up to 30 search terms at a time). Search term trends can be
          viewed worldwide, within any country, and across up to five countries at a time.</p>

        <h1 id="aboutTitleText">Why share of search is a useful metric</h1>
        <p id="aboutBaseText">
          Analysis of Google search data shows that, in many categories, a brand’s share of category search can be used to predict its market share. <a href="https://img1.wsimg.com/blobby/go/10ab9f80-7651-45a5-b821-244016eea05e/downloads/Share%20of%20Searching%20-%20Introduction.pdf?ver=1669367594089" target="_blank" rel="noreferrer" >Click here</a> to download an introduction to Share of Searching and <a href="https://img1.wsimg.com/blobby/go/10ab9f80-7651-45a5-b821-244016eea05e/downloads/Share%20of%20Searching%20-%20Category%20Use%20Examples.pdf?ver=1669367594089" target="_blank" rel="noreferrer">click here</a> to download category use case examples.
        </p>

        <h1 id="aboutTitleText">Benefits of using Share of Searching:</h1>
        <ul id="filesBulletpoints" style={{}}>
          <li id="bulletpoint">
            Tracking, trends, and comparisons of search interest for brands in a category or any topics. View and
            export trends in search volumes and % share of search.
          </li>
          <li id="bulletpoint">
            Comparable volume (monthly) trends for up to 30 search terms at a time over the last four years.
          </li>
          <li id="bulletpoint">
            Discover search terms related to a brand or topic. View the relative importance (volume) of each term
            and trend in interest for the terms.
          </li>
        </ul>

        <p id="aboutBaseText" style={{ fontSize: 18, marginTop: "10%" }}>If you have any questions or suggestions for improvement, please email Frank Harrison at frank@croftanalytics.com
        </p>

      </div>
      <Footer withExtras />
    </div>
  );
}

export default About;
