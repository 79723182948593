import $ from 'jquery';

export const SavedSearchesPopup = ({ savedSearchesOk, savedSearchesProps, showAllSavedSearchesFunc, savedSearchesToRender }) => {
  const id = sessionStorage.getItem("id");
  return (<div className="SavedSearchesPopup">
    <div id="savedSearchesPopupBox">
      <h3 id="savedSearchesHeaderText"> My Saved Searches </h3>
      <div id="savedSearchesSubHeaderDiv" >
        <p id="savedSearchesSubHeaderText"> Click on a previous search to retrieve the search criteria </p>
        <label for="sortBySelect" id="sortBySelectLabel">Sort By: </label>
        <select id="sortBySelect" onChange={(e) => {
          showAllSavedSearchesFunc({ sortBy: e.target.value, ...savedSearchesProps });
        }}
        >
          <option value="">Created</option>
          <option value="Name">Name</option>
          <option value="Country">Country</option>
          <option value="StartDate">Start Date</option>
        </select>
      </div>
      <div id="mySavedSearches">
        {savedSearchesToRender}
      </div>
      <button id="cancelOk" onClick={(e) => savedSearchesOk(false)}>Close</button>
    </div>
  </div >
  )
};
