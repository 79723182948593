import PropTypes from 'prop-types';
import { Constants } from '../Constants';

export const NoPermissionLimitReachedPopup = ({ dailyLimitReached, searchType }) => {
    const searchTypeText = searchType === Constants.VOLUME_TYPE ? "VOLUME TRENDS" : searchType === Constants.VOLUME_PLUS_TYPE ? "COUNTRY TRENDS" : "SIMILAR TRENDS"
    return (
        <div className="NoVolumePermissionPopup">
            <div id="noPermissionPopupBox">
                {dailyLimitReached ?
                    <>
                        <p id="popupNoPermissionText"><b>SORRY, YOU HAVE REACHED THE DAILY LIMIT FOR {searchTypeText}</b></p>
                        <p id="smallNoPermissionText">To get additional daily volume searches or for further information please contact Frank Harrison at <a href="mailto:frank@croftanalytics.com">frank@croftanalytics.com</a> </p>
                    </>
                    :
                    <>
                        <p id="popupNoPermissionText"><b>SORRY, YOU HAVE REACHED THE LIMIT FOR FREE {searchTypeText}</b></p>
                        <p id="smallNoPermissionText">Please start a subscription <a href="https://croftanalytics.com/shareofsearching" target='_blank'>here</a> to get additional access to volume trends.</p>
                        <p>Please email <a href="mailto:frank@croftanalytics.com">frank@croftanalytics.com</a> if you have questions.</p><br />
                    </>
                }
                <button id="cancelOk" onClick={(e) => {
                    window.location.replace("/accountpage");
                }}>OK</button>
            </div>
        </div>
    )
}