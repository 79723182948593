import './Css/Home.css';
import './Css/Nav.css';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import normalLogo from '../images/CroftAnalytics.png';
import christmasLogo from '../images/CroftAnalyticsBaubles.png';
import halloweenLogo from '../images/CroftAnalyticsHalloween.png';
import homeIcon from '../images/homeIcon2.png';
import aboutIcon from '../images/aboutIcon2.png';
import accountIcon from '../images/accountIcon2.png';
import $ from 'jquery';
import { EnquirePopup } from './Components/Popups/EnquirePopup';


const Nav = () => {

  const [firstName, setFirstName] = useState(sessionStorage.getItem('userName'));
  const [showContactPopup, setShowContactPopup] = useState(false);


  const logo = easterEgg()

  const popupContactForm = () => {
    setShowContactPopup(!showContactPopup)
  }


  // if(navigator.userAgentData.mobile || (window.innerWidth <= 600 &&  window.innerHeight <= 900)){$("#topTrendingSearchesOuterDiv").css('display', 'none')}

  return (
    <nav id="navBar">
      {showContactPopup && <EnquirePopup setShowContactPopup={setShowContactPopup} />}
      <Link to='/selector' id="croftLink">
        <img src={logo} alt="Share Of Searching Logo" id="sosImage" />
      </Link>
      <h1 id="centerText">SHARE OF SEARCHING</h1>
      <div className="nav-Links">
        <Link to='/selector'>
          <img src={homeIcon} alt="Home Logo" id="searchIcon" title="Home" />
        </Link>
        <Link to='/about'>
          <img src={aboutIcon} alt="About Logo" id="aboutIcon" title="About" />
        </Link>
        <Link to='/accountpage'>
          <img src={accountIcon} alt="Account Logo" id="accountIcon" title="My Account" onMouseOver={(e) => $(".dropdown-content").show()} onMouseOut={(e) => $(".dropdown-content").hide()} />
        </Link>
        <a href="/accountpage" id="firstName">
          <span id="firstName">{firstName}</span>
        </a>
        <div className="dropdown-content" onMouseOver={(e) => $(".dropdown-content").show()} onMouseOut={(e) => $(".dropdown-content").hide()}>
          <a href="/accountpage">My Account</a>
          {/* <i><a target="_blank" href="https://chrome.google.com/webstore/detail/shareofsearching/jooafkjmiadpbblkncmjakcefnnomfkc"> Chrome Ext. </a></i> */}
          <a onClick={() => popupContactForm()}>Contact</a>
          <a href="/">Logout</a>
        </div>
      </div>
    </nav>
  );
}

function easterEgg() {
  const currentDate = new Date();

  if (currentDate.getMonth() === 9 && currentDate.getDate() === 31) {
    return halloweenLogo;
  } else if (currentDate.getMonth() === 11 && currentDate.getDate() > 0 && currentDate.getDate() < 27) {
    return christmasLogo;
  }
  return normalLogo
}

export default Nav;
