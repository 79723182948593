import HelperFunctions from "../../Helpers/HelperFunctions";
import Chart from "chart.js";
import $ from 'jquery';
import excelIcon from '../../../images/excelIcon.png';
import { useEffect, useState } from "react";
import { Constants } from "../Constants";
import { coloursArray, getChartSubTitleString, getDDMMMYYYYGraphDate, removeEndCommas } from "../../Helpers/HelpersConstants";

export const CreateMultiVolumeGraphRefactored = ({ chartDataObject, volumeChartRef, searchDataObj, downloadContainerElement, isSubscriber, setShowSubscribersOnly, refactoredPost }) => {

    const isIndexPage = (window.location.pathname === "/search");
    const isVolumePlus = (window.location.pathname === "/countrysearch");

    const updateChart = () => {

        var nameArray = chartDataObject.nameArray;
        var valuesArray = chartDataObject.volumeArray;
        if (!Array.isArray(valuesArray[0])) valuesArray = [valuesArray]
        const dateArray = chartDataObject.dateArray;
        const countriesArray = chartDataObject.countriesArray || null;
        const selectedTerm = chartDataObject.selectedTerm || null;

        var indexOfSelected = countriesArray?.indexOf(chartDataObject.selectedCountry) || 0
        if (indexOfSelected < 0) indexOfSelected = 0;

        const searchProperty = searchDataObj.property === "youtube" ? "YouTube" : "Google";
        if (!valuesArray || valuesArray.length === 0) { return null };
        const localColoursArray = coloursArray();

        if (isVolumePlus && selectedTerm && (selectedTerm !== "All")) {
            // Use for non percentage - graph plot values
            const indexOfSelectedSearchTerm = nameArray.indexOf(selectedTerm);
            var combinedValueArray = Array.from({ length: valuesArray.length }, () => []);
            valuesArray.forEach((countryResults, x) => {
                countryResults.forEach((element, i) => {
                    combinedValueArray[x].push(element.split(",")[indexOfSelectedSearchTerm])
                });
            })

            nameArray = countriesArray;
            $('#countryTrendsCountrySelect').val("All")

        } else {
            // Use for non percentage - graph plot values
            var combinedValueArray = new Array(valuesArray[indexOfSelected][0].split(",").length);
            for (var x = 0; x < combinedValueArray.length; x++) {
                combinedValueArray[x] = [];
            }
            valuesArray[indexOfSelected].forEach((item, i) => {
                var vArray = item.split(",");
                for (var c = 0; c < vArray.length; c++) {
                    combinedValueArray[c].push(vArray[c]);
                }
            });
            $('#countryTrendsCountrySelect').find('option').eq(indexOfSelected).prop('selected', true);
            $('#countryTrendsTermSelect').val("All")
        }

        nameArray = removeEndCommas(nameArray);


        $('#myVolumeChart').remove(); //.remove() this is my <canvas> element
        $('#myVolumeChartDiv').append(`<canvas id="myVolumeChart" ref=${volumeChartRef} />`)

        const canvas = volumeChartRef.current;
        var ctx = canvas.getContext('2d'); // Get the 2D rendering context

        // Ensure that the canvas element exis
        if (!canvas) return;

        // Clear the previous chart instance, if it exists
        if (canvas.chart) {
            canvas.chart.destroy();
        }
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        var concludingChart = new Chart(document.getElementById('myVolumeChart'), {
            type: "line",
            data: {
                labels: dateArray,
                datasets: [
                    { label: nameArray[0], data: combinedValueArray[0], fill: false, borderColor: localColoursArray[0] },
                ]
            },
            options: {
                animation: {
                    duration: 0
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                            userCallback: function (value, index, values) {
                                return value.toLocaleString();
                            },
                        },
                        scaleLabel: {
                            display: true,
                            labelString: isIndexPage ? `Trend in Volume Index` : 'Search Volume',
                            fontSize: 20,
                        },
                    }]
                },
                legend: {
                    labels: {
                        // This more specific font property overrides the global property
                        fontColor: 'black',
                        fontSize: 15,
                        fontStyle: 'bold',
                    }
                },
            }
        });
        for (var i = 1; i < nameArray.length; i++) {
            concludingChart.data.datasets.push({
                label: nameArray[i],
                fill: false,
                borderColor: localColoursArray[i],
                data: combinedValueArray[i],
            });
        }

        var country = searchDataObj.country ? searchDataObj.country.toUpperCase() : null;
        var category = searchDataObj.category ? searchDataObj.category.toUpperCase() : null;
        var startdate = !searchDataObj.startDate ? null : getDDMMMYYYYGraphDate(searchDataObj.startDate)
        var enddate = !searchDataObj.endDate ? null : getDDMMMYYYYGraphDate(searchDataObj.endDate)
        var shorthands = "";
        if (searchDataObj.stateShorthand !== "" && searchDataObj.regionShorthand !== "") {
            shorthands = searchDataObj.stateName + " | " + searchDataObj.region
        }
        if (searchDataObj.regionShorthand === "" && searchDataObj.stateShorthand !== "") {
            shorthands = searchDataObj.stateName
        }

        for (var c = 0; c < dateArray.length; c++) {
            var date = dateArray[c].split(" ");
            // if (parseInt(date[1].substring(0, date[1].length - 1)) < 10) {
            // }
            dateArray[c] = date.join(" ");
            dateArray[c] = dateArray[c].replace(',', '');
            dateArray[c] = dateArray[c].replace('\u2013', '-');
        }


        document.getElementById('myVolumeChart').onclick = function (evt) {
            var activePoints = concludingChart.getElementsAtEvent(evt);
            const index = activePoints[0]?._index;
            if (activePoints.length) {
                var mouse_position = Chart.helpers.getRelativePosition(evt, concludingChart.chart);

                activePoints = $.grep(activePoints, function (activePoint, index) {
                    var leftX = activePoint._model.x - 5,
                        rightX = activePoint._model.x + 5,
                        topY = activePoint._model.y + 5,
                        bottomY = activePoint._model.y - 5;

                    return mouse_position.x >= leftX && mouse_position.x <= rightX && mouse_position.y >= bottomY && mouse_position.y <= topY;
                });
                const activeDataSet = parseInt(activePoints[0]._datasetIndex);
                const searchedFor = nameArray[activeDataSet].trim();
                const chartData = activePoints[0]._chart.data;
                let indexDate = chartData.labels[index];
                const monthToSearch = [];
                let yearToSearch = 2022;
                indexDate.split(" ").forEach((item, i) => {
                    if (item.length === 4 && parseInt(item) > 0) { yearToSearch = parseInt(item) }
                    if (item.length !== 3) { return }
                    if (parseInt(item) >= 0) { return }
                    monthToSearch.push(item)
                });
                if (indexDate.length < 14) {
                    window.open("https://www.google.com/search?q=" + searchedFor + " " + indexDate);
                } else {
                    window.open("https://www.google.com/search?q=" + searchedFor + " " + monthToSearch[monthToSearch.length - 1] + " " + yearToSearch);
                }

                // Uncomment the 4 lines below & delete this line, to just get Month & Year google searches. e.g. 'bob Jan 2010'
                // while(!isNaN(parseInt(indexDate.charAt(0))))
                // {
                //  indexDate = indexDate.substring(1).trim();
                // }
            }
        }.bind(this);


        var elementToRemove = document.getElementById("volumeDownloadbutton");

        // Check if the element exists before attempting to remove it
        if (elementToRemove) {
            elementToRemove.parentNode.removeChild(elementToRemove);
        }

        var button = document.createElement("button");
        button.id = "volumeDownloadbutton"
        button.innerHTML = `<div id="downloadButtonDiv"><img id='downloadIcon' style="margin-right: 10%" src=${excelIcon} /><b>EXPORT VOLUME ${isIndexPage ? "INDEX" : ""}</b></div>`
        button.onclick = function () {

            if (!isSubscriber) {
                setShowSubscribersOnly(true)
                return;
            }

            var namesArray = nameArray;
            var datesArray = dateArray

            var countriesArr = chartDataObject?.countriesArray || ""
            const volumePlusArr = [null,];

            if (isVolumePlus) {
                valuesArray.forEach((country, i) => {
                    volumePlusArr.push(countriesArr[i]);
                    nameArray.forEach((term, o) => {
                        if (o !== 0) {
                            volumePlusArr.push(null)
                        }
                    })
                })
            }

            var rows = [
                [`${searchProperty} SEARCH VOLUME ${isIndexPage ? "INDEX" : ""} / ` + (!isVolumePlus ? country : '') +
                    (!isIndexPage ? "" : shorthands !== "" ? ` ( ${shorthands} )` : "") +
                    (!isIndexPage ? "" : " | " + category) +
                    " | " + startdate +
                    " - " + enddate],
                (isVolumePlus ? volumePlusArr : null),
                ["Dates", namesArray[0]]
            ];

            if (isVolumePlus) {
                for (var o = 0; o < valuesArray.length; o++) {
                    for (var x = (o === 0 ? 1 : 0); x < namesArray.length; x++) {
                        rows[2].push(namesArray[x]);
                    }
                }
            } else {
                rows[1] = ["Dates", namesArray[0]]; // Set rows[1] as the array containing "Dates"
                rows.splice(2, 1); // Remove row[2]
                for (var x = 1; x < namesArray.length; x++) {
                    rows[1].push(namesArray[x]);
                }
            }



            if (isVolumePlus) {
                for (var x = 0; x < datesArray.length; x++) {
                    var arr = [];
                    for (var i = 0; i < valuesArray.length; i++) {
                        arr.push(valuesArray[i][x].split(","));
                        for (var l = 0; l < arr.length; l++) {
                            arr[l] = arr[l];
                        }
                    }
                    arr.join()
                    rows.push(Array.of(datesArray[x], arr));
                }
            } else {
                for (var x = 0; x < datesArray.length; x++) {
                    var arr = [];
                    for (var i = 0; i < combinedValueArray.length; i++) {
                        arr.push(combinedValueArray[i][x].split(","));
                        for (var l = 0; l < arr.length; l++) {
                            arr[l] = arr[l];
                        }
                    }
                    arr.join()
                    rows.push(Array.of(datesArray[x], arr));
                }
            }

            let csvContent = "";

            rows.forEach(function (rowArray) {
                let row = rowArray.join(",");
                csvContent += row + "\r\n";
            });

            var encodedUri = encodeURI(csvContent);
            var link = document.createElement("a");
            link.setAttribute("href", "data:text/csv;charset=utf-8,\uFEFF" + encodedUri);
            link.setAttribute("download", "volumeSOS.csv");
            document.body.appendChild(link); // Required for FF

            link.click(); // This will download the data file named "my_data.csv".
        };
        // 2. Append somewhere
        downloadContainerElement.appendChild(button);

        // 3. Add event handler
        concludingChart.update();
    }

    useEffect(() => {
        if (!chartDataObject) return null;
        setTimeout(() => {
            updateChart()
        }, 500);
    }, [chartDataObject])

    if (!chartDataObject) return null;

    const property = chartDataObject.property === "youtube" ? "YouTube" : "Google";;
    const page = chartDataObject.page;

    var chartSubTitleString = getChartSubTitleString({ refactoredPost, page: isVolumePlus ? Constants.VOLUME_PLUS_TYPE : "", chart: "myVolumeChart", chartDataObject })

    const headerHoverText = ({ page }) => {
        const selectedTerm = chartDataObject.selectedTerm || null;
        switch (page) {
            case Constants.INDEX_TYPE:
                return (
                    <div id="graphInfoIText1">
                        This chart shows the trend in relative search volume (shown as an index) for the search terms. These data are sourced from Google Trends. <br />
                        - The data are shown as an index, with the peak of 100 being for the search term with the highest search volume during the period - all other data points are volumes indexed relative to the search term volume shown as 100. <br />
                        - The Google methodology for these data is to use a random sample of all search queries. This means the data can vary from one search query to another, and the variance will be greater for low volume terms. <br />
                        - Click on data dots to view Google search results for the term and date (helps to explain search spikes)
                    </div>);
            case Constants.VOLUME_TYPE:
                return (
                    <div id="graphInfoIText1" >
                        This charts shows the trend in monthly search volume for each search term.These data are sourced from Google Keyword Planner. <br />
                        - Each data point is the total volume of search for the search term for the month. <br />
                        - These data are rolled up or down by Google so it is possible to see several months with the same search volume.
                    </div >);
            case Constants.VOLUME_PLUS_TYPE:
                if (selectedTerm) {
                    return (
                        <div id="graphInfoIText1" >
                            This chart shows the trend in monthly search volume for the selected search term
                            in each of the countries.These data are sourced from Google Keyword Planner.
                            - Each data point is the total volume for the search term in the country for the
                            month.
                            - These data are rolled up or down by Google so it is possible to see several months
                            with the same search volume
                        </div >);
                }
                return (
                    <div id="graphInfoIText1" >
                        This chart shows the trend in monthly search volume for the search terms in the
                        selected country. These data are sourced from Google Keyword Planner.
                        - Each data point is the total volume for the search term for the month.
                        - These data are rolled up or down by Google so it is possible to see several months
                        with the same search volume
                    </div >);
            case Constants.SIMILAR_TYPE:
                return (
                    <div id="graphInfoIText1" >
                        This chart shows the trend in relative search volume(shown as an index) for the search terms.These data are sourced from Google Trends. <br />
                        - The data are shown as an index, with the peak of 100 being for the search term with the highest search volume during the period - all other data points are volumes indexed relative to the search term volume shown as 100. <br />
                        - The Google methodology for these data is to use a random sample of all search queries.This means the data can vary from one search query to another, and the variance will be greater for low volume terms. <br />
                        - Click on data dots to view Google search results for the term and date(helps to explain search spikes)
                    </div>
                )
            default:
                return (
                    <div id="graphInfoIText1">
                        This chart shows the trend in relative search volume (shown as an index) for the search terms. These data are sourced from Google Trends. <br />
                        - The data are shown as an index, with the peak of 100 being for the search term with the highest search volume during the period - all other data points are volumes indexed relative to the search term volume shown as 100. <br />
                        - The Google methodology for these data is to use a random sample of all search queries. This means the data can vary from one search query to another, and the variance will be greater for low volume terms. <br />
                        - Click on data dots to view Google search results for the term and date (helps to explain search spikes)
                    </div>);
        }
    }

    return (
        <div id="myVolumeChartDiv">
            <p class="ChartTitles ChartTitle1">{property} Trend in Search Volume {isIndexPage ? 'Index' : ''}</p>
            {headerHoverText({ page })}
            <p class="chartSubTitles">{chartSubTitleString}</p>
            {isVolumePlus && <p class="chartSubTitles Font-Bold Color-Darkgrey ">{chartDataObject.selectedTerm ? chartDataObject.selectedTerm : chartDataObject.selectedCountry ? chartDataObject.selectedCountry : chartDataObject.countriesArray[0]}</p>}
            <canvas
                id="myVolumeChart"
                ref={volumeChartRef}
                style={{ height: "auto" }}
            />
        </div>
    );
};