export const ConfirmDeletePopup = ({ showConfirmDeletePopup, setShowConfirmDeletePopup, reportData, setReportData, setHasReports, environmentUrl }) => {
    var show = showConfirmDeletePopup.show;
    var id = showConfirmDeletePopup.id;
    return (<div className="Popup">
        <div className="PopupBox">
            <p id="PopupHeaderText"><b>Confirm Deletion</b></p>
            <p id="PopupSubHeaderText"> Are you sure you want to delete this report </p>
            <div id="yesNoButtons">
                <button id="cancelOk" onClick={(e) => confirmDeleteFunc(id, setShowConfirmDeletePopup, reportData, setReportData, setHasReports, environmentUrl)}>YES</button>
                <button id="cancelOk" onClick={(e) => setShowConfirmDeletePopup({ show: false, id })}>NO</button>
            </div>
        </div>
    </div>
    )
}

async function confirmDeleteFunc(id, setShowConfirmDeletePopup, reportData, setReportData, setHasReports, environmentUrl) {
    var email = sessionStorage.getItem('user');
    var accountId = sessionStorage.getItem('id');
    const reportId = parseInt(id);
    const newReports = reportData.filter(report => report.reportId !== reportId)
    setReportData(newReports);
    if (newReports.length < 1) {
        setHasReports(false)
    }
    const response = await fetch(environmentUrl + 'reports/deletereport', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ post: { email: email, id: accountId, reportId: reportId } }),
    });
    const deletedResponse = await response.json();

    if (deletedResponse === false) {
        alert("Error: Report not deleted.");
        window.location.reload();
    }

    setShowConfirmDeletePopup({ show: false, id: null });
}
