import React from 'react';
import './Css/Home.css';
import './Css/About.css';
import './Css/AboutLoginPage.css';
import { Link } from 'react-router-dom';
import backIcon from '../images/Back.png';
import logo from '../images/ShareOfSearching.png';


function AboutLoginPage() {
  document.title = "Share Of Searching";
  return (
    <div className="About">
      <div id="navLoginAboutHider"></div>
      <Link to='/' style={{ textDecoration: 'none' }} id="backLink">
        <img src={backIcon} alt="Logo" id="backIcon" />
      </Link>
      <img src={logo} alt="Logo" id="sosingAboutImage" />
      <div id="aboutText">
        <h1 id="aboutTitleText">About the Share-Of-Searching App</h1>
        <p id="aboutBaseText">
          You can use the Share-Of-Searching app to generate charts showing trends in Google search volumes and share of search, with historic trend data for the last four years through to today, for search terms (e.g. brand names). You can filter the data by country, category, and date range, and export the data to Excel.
          <br /><br />
        </p>

        <h1 id="aboutTitleText">Why share of search is a useful metric</h1>
        <p id="aboutBaseText">
          Analysis of Google search data shows that, in many categories, a brand’s share of category search can be used to predict its market share. This particularly applies where search is a key activity in the process of deciding what to buy, for example in (but not limited to) automotive, finance, retail, telecoms, travel, and electronic goods categories.
          <br /><br />
        </p>

        <h1 id="aboutTitleText">Creating your account and using the app</h1>
        <p id="aboutBaseText">
          Once you create your account you can use Share-Of-Searching at no cost for seven days. At any time during your free trial you can subscribe to use Share-Of-Searching for 12 months for £500. After you have logged in, click on the My Account icon (top right of the screen) to access the subscription link. You can pay by debit card, credit card, or through a PayPal account.
          <br /><br />
          If your free trial has ended, when you log in you will be taken to the My Account page where you can access the link to start a subscription.
        </p><br />

        <h1 id="aboutTitleText">Contact information</h1>
        <p id="contactInfoSubText">
          Share-Of-Searching is developed by Croft Analytics. For more information please contact Frank Harrison at <a href="mailto:frank@croftanalytics.com">frank@croftanalytics.com</a>
          <br /><br />
        </p>
      </div>
    </div>
  );
}

export default AboutLoginPage;
