import React, { useState, useEffect } from 'react'
import $ from 'jquery';
import { Alert } from 'bootstrap';

const UserAccountController = ({ accountInfo, environment }) => {

    const currentDate = new Date();
    const [showUpdateForm, setShowUpdateForm] = useState(false)
    const [showPasswordForm, setShowPasswordForm] = useState(false)
    const [showEmailForm, setShowEmailForm] = useState(false)
    const [newPassword, setNewPassword] = useState()
    const [newPasswordConfirm, setNewPasswordConfirm] = useState()
    const [newEmail, setNewEmail] = useState()
    const [newSubscriptionType, setNewSubscriptionType] = useState("FREE")
    const [newAccountInfo, setNewAccountInfo] = useState({
        email: accountInfo.Email,
        subscriptionType: accountInfo.Subscription,
        subscriptionStartDate: currentDate.getDate() + "/" + (currentDate.getUTCMonth() + 1) + "/" + (currentDate.getFullYear()) + " 02:00:00",
        subscriptionEndDate: accountInfo.SubscriptionEndDate,
        allowSearch: 1,
        subscriptionComment: accountInfo.Comment
    })

    const addToNewAccount = (key, value) => {
        setNewAccountInfo(oldAccountInfo => ({ ...oldAccountInfo, [key]: value }));
    };

    useEffect(() => {
        if (!accountInfo.isExpired) {
            $("#extraInfo").text(" - OK");
            $("#extraInfo").css('font-weight', 'bold');;
            $("#extraInfo").css('color', 'green');;
        } else if (accountInfo.isExpired) {
            $("#extraInfo").text(" - ERROR");
            $("#extraInfo").css('font-weight', 'bold');;
            $("#extraInfo").css('color', 'red');
        }
    }, [accountInfo])


    useEffect(() => {
        if (!newSubscriptionType) return;
        addToNewAccount("subscriptionType", newSubscriptionType);
        switch (newSubscriptionType) {
            case 'FREE':
                var twoWeeks = 1000 * 60 * 60 * 24 * 14;
                var twoWeeksTime = new Date(new Date().getTime() + twoWeeks);
                var formattedEndDate = twoWeeksTime.getDate() + '/' + (twoWeeksTime.getMonth() + 1) + '/' + twoWeeksTime.getFullYear() + " 02:00:00";
                addToNewAccount("subscriptionEndDate", formattedEndDate)
                break;
            case 'PAID':
                addToNewAccount("subscriptionEndDate", currentDate.getDate() + "/" + (currentDate.getUTCMonth() + 1) + "/" + (currentDate.getFullYear() + 1) + " 02:00:00")
                break;
            case 'ADMIN':
                addToNewAccount("subscriptionEndDate", currentDate.getDate() + "/" + (currentDate.getUTCMonth() + 1) + "/" + (currentDate.getFullYear() + 100) + " 02:00:00");
                break;
            default:
                alert("error: formatEndDate");
                break;
        }
    }, [newSubscriptionType])


    const deleteAccount = async (e) => {
        if (window.confirm("Ollie says: Hmm... you sure you want to fully delete this account (Cannot be undone)?")) {
            if (window.confirm("Double Checking: Are you definitely sure you want to delete this account?")) {
                const response = await fetch(environment.url + 'deleteaccountbyemail', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ post: { email: accountInfo.Email } }),
                });
                const deleteAccountResponse = await response.json();
                if (deleteAccountResponse === true) {
                    alert("Account deleted.");
                    window.location.reload();
                } else {
                    alert("Error: Account not deleted.");
                    window.location.reload();
                }
            }
        }
    }

    const changePassword = async e => {
        e.preventDefault();

        if (newPassword !== newPasswordConfirm) {
            alert("Error: New passwords do not match.")
        } else {
            const response = await fetch(environment.url + 'updatepassword', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ post: { email: accountInfo.Email, password: newPassword } }),
            });

            const passwordChangedResponse = await response.json();

            if (passwordChangedResponse) {
                alert("Success: Password changed!");
            } else {
                alert("Error: Password not changed");
            }
            setShowPasswordForm(false);
        }
    }

    const changeEmail = async e => {
        e.preventDefault();

        const response = await fetch(environment.url + 'updateemail', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ post: { email: newAccountInfo.email } })
        });

        const emailChangedResponse = await response.json();

        if (emailChangedResponse === true) {
            alert("Success: Email changed!");
            window.location.reload();
        } else {
            alert("Error: Email not changed");
        }
        setShowEmailForm(false);
    }

    const updateSubscription = async e => {
        e.preventDefault();

        const response = await fetch(environment.url + 'setsubscription', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ post: newAccountInfo }),
        });

        const subscriptionUpdatedBoolean = await response.json();
        if (subscriptionUpdatedBoolean === true) {
            alert("Success: Subscription updated!");
            window.location.reload();
        } else {
            alert("Error: Subscription not updated.");
            window.location.reload();
        }
    }

    return (
        <div className="UserAccountController">
            <div id="accountInfo">
                <h3 id="accountInfoHeaderText">Account Info:</h3>
                <div id="accountInfoDiv">
                    <p id="accountInfoLabel"><b>Id: </b></p><span id="accountInfoText">{accountInfo.Id}</span>
                    <p id="accountInfoLabel"><b>First Name: </b></p><span id="accountInfoText">{accountInfo.FirstName}</span>
                    <p id="accountInfoLabel"><b>Last Name: </b></p><span id="accountInfoText">{accountInfo.LastName}</span>
                    <p id="accountInfoLabel"><b>Email: </b></p><span id="accountInfoText">{accountInfo.Email}</span>
                </div>
                <div id="accountInfoDiv">
                    <p id="accountInfoLabel"><b>Country: </b></p><span id="accountInfoText">{accountInfo.Country}</span>
                    <p id="accountInfoLabel"><b>Company: </b></p><span id="accountInfoText">{accountInfo.Company}</span>
                    <p id="accountInfoLabel"><b>Job Title:</b></p><span id="accountInfoText">{accountInfo.JobTitle}</span>
                </div>
                <div id="accountInfoDiv">
                    <p id="accountInfoLabel"><b>Subscription: </b></p><span id="accountInfoText">{accountInfo.Subscription}</span>
                    <p id="accountInfoLabel"><b>Allowed to search: </b></p><span id="accountInfoText">{accountInfo.AllowSearch === 1 ? "True" : "False"}</span>
                </div>
                <div id="accountInfoDiv">
                    <p id="accountInfoLabel"><b>Creation IP Address: </b></p><span id="accountInfoText">{accountInfo.CreationIpAddress || "N/A"}</span>
                    <p id="accountInfoLabel"><b>Comment: </b></p><span id="accountInfoText">{accountInfo.Comment}</span>
                </div>
                <div id="accountInfoDiv">
                    <p id="accountInfoLabel"><b>Subscription Start Date: </b></p><span id="accountInfoText">{accountInfo.SubscriptionStartDate.replace("T", " ").replace("Z", " ").slice(0, -5)}</span>
                    <p id="accountInfoLabel"><b>Subscription End Date: </b></p><span id="accountInfoText"></span>{accountInfo.SubscriptionEndDate.replace("T", " ").replace("Z", " ").slice(0, -5)}<span id="extraInfo"></span>
                </div>
            </div>
            <div id="accountOptions">
                <button id="deleteAccountAdminButton" type="button" onClick={(e) => deleteAccount(e)}>Delete Account</button>
                <button id="showUpdateSubscriptionAdminButton" type="button" onClick={(e) => setShowUpdateForm(!showUpdateForm)}>Update Subscription</button>
                <button id="changePasswordAdminButton" onClick={(e) => setShowPasswordForm(!showPasswordForm)}>Change Password</button>
                <button id="changePasswordAdminButton" onClick={(e) => setShowEmailForm(!showEmailForm)}>Change Email</button><br /><br />

                {showUpdateForm && (
                    <form id="updateSubscriptionForm" onSubmit={(e) => updateSubscription(e)}>
                        <div>
                            Subscription Type: <select id="subscriptionType" onChange={(e) => setNewSubscriptionType(e.target.value)}>
                                <option value="FREE">Free</option>
                                <option value="PAID">Paid</option>
                                <option value="EXPIRED">Expired</option>
                                <option value="ADMIN">Admin</option>
                            </select>
                            &nbsp; Subscription Comment:
                            <input type="text" id="commentInput" />
                        </div>
                        <div>
                            Subscription Start Date:
                            <input type="text" id="subscriptionStartDateInput" placeholder="e.g. 17/07/2013 18:33:55" onChange={(e) => addToNewAccount("subscriptionStartDate", e.target.value)} value={newAccountInfo.subscriptionStartDate} />
                            &nbsp; Subscription End Date:
                            <input type="text" id="subscriptionEndDateInput" placeholder="e.g. 17/07/2014 18:33:55" onChange={(e) => addToNewAccount("subscriptionEndDate", e.target.value)} value={newAccountInfo.subscriptionEndDate} />
                        </div>
                        <div>
                            Set allow access to search:
                            <select id="allowSearchBoolean" onChange={
                                (e) => addToNewAccount("allowSearch", parseInt(e.target.value))
                            }>
                                <option value="1">True (Allow)</option>
                                <option value="0">False (Disallow)</option>
                            </select>
                            &nbsp;
                            <button type="submit" id="updateSubscriptionSubmitButton">Update</button>
                        </div>
                    </form>
                )}
                {showPasswordForm && (
                    <form id="updatePasswordForm" onSubmit={(e) => changePassword(e)}>
                        New Password: <input type="password" id="newPasswordUpdate" minLength="4" onChange={(e) => setNewPassword(e.target.value)} />
                        <span style={{ position: 'relative', top: 5 }}>&nbsp; <br /> New Password Confirmation: <input type="password" id="newPasswordConfirmUpdate" minLength="4" onChange={(e) => setNewPasswordConfirm(e.target.value)} />
                            &nbsp; <button type="submit">Update</button>
                        </span>
                    </form>
                )}
                {showEmailForm && (
                    <form id="updateEmailForm" onSubmit={(e) => changeEmail(e)}>
                        New Email: <input type="email" id="newEmail" onChange={(e) => addToNewAccount("email", e.target.value)} />
                        &nbsp; <button type="submit">Update</button>
                    </form>
                )}
            </div>
        </div >
    )
}

export default UserAccountController