import { Link } from 'react-router-dom';
import { useState } from 'react';
import { EnquirePopup } from './Popups/EnquirePopup';

export const Footer = ({ withExtras }) => {

  const loggedInUser = sessionStorage.getItem("user") !== "null"
  const [showContactPopup, setShowContactPopup] = useState(false);

  const popupContactForm = () => {
    setShowContactPopup(!showContactPopup)
  }

  return (
    <div className="Footer">
      {showContactPopup && <EnquirePopup setShowContactPopup={setShowContactPopup} />}
      <div id="subFooter">
        <p>© {new Date().getFullYear()} CROFT ANALYTICS</p>
        {withExtras && loggedInUser && (
          <>
            <Link to='/selector' style={{ textDecoration: 'none' }} id="footerLink">
              <p id="footerSearchText">&nbsp; Home &nbsp;</p>
            </Link>
            <Link to='/about' style={{ textDecoration: 'none' }} id="footerLink">
              <p id="footerAboutText">&nbsp; About &nbsp;</p>
            </Link>
            <Link to='/accountpage' style={{ textDecoration: 'none' }} id="footerLink">
              <p id="footerAccountText">&nbsp; Account &nbsp;</p>
            </Link>

          </>
        )}
        {!loggedInUser && (
          <Link to='/' style={{ textDecoration: 'none' }} id="footerLink">
            <p id="footerHomeText">&nbsp; Home</p>
          </Link>
        )}
        <p id="footerLink" onClick={() => popupContactForm()}>Contact</p>
        <Link to='/privacy' style={{ textDecoration: 'none' }} id="footerLink">
          <p id="footerPrivacyText">&nbsp; Privacy Policy</p>
        </Link>
        <Link to='/termsandconditions' style={{ textDecoration: 'none' }} id="footerLink">
          <p id="footerT&CText">&nbsp; T&Cs</p>
        </Link>
      </div>
    </div>
  )
};
