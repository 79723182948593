import React, { useState, useEffect } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import '../Css/PageSelector.css';
import { Constants } from '../Components/Constants';
import HelperFunctions from '../Helpers/HelperFunctions';
import $ from 'jquery';
import { getUserAccountInfo, searchAllowed, testEnvironment } from '../Helpers/HelpersConstants';
import { Footer } from '../Components/Footer';

const PageSelector = () => {
    const environment = testEnvironment(window.location.hostname);
    $('head').append('<link rel="stylesheet" href="https://netdna.bootstrapcdn.com/font-awesome/4.0.3/css/font-awesome.min.css">');

    const email = getUserAccountInfo().email;
    const accountId = getUserAccountInfo().accountId;
    const isSmall = (window.innerWidth < 535);
    const history = useHistory();
    const [showP, setShowP] = useState(null)
    const [paypalUrl, setPaypalUrl] = useState("https://croftanalytics.com/shareofsearchingint");
    const [userInfo, setUserInfo] = useState({
        email,
        accountId,
        isSubscriber: false,
        isExpired: false,
        allowed: [],
        dailyLimitsReached: [],
        verified: true // replaced showNoPermission
    });

    useEffect(() => {
        changeUserInfo("paypalLink", paypalUrl);
    }, [paypalUrl])


    const changeUserInfo = (field, value) => {
        setUserInfo(prevState => ({ ...prevState, [field]: value }));
    }


    useEffect(() => {
        const pulseArr = [
            document.getElementById("pulse1"),
            document.getElementById("pulse2"),
            document.getElementById("pulse3"),
            document.getElementById("pulse4"),
            document.getElementById("pulse5")
        ];

        pulseArr.forEach((pulse, index) => {
            setTimeout(() => {
                pulse.classList.add('pulse');

                // Remove the 'pulse' class after 2 seconds
                setTimeout(() => {
                    pulse.classList.remove('pulse');
                }, 2000);

            }, index * 2000);
        });

        searchAllowed({ userInfo, environment, paypalUrl, setPaypalUrl, changeUserInfo })
    }, [])

    const handleDivClick = (toPathName) => {
        const variables = {
            environment,
            userInfo,
        };

        // Use history.push to navigate to the new route and pass state
        history.push({
            pathname: toPathName,
            state: variables,
        });
    };

    return (
        <>
            <div className='SelectorDiv' onClick={() => setShowP()}>
                <div className='TabSelector pulse1' id="pulse1" onMouseOver={() => setShowP(Constants.VOLUME_TYPE)} onClick={() => handleDivClick("/volumesearch")}>
                    <h2 id="tabHeader">Volume Trends</h2>
                    {(showP === Constants.VOLUME_TYPE || isSmall) && (
                        <p id="tabBody">
                            Enter up to 30 search terms to compare monthly trends in Google search volumes and % share of search. Choose country and date range  (back four years)
                        </p>
                    )}
                </div>
                <div className='TabSelector pulse2' id="pulse2" onMouseOver={() => setShowP(Constants.VOLUME_PLUS_TYPE)} onClick={() => handleDivClick("/countrysearch")}>
                    <h2 id="tabHeader">Country Trends</h2>
                    {(showP === Constants.VOLUME_PLUS_TYPE || isSmall) && (
                        <p id="tabBody">
                            Enter up to 30 search terms to compare monthly trends in Google search volumes and % share of search. Choose <b>multiple</b> countries and date range  (back four years)
                        </p>
                    )}
                </div>
                <div className='TabSelector pulse3' id="pulse3" onMouseOver={() => setShowP(Constants.SIMILAR_TYPE)} onClick={() => handleDivClick("/similarsearch")}>
                    <h2 id="tabHeader">Similar Terms</h2>
                    {(showP === Constants.SIMILAR_TYPE || isSmall) && (
                        <p id="tabBody">
                            Enter a single search term to compare trends in Google search volumes and % share of search for related terms, with historic trend data for the last four years. Choose country and date range.
                        </p>
                    )}
                </div>
            </div>
            <div className='SelectorDiv' onClick={() => setShowP()}>
                <div className='TabSelector pulse4' id="pulse4" onMouseOver={() => setShowP(Constants.REPORTS_TYPE)} onClick={() => handleDivClick("/myreports")}>
                    <h2 id="tabHeader">My Reports</h2>
                    {(showP === Constants.REPORTS_TYPE || isSmall) && (
                        <p id="tabBody">
                            Create custom Volume Trends reports. Schedule monthly emails with CSV files containing search volumes and % share of search for each report.
                        </p>
                    )}</div>
                <div className='TabSelector pulse5' id="pulse5" onMouseOver={() => setShowP(Constants.INDEX_TYPE)} onClick={() => handleDivClick("/search")}>
                    <h2 id="tabHeader">Index Trends</h2>
                    {(showP === Constants.INDEX_TYPE || isSmall) && (
                        <p id="tabBody">
                            Enter up to 30 search terms to compare trends in Google or YouTube search volumes (relative index) and % share of search. Choose country, category, and date range (back to 2004)
                        </p>
                    )}
                </div>
            </div>
            <Footer withExtras />
        </>
    )
}

export default PageSelector;