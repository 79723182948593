import { Tabs } from '../Components/Tabs.js';
import $ from 'jquery';
import { useEffect, useState } from 'react';
import '../Css/Buttons.css';
import '../Css/Reports.css';
import '../Css/ReportPopups.css';
import '../Css/Popup.css';
import '../Css/Generics.css';
import CompareDates from '../CompareDates.js';
import { NotAllowedHerePopup } from "../Components/Popups/NotAllowedHerePopup.js";
import { ExpiredSubscriptionPopup } from "../Components/Popups/ExpiredSubscriptionPopup.js";
import { EmptyInbox } from '../../images/SVGs.js';
import { AddReportPopup } from '../Components/Popups/AddReportPopup.js';
import { EditReportPopup } from '../Components/Popups/EditReportPopup.js';
import { Button } from '../Components/Button.js';
import { ReportInfoPopup } from '../Components/Popups/ReportInfoPopup.js';
import { ConfirmDeletePopup } from '../Components/Popups/ConfirmDeletePopup.js';
import { Footer } from '../Components/Footer.js';
import { datediff, longMonths, parseDate } from "../Helpers/HelpersConstants";

const ReportsPage = ({ location }) => {
    const { environment, userInfo } = location.state || {};

    var environmentUrl = 'https://servershareofsearch.herokuapp.com/api/';
    var isLocal = false;
    var email = sessionStorage.getItem('user');
    var accountId = sessionStorage.getItem('id');
    var isSubscriber = false;
    var allowedSearch = 1;

    environmentUrl = environment.url;
    isLocal = environment.localhost;

    const [subscriptionType, setSubscriptionType] = useState(null);
    const [reportData, setReportData] = useState(new Array());
    const [selectedReport, setSelectedReport] = useState({});
    const [hasReports, setHasReports] = useState(reportData.length > 0);
    const [editing, setEditing] = useState(false);
    const [addingReport, setAddingReport] = useState(false);
    const [showInformation, setShowInformation] = useState(false);
    const [showConfirmDeletePopup, setShowConfirmDeletePopup] = useState({ show: false, id: null });
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [notAllowed, setNotAllowed] = useState(false);
    const [showExpiredSubscription, setShowExpiredSubscription] = useState(false);

    useEffect((e) => {
        $("#reportsTab").css("background-color", "rgb(84,155,53)");
        $("#reportsTab").css("color", "white");
        $("#reportsTab").css("border-bottom", "thin solid darkgreen");
        $("#reportsTab").css("border-left", "thin solid darkgreen");
        $("#reportsTab").css("border-right", "thin solid darkgreen");
        $("#reportsTab").css("cursor", "auto");
        $("#loader").css("border", "16px solid rgb(100,149,237)");
        searchAllowed(e);
        getReports(e);
    }, []);

    async function getReports(e) {
        const id = (sessionStorage.getItem("id"));
        const email = sessionStorage.getItem("user");

        const response = await fetch(environmentUrl + 'reports/getreports', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ post: { id, email } }),
        });
        const reports = await response.json();
        if (reports.results.length > 0) {
            setHasReports(true);
        } else { setHasReports(false) }
        const dataToStore = reports.results.map(report => ({ reportName: report.Report_Name, reportCreatedEdited: report.Date_Created_Edited.toString().slice(0, 19).replace('T', ' '), searchType: report.Search_Type, searchTerms: report.Search_Terms, searchCategory: report.Search_Category, searchCategoryId: report.Search_Category_Id, searchCountry: report.Search_Country, searchCountryShorthand: report.Search_Country_Shorthand, searchPeriod: report.Search_Period, reportFrequency: report.Report_Frequency, reportId: report.Id, property: report.Property })).sort(function (a, b) {
            return new Date(b.reportCreatedEdited) - new Date(a.reportCreatedEdited);
        });

        setReportData(dataToStore)
    }

    async function searchAllowed(e) {
        var accountInfo = { email };

        if (email === null || email === "null") {
            window.location.replace("/login");
        }

        const response = await fetch(environmentUrl + 'checkallowedsearch', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ post: accountInfo }),
        });
        const allowedSearchResponse = await response.json();
        var allowedSearchBinary = allowedSearchResponse[0].AllowSearch;
        var subEndDate = allowedSearchResponse[0].SubscriptionEndDate;
        var subEndDateVar = new Date(allowedSearchResponse[0].SubscriptionEndDate.replace("T", " ").replace("Z", " ").substring(0, 10));
        var subEndDateText = subEndDateVar.getDate() + " " + longMonths()[subEndDateVar.getUTCMonth()] + " " + subEndDateVar.getFullYear();
        var extendedSubEndDateText = subEndDateVar.getDate() + " " + longMonths()[subEndDateVar.getUTCMonth()] + " " + (subEndDateVar.getFullYear() + 1);
        var subscriptionType = allowedSearchResponse[0].Subscription;
        setSubscriptionType(allowedSearchResponse[0].Subscription)
        var volumeSearchCount = allowedSearchResponse[0].volumeSearchCount;
        var daysBetweenDates = datediff(parseDate(new Date().getUTCMonth() + "/" + new Date().getDate() + "/" + new Date().getFullYear()), parseDate(subEndDateVar.getUTCMonth() + "/" + subEndDateVar.getDate() + "/" + subEndDateVar.getFullYear()));

        if (subscriptionType !== "FREE" && subscriptionType !== "EXPIRED") { isSubscriber = true }

        var isExpired = CompareDates.compare_dates(new Date(), new Date(subEndDate))

        if (isExpired === "Now > Date2") {
            var allowSearchToDb = 0;
            accountInfo.allowSearchUpdate = allowSearchToDb;

            const response = await fetch(environmentUrl + 'setexpired', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ post: accountInfo }),
            });
        }

        if (subscriptionType === "FREE" || allowedSearchBinary === 0 || email === null || email === "null") {
            allowedSearch = 0;
            setNotAllowed(true);
        } else if (subscriptionType === "EXPIRED" || isExpired === "Now > Date2") {
            allowedSearch = 0;
            setShowExpiredSubscription(true);
            $("#subscriptionStatus").html('Your current subscription has <b>Expired</b> - Click <a id="hereAttr" href="https://croftanalytics.com/shareofsearching"><i>here</i></a> to renew for another year.');
        } else if (daysBetweenDates < 31) {
            $("#subscriptionStatus").html('Your subscription ends on <b>' + subEndDateText + '</b> - to extend it to ' + extendedSubEndDateText + ' click <a id="hereAttr" href="https://croftanalytics.com/shareofsearching"><i>here</i></a>.');
        }
        else {
            $("#subscriptionStatus").remove();
        }
    }

    function editReport(e) {
        const reportEditId = parseInt(e.target.id);
        const chosenReport = reportData.filter(report => report.reportId === reportEditId)
        setSelectedReport(chosenReport[0]);
        setEditing(true);
    }


    async function deleteReport(e) {
        // Save it!
        setShowConfirmDeletePopup({ show: true, id: e.target.id });

        if (confirmDelete) {
            const reportId = parseInt(e.target.id);
            const newReports = reportData.filter(report => report.reportId !== reportId)
            setReportData(newReports);
            if (newReports.length < 1) {
                setHasReports(false)
            }
            const response = await fetch(environmentUrl + 'reports/deletereport', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ post: { email: email, id: accountId, reportId: reportId } }),
            });
            const deletedResponse = await response.json();

            if (deletedResponse === false) {
                alert("Error: Report not deleted.");
                window.location.reload();
            }
        }
        setConfirmDelete(false);
    }

    return (
        <>
            {showExpiredSubscription &&
                <ExpiredSubscriptionPopup setShowExpiredSubscription={setShowExpiredSubscription} />
            }
            {notAllowed &&
                <NotAllowedHerePopup setNotAllowed={(e) => setNotAllowed} />
            }
            {addingReport &&
                <AddReportPopup setAddingReport={setAddingReport} setReportData={setReportData} setHasReports={setHasReports} environmentUrl={environmentUrl} />
            }
            {editing &&
                <EditReportPopup reportData={reportData} setEditing={setEditing} selectedReportData={selectedReport} setReportData={setReportData} environmentUrl={environmentUrl} />
            }
            {showInformation &&
                <ReportInfoPopup showInformationPopup={setShowInformation} />
            }
            {showConfirmDeletePopup.show &&
                <ConfirmDeletePopup showConfirmDeletePopup={showConfirmDeletePopup} setShowConfirmDeletePopup={setShowConfirmDeletePopup} reportData={reportData} setReportData={setReportData} setHasReports={setHasReports} environmentUrl={environmentUrl} />
            }
            <Tabs window={window} selected={"reports"} environment={environment} userInfo={userInfo} />
            <div className='ReportsPage'>
                <h3 id="reportsTitle">MY REPORTS
                </h3>
                <div className="AddReportDiv">
                    <Button onClick={(e) => setShowInformation(true)} text="i" id="infoButton" className="CircularButtonWhite Grow" style={{ float: "left" }} />
                    <Button alt="Add Report" className={!hasReports ? 'Pulse' : null} id="reportAddButton" title="Add Report" onClick={(e) => setAddingReport(true)} text="+" />
                </div>
                {!hasReports ?
                    (
                        <div className='NoReportsToShow'>
                            <EmptyInbox />
                            <p>You have no saved reports. Please click '+' at the top right to create your first report.</p>
                        </div>
                    ) : (
                        < div className='ReportsToShow'>
                            <table className='ReportsTable'>
                                <tr id='reportsHeaders'>
                                    <th>Created/<br />Edited</th>
                                    <th>Report Name</th>
                                    {/* <th>Search Type</th> */}
                                    {/* <th>Search Property</th> */}
                                    <th>Search Terms</th>
                                    <th>Search Country</th>
                                    {/* <th>Search Category</th> */}
                                    <th>Report Period</th>
                                    {/* <th>Report Frequency</th> */}
                                    <th>Modify</th>
                                </tr>
                                {reportData.length > 0 && reportData.map(data => (
                                    <tr id='reportsRows'>
                                        <td>{data.reportCreatedEdited}</td>
                                        <td>{data.reportName}</td>
                                        {/* <td>{data.searchType}</td> */}
                                        {/* <td>{propertyFormatText(data.property)}</td> */}
                                        <td>{data.searchTerms}</td>
                                        <td>{data.searchCountry || 'Worldwide'}</td>
                                        {/* <td>{data.searchCategory === 0 ? 'All' : data.searchCategory}</td> */}
                                        <td>{data.searchPeriod.charAt(0) + data.searchPeriod.slice(1).replaceAll("_", " ").toLowerCase()}</td>
                                        {/* <td>{data.reportFrequency}</td> */}
                                        <td>
                                            <span style={{ cursor: "pointer" }} id={data.reportId} onClick={(e) => editReport(e)}>
                                                &#9998;
                                            </span>
                                            &nbsp;&nbsp;&nbsp;
                                            <span style={{ cursor: "pointer" }} id={data.reportId} onClick={(e) => deleteReport(e)}>
                                                &#128465;
                                            </span>
                                        </td>
                                    </tr>
                                ))}
                            </table>
                        </div>
                    )}
            </div>
            <Footer withExtras />
        </>
    )
}

export default ReportsPage;