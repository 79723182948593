import '../Css/ComponentCss/HeroImage.css';
import eyeImage from '../../images/SOSEyeImageFull.jpeg';

export const HeroImage = ({ createAccountClicked, loginClicked }) => {
    return (
        <div id="eyeImageDiv">
            <header><h1 id="heroTitle">The Search Trends Tool</h1></header>
            <img id="eyeImage" ALT="Share of searching eye logo" src={eyeImage} />
            <div id="signUpButtonsDiv">
                <button type="button" id="signupButton" onClick={(e) => createAccountClicked(e)}><b>FREE TRIAL</b></button>
                {/* <button type="button" class="Bck-Orange" id="signupButton"> <a href="mailto:frank@croftanalytics.com?subject=Book%20A%20Demo" id="bookDemoText"><b>BOOK A DEMO</b></a></button> */}
                <button type="button" class="Bck-Blue" id="signupButton" onClick={(e) => loginClicked(e)}><b>LOG IN</b></button>
            </div>
        </div>
    )
};