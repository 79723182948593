import '../../Css/Popup.css';

export const GenericPopup = ({ closeFunction, headerText, bodyText, context }) => {
    const handleCloseFunction = () => {
        if (context === "nonReturnedCountriesPopup") {
            closeFunction({ show: false, headerText: '', bodyText: '' })
            return;
        }
        closeFunction(false);
    }
    return (
        <div className="Popup">
            <div className="GenericPopupBox">
                <h2 id="PopupHeaderTextCenter">{headerText}</h2>
                <p className="Text-Center">{bodyText}</p>
                <button type="button" id="cancelOk" onClick={() => handleCloseFunction()}>close</button>
            </div>
        </div >
    )
};