import { Link } from 'react-router-dom';
import aboutIcon from '../../images/aboutIcon.png';

export const LoginForm = ({ loginFunc, loggingIn, logInError }) => (
  <div className="LoginFormDiv">
    <form className="LoginForm" onSubmit={(e) => {
      loginFunc(e)
    }}>
      <p id="loginFormText"></p>
      <div id="emailInputLine">
        <input
          id="emailUserInput"
          type="email"
          placeholder="  Email address"
        />
      </div>
      <br />
      <div id="passwordInputLine">
        <input
          id="passwordInput"
          type="password"
          placeholder="  Password"
        />
      </div>
      {logInError && (
        <div className='LogInErrorDiv'>
          <p id="LogInErrorText">Email or Password are incorrect</p>
        </div>
      )}
      <div id="centerSubmitDiv">
        <button id="logInButton" type="submit" disabled={loggingIn}>Log In</button>
      </div>
      <Link to='/forgotpassword' style={{ textDecoration: 'none' }} id="forgotPasswordLink">
        <p id="forgotPasswordButton">Forgotten Password?</p>
      </Link>
      <Link to='/createaccount' style={{ textDecoration: 'none' }} id="signUpLink">
        <p id="signUpButton">Create Account</p>
      </Link>
      <a href="https://www.croftanalytics.com/share-of-searching">
        <img src={aboutIcon} alt="Logo" id="aboutInfoIcon" />
      </a>
      <br />
      <br />
      <br />
    </form>
  </div>
)
