export const SavedSearchConfirmDelete = ({ savedSearchToDelete, showAllSavedSearchesFunc, setShowSavedSearchDelete, environment }) => {

  const confirmDelete = async () => {
    const response = await fetch(environment.url + 'deletesavedsearch', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ post: savedSearchToDelete }),
    });
    const savedSearches = await response.json();
    setShowSavedSearchDelete(false)
    showAllSavedSearchesFunc()
  }

  return (
    <div className="SavedSearchDeletePopup">
      <div id="savedSearchDeletePopupBox">
        <h3 id="savedSearchDeleteHeaderText"> Are you sure you want to delete? </h3>
        <h4 id="searchNameToDelete"></h4>
        <button id="confirmOk" onClick={(e) => {
          confirmDelete();
        }}>Yes</button>
        <button id="cancelSaveSearchDeleteConfirmPopup" onClick={(e) => setShowSavedSearchDelete(false)}>No</button>
      </div>
    </div>
  )
};
