import { Tabs } from "../Components/Tabs";
import { ExpiredSubscriptionPopup } from '../Components/Popups/ExpiredSubscriptionPopup';
import { NoPermissionPopup } from '../Components/Popups/NoPermissionPopup';
import { EditSearchListPopup } from '../Components/Popups/EditSearchListPopup';
import { SaveSearchPopup } from '../Components/Popups/SaveSearchPopup';
import { SavedSearchesPopup } from '../Components/Popups/SavedSearchesPopup';
import { SavedSearchConfirmDelete } from '../Components/Popups/SavedSearchConfirmDelete';
import SearchColumn from '../Components/SearchColumn';
import GraphColumn from '../Components/GraphColumn';
import { useState, useEffect, useRef } from 'react';
import $ from 'jquery';
import { Constants } from '../Components/Constants';
import React from "react";
import { Footer } from '../Components/Footer';
import '../Css/Search.css';
import '../Css/Tabs.css';
import { SubscribersOnly } from '../Components/Popups/SubscribersOnly';
import { createPowerpointDownloadButtons, editSearchesOk, handleMultiSubmit, resetSearchWords, saveSearchOk, savedSearchDeleteFunc, showAllSavedSearchesFunc, tabHandler, checkPageAllowed, getClientInfo, setStickyHeader } from "../Helpers/HelpersConstants";
import { IndexUnavailablePopup } from "../Components/Popups/IndexUnavailablePopup";

const SearchNew = ({ location }) => {
  const { environment, userInfo } = location.state || {};
  if (!userInfo) window.location.href = ("/login");

  $('head').append('<link rel="stylesheet" href="https://netdna.bootstrapcdn.com/font-awesome/4.0.3/css/font-awesome.min.css">');

  const [refactoredPost, setRefactoredPost] = useState({ searchTerms: [], property: "web", category: "All" });
  const [showEditList, setShowEditList] = useState(false);
  const [showSavedSearches, setShowSavedSearches] = useState(false);
  const [showSavedSearchDelete, setShowSavedSearchDelete] = useState(false);
  const [savedSearchesToRender, setSavedSearchesToRender] = useState([]);
  const [showSaveSearch, setShowSaveSearch] = useState(false);
  const [saveSearchSubmit, setSaveSearchSubmit] = useState(false);
  const [saveSearchNameInput, setSaveSearchNameInput] = useState('');
  const [savedSearchToDelete, setSavedSearchToDelete] = useState({});
  const [showNoPermission, setShowNoPermission] = useState(false);
  const [showSubscribersOnly, setShowSubscribersOnly] = useState(false);
  const [showExpiredSubscription, setShowExpiredSubscription] = useState(false);
  const [showIndexUnavailable, setShowIndexUnavailable] = useState(false);
  const [showIndexWarning, setShowIndexWarning] = useState(false);
  const [scrollDown, setScrollDown] = useState(false);
  const [isSubscriber, setIsSubscriber] = useState(false);
  const [numberOfRunSearches, setNumberOfRunSearches] = useState(0);
  const [responseToPostVolume, setResponseToPostVolume] = useState();
  const [dateArray, setDateArray] = useState([]);
  const [volumeArray, setVolumeArray] = useState([]);
  const [searchDataObj, setSearchDataObj] = useState({});
  const [showGraphText, setShowGraphText] = useState(true);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [selectedYearlyChartTerm, setSelectedYearlyChartTerm] = useState();
  const [selectedYearlyPercentChartTerm, setSelectedYearlyPercentChartTerm] = useState();
  const [chartDataObject, setChartDataObject] = useState();
  const chartRef = useRef()
  const areaChartRef = useRef()
  const barChartRef = useRef()
  const volumeChartRef = useRef()
  const yearlyChartRef = useRef()
  const yearlyPercentChartRef = useRef()
  const barSliderChartRef = useRef()
  const isInitialRender = useRef(true)
  const isInitialRender2 = useRef(true)
  const [chartsLoaded, setChartsLoaded] = useState(false);

  useEffect(() => {
    tabHandler(window.location.pathname);
    addToRefactoredPost('email', userInfo.email);
    addToRefactoredPost('accountId', userInfo.accountId);
    tabHandler(window.location.pathname);
    getClientInfo(addToRefactoredPost);
    var header = document.getElementById("navBar");
    var sticky = header.offsetTop;
    window.onscroll = async () => await setStickyHeader(header, sticky, setScrollDown);
    if (userInfo.IndexStatus === "OFF") setShowIndexUnavailable(true);
  }, []);

  useEffect(() => {
    createPowerpointDownloadButtons({
      refactoredPost,
      downloadContainerElement: document.getElementById("downloadContainer"),
      page: Constants.INDEX_TYPE,
      isSubscriber: userInfo.isSubscriber,
      setShowSubscribersOnly,
      selectedYearlyChartTerm,
      selectedYearlyPercentChartTerm
    }
    );
  }, [refactoredPost, selectedYearlyChartTerm, selectedYearlyPercentChartTerm, chartsLoaded])

  useEffect(async () => {
    if (saveSearchSubmit) {
      const response = await fetch(environment.url + 'setsavedsearch', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ post: refactoredPost }),
      });
      let responseBool = await response.json();
      setSaveSearchSubmit(false);
    }
  }, [saveSearchSubmit])

  useEffect(() => {
    if (numberOfRunSearches > 0) {
      handleMultiSubmit({
        environment,
        userInfo,
        numberOfRunSearches,
        setShowGraphText,
        refactoredPost,
        chartRef,
        barChartRef,
        volumeChartRef,
        areaChartRef,
        setSearchDataObj,
        setResponseToPostVolume,
        setChartsLoaded,
        setFormSubmitted,
        setNumberOfRunSearches,
        setChartDataObject,
        setDateArray,
        setVolumeArray,
        searchType: Constants.INDEX_TYPE
      });
    }
  }, [numberOfRunSearches]);


  const addToRefactoredPost = async (field, value) => {
    setRefactoredPost(prevState => ({ ...prevState, [field]: value }));
  }


  const requirementsObj = { countries: true, categories: true, statesRegions: true, startDate: true, endDate: true, properties: true, saveSearch: true, retrieveSavedSearches: true, wideNarrow: false, singleSearchTerm: false, minDate: '2004-01-01' }

  return (
    <div>
      {showIndexUnavailable && <IndexUnavailablePopup setShowIndexUnavailable={setShowIndexUnavailable} />}
      {showExpiredSubscription && <ExpiredSubscriptionPopup setShowExpiredSubscription={setShowExpiredSubscription} />}
      {showNoPermission && <NoPermissionPopup setShowNoPermission={setShowNoPermission} />}
      {showSubscribersOnly && <SubscribersOnly setShowSubscribersOnly={setShowSubscribersOnly} />}
      {showSavedSearchDelete && <SavedSearchConfirmDelete savedSearchToDelete={savedSearchToDelete} showAllSavedSearchesFunc={() => showAllSavedSearchesFunc({ environment, setShowSavedSearchDelete, setShowSavedSearches, savedSearchDeleteFunc: savedSearchDeleteFunc, setSavedSearchesToRender, addToRefactoredPost, resetSearchWords, setSavedSearchToDelete })} setShowSavedSearchDelete={setShowSavedSearchDelete} environment={environment} />}
      {showSaveSearch && <SaveSearchPopup setSaveSearchNameInput={setSaveSearchNameInput} setShowSaveSearch={setShowSaveSearch} saveSearchOk={saveSearchOk} addToRefactoredPost={addToRefactoredPost} setSaveSearchSubmit={setSaveSearchSubmit} />}
      {showEditList && (<EditSearchListPopup editSearchesOk={(e) => editSearchesOk(e)} />)}
      {showSavedSearches && <SavedSearchesPopup savedSearchesOk={setShowSavedSearches} showAllSavedSearchesFunc={showAllSavedSearchesFunc} savedSearchesProps={{ environment, setShowSavedSearchDelete, setShowSavedSearches, savedSearchDeleteFunc: savedSearchDeleteFunc, setSavedSearchesToRender, addToRefactoredPost, setSavedSearchToDelete }} savedSearchesToRender={savedSearchesToRender} />}

      <Tabs window={window} selected={"searchIndex"} environment={environment} userInfo={userInfo} />
      {(userInfo.IndexStatus !== "OFF" || userInfo.SubscriptionType === "ADMIN") && (
        <div class="SearchColumns">
          {userInfo && <SearchColumn refactoredPost={refactoredPost} setRefactoredPost={setRefactoredPost} environment={environment} requirementsObj={requirementsObj} isSubscriber={userInfo.isSubscriber} setShowSubscribersOnly={setShowSubscribersOnly} setShowSavedSearches={setShowSavedSearches} showAllSavedSearchesFunc={() => showAllSavedSearchesFunc({ environment, setShowSavedSearchDelete, setShowSavedSearches, savedSearchDeleteFunc: savedSearchDeleteFunc, setSavedSearchesToRender, addToRefactoredPost, resetSearchWords, setSavedSearchToDelete })} setShowEditList={setShowEditList} setShowSaveSearch={setShowSaveSearch} formSubmitted={formSubmitted} setFormSubmitted={setFormSubmitted} handleMultiSubmit={() => handleMultiSubmit({
            environment,
            userInfo,
            numberOfRunSearches,
            setShowGraphText,
            refactoredPost,
            chartRef,
            barChartRef,
            volumeChartRef,
            areaChartRef,
            setSearchDataObj,
            setResponseToPostVolume,
            setChartsLoaded,
            setFormSubmitted,
            setNumberOfRunSearches,
            setChartDataObject,
            setDateArray,
            setVolumeArray,
            searchType: Constants.INDEX_TYPE
          })} userInfo={userInfo} setShowSavedSearchDelete={setShowSavedSearchDelete} />}

          <GraphColumn chartRef={chartRef} areaChartRef={areaChartRef} barChartRef={barChartRef} volumeChartRef={volumeChartRef} yearlyChartRef={yearlyChartRef} yearlyPercentChartRef={yearlyPercentChartRef} showGraphText={showGraphText} page={Constants.INDEX_TYPE} barSliderChartRef={barSliderChartRef} chartDataObject={chartDataObject} searchDataObj={searchDataObj} downloadContainerElement={document.getElementById("downloadContainer")} isSubscriber={userInfo.isSubscriber} setShowSubscribersOnly={setShowSubscribersOnly} refactoredPost={refactoredPost} setSelectedYearlyChartTerm={setSelectedYearlyChartTerm} setSelectedYearlyPercentChartTerm={setSelectedYearlyPercentChartTerm} userInfo={userInfo} />
        </div>
      )}
      <Footer withExtras />
    </div>
  )
}

export default SearchNew;