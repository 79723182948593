import React from 'react';
import './Css/Home.css';
import $ from 'jquery';
import Login from './Login.js';
import HomePage from './Pages/HomePage.js';
import CreateAccount from './CreateAccount.js';
import ForgotPassword from './ForgotPassword.js'
import ResetPassword from './ResetPassword.js';
import VerifyEmail from './VerifyEmail.js'
import Nav from './Nav.js';
import About from './Pages/About.js';
import AboutLoginPage from './AboutLoginPage.js';
import Privacy from './Privacy.js';
import TermsConditions from './TermsConditions';
import ReportsPage from './Pages/ReportsPage.js';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import SearchNew from './Pages/SearchNew';
import SearchVolumeNew, { SearchVolumeNewWithRouter } from './Pages/SearchVolumeNew';
import SearchVolumePrem from './Pages/SearchVolumePrem.js';
import SimilarSearchesNew from './Pages/SimilarSearchesNew';
import AdminNew from './Pages/AdminNew.js';
import AccountPageNew from './Pages/AccountPageNew.js';
import PageSelector from './Pages/PageSelector';

function Home() {
  // $('link').remove();
  // $('head').append('<link rel="shortcut icon" type="image/x-icon" href=' + croftFav + '>');
  // $('head').append('<link rel="icon" type="image/x-icon" href=' + croftFav + '>');
  // $('head').append(`<link rel="apple-touch-icon" href="${croftFav}" />`);
  // $('head').append('<link rel="icon" href="https://img1.wsimg.com/isteam/ip/10ab9f80-7651-45a5-b821-244016eea05e/favicon/b3cd94bb-c30d-44e5-99fb-f60f1265e26c.png/:/rs=w:16,h:16,m" sizes="16x16"></link>');
  // $('head').append('<link rel = "icon" href = "https://img1.wsimg.com/isteam/ip/10ab9f80-7651-45a5-b821-244016eea05e/favicon/b3cd94bb-c30d-44e5-99fb-f60f1265e26c.png/:/rs=w:24,h:24,m" sizes = "24x24" ></link>');
  // $('head').append('<link rel="icon" href="https://img1.wsimg.com/isteam/ip/10ab9f80-7651-45a5-b821-244016eea05e/favicon/b3cd94bb-c30d-44e5-99fb-f60f1265e26c.png/:/rs=w:32,h:32,m" sizes="32x32"></link>');

  if ($('script').length <= 3) {
    $('head').append('<!-- Global site tag (gtag.js) - Google Analytics -->' +
      '<script async src=https://www.googletagmanager.com/gtag/js?id=G-E9V97092YM></script>' +
      '<script>' +
      '  window.dataLayer = window.dataLayer || [];' +
      'function gtag(){dataLayer.push(arguments);}' +
      'gtag("js", new Date());' +
      'gtag("config", "G-E9V97092YM");' +
      'gtag("config", "AW-1037309005");' +
      '</script>');
  }
  return (
    <Router>
      <div>
        <Router>
          <ScrollToTop />
          <Nav />
          <Switch>
            <Route path="/search" component={SearchNew} />
            <Route path="/selector" component={PageSelector} />
            <Route
              path="/similarsearch"
              render={(props) => <SimilarSearchesNew {...props} />}
            />
            <Route
              path="/volumesearch"
              render={(props) => <SearchVolumeNew {...props} />}
            />
            <Route path="/countrysearch" component={SearchVolumePrem} />
            <Route
              path="/myreports"
              render={(props) => <ReportsPage {...props} />}
            />
            <Route path="/resetpassword/:code" component={ResetPassword} />
            <Route path="/verifyemail/:code" component={VerifyEmail} />
            <Route path="/admin" component={AdminNew} />
            <Route path="/accountpage" component={AccountPageNew} />
            <Route path="/forgotpassword" component={ForgotPassword} />
            <Route path="/createaccount" component={CreateAccount} />
            <Route path="/about" component={About} />
            <Route path="/aboutloginpage" component={AboutLoginPage} />
            <Route path="/privacy" component={Privacy} />
            <Route path="/termsandconditions" component={TermsConditions} />
            <Route path="/login" component={Login} />
            <Route path="/" component={HomePage} /> {/* //always at bottom! */}
          </Switch>
        </Router>
      </div>
    </Router>

  );
}

export const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export default Home;
