export const Constants = {
    FREE_SUBSCRIPTION: "FREE",
    PAID_SUBSCRIPTION: "PAID",
    EXPIRED_SUBSCRIPTION: "FREE",
    ADMIN_SUBSCRIPTION: "ADMIN",
    VOLUME_TYPE: "VOLUME",
    VOLUME_PLUS_TYPE: "VOLUME_PLUS",
    INDEX_TYPE: 'INDEX',
    SIMILAR_TYPE: 'SIMILAR',
    YEARLY_TYPE: 'YEARLY',
    REPORTS_TYPE: 'REPORTS'
}

export const VOLUME_SEARCH_FAILED_TEXT = "Whoops, something went wrong. Please try again, possibly more than once. If this continues, please email frank@croftanalytics.com."
export const SEARCH_FAILED_TEXT = "Whoops, something went wrong. Please try again, possibly more than once. If this continues, please email frank@croftanalytics.com. Also consider using Volume Trends for your query."