import '../../Css/ComponentCss/ClickImagePopup.css';

export const ClickImagePopup = ({ state, updateParent, image, alt }) => {
    return (<div className="Popup" onClick={(e) =>
        updateParent()
    }>
        <div id="PopupBox">
            <div class="GraphPopupBox">
                <img id="phoneGraphPopup" src={image} alt={alt} /><br />
                <button class="PopupCancelButton Bck-Red" onClick={(e) =>
                    updateParent()
                }>Close</button>
            </div>
        </div>
    </div >
    )
};