import PropTypes from 'prop-types';

export const NoPermissionPopup = () => (
  <div className="NoPermissionPopup">
    <div id="noPermissionPopupBox">
      <p id="popupNoPermissionText">YOUR EMAIL HAS NOT BEEN VALIDATED</p>
      <p id="smallNoPermissionText">Please check your email account for a validation email. Or contact <a href="mailto:frank@croftanalytics.com">frank@croftanalytics.com</a> if you have questions.</p><br />
      <button id="cancelOk" onClick={(e) => {
        window.location.replace("/accountpage");
      }}>OK</button>
    </div>
  </div>
)
