import $ from 'jquery';

export const SaveSearchPopup = ({ setSaveSearchNameInput, setShowSaveSearch, saveSearchOk, addToRefactoredPost, setSaveSearchSubmit }) => {
  return (
    <div className="SaveSearchPopup">
      <div id="saveSearchBox">
        <p id="saveSearchBoxTitleText"><b>Save Search</b></p>
        <div id="saveSearchBoxText">
          <p id="saveSearchFields"></p>
        </div>
        <input
          id="saveSearchNameInput"
          type="text"
          defaultValue=""
          placeholder="Enter search name"
          onChange={(e) => setSaveSearchNameInput(e.target.value)}
        />
        <div id="saveSearchButtons">
          <button id="saveSearchOk" onClick={(e) => {
            if (!!$("#saveSearchNameInput").val()) {
              saveSearchOk({ saveSearchNameInput: $("#saveSearchNameInput").val(), addToRefactoredPost, setSaveSearchSubmit, setShowSaveSearch });
            } else {
              $("#saveSearchNameInput").addClass('SaveSearchNameInputRed');
            }
          }}>Save</button>
          <button id="saveSearchCancel" onClick={(e) => setShowSaveSearch(false)}>Cancel</button>
        </div>
      </div>
    </div>
  )
}
