import React, { useEffect, useState } from 'react';
import './Css/Login.css';
import logo from '../images/ShareOfSearching.png';
import { Footer } from "./Components/Footer.js";
import { LoginForm } from "./Components/LoginForm.js";
import HelperFunctions from './Helpers/HelperFunctions';
import { MaintenanceModePopup } from './Components/Popups/MaintenanceModePopup';


const Login = () => {

  var helperFunctions = new HelperFunctions;
  const [environmentUrl, setEnvironmentUrl] = useState('https://servershareofsearch.herokuapp.com/api/');
  const [maintenanceMode, setMaintenanceMode] = useState(false);
  const [loggingIn, setLoggingIn] = useState(false);
  const [logInError, setLogInError] = useState(false);

  useEffect(() => {
    const environment = helperFunctions.testEnvironment(window.location.hostname);
    setEnvironmentUrl(environment.url);
    sessionStorage.setItem('user', null);
    wipeFirstName(this);
    console.clear();
  }, [])

  const wipeFirstName = async e => {
    setTimeout(function () {
      document.getElementById("firstName").innerHTML = "";
    }, 800);
  }

  const login = async (e) => {
    e.preventDefault();
    setLoggingIn(true);
    setLogInError(false);
    if (maintenanceMode) return;
    var accountInfo = []
    var email = document.getElementById("emailUserInput").value;
    var password = document.getElementById("passwordInput").value;
    accountInfo.push(email);
    accountInfo.push(password);

    const response = await fetch(environmentUrl + 'checklogin', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ post: accountInfo }),
    }).catch((error) => {
      if (window.location.href === "http://localhost:3000/login" || window.location.href === "https://www.shareofsearching.com/login") {
        alert("This site is down for maintenance. We apologise for the inconvenience. Please check back soon.")
        window.location.replace("/");
      }
    });

    const allowedLoginBoolean = await response.json();

    if (allowedLoginBoolean === true) {
      sessionStorage.setItem('user', email);
      const responseAccount = await fetch(environmentUrl + 'getaccountinfobyemail', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ post: { email: email } }),
      });
      const allowedAccount = await responseAccount.json();
      sessionStorage.setItem('userName', allowedAccount.FirstName);
      sessionStorage.setItem('id', allowedAccount.Id * 500);
      setLoggingIn(false);
      window.location.replace("/selector");
    } else {
      setLoggingIn(false);
      setLogInError(true);
    }
  }


  return (
    <div className="Login">
      {maintenanceMode && <MaintenanceModePopup />}
      <div id="navLoginHider"></div>
      <a href="/">
        <img src={logo} alt="Logo" id="sosingImage" />
      </a>
      <LoginForm loginFunc={login} loggingIn={loggingIn} logInError={logInError} />
      <Footer />
    </div>
  );

}

export default Login;
