import { Chart } from "chart.js"
import { useEffect } from "react"
import HelperFunctions from "../../Helpers/HelperFunctions";
import "../../Css/GraphsCss/SliderBarChart.css";
import { useState } from "react";
import $ from 'jquery';
import React from "react";
import { getChartSubTitleString } from "../../Helpers/HelpersConstants";

const SliderBarChart = ({ chartDataObject, barSliderChartRef, refactoredPost }) => {
    const [isVolumePlus, setIsVolumePlus] = useState(false);

    const [years, setYears] = useState({})
    const [volumeArrayPercentages, setVolumeArrayPercentages] = useState([])
    const [nameArray, setNameArray] = useState([])
    const [property, setProperty] = useState("")
    const [percentageBiggestInt, setPercentageBiggestInt] = useState(0);

    const helperFunctions = new HelperFunctions();

    useEffect(() => {
        if (!chartDataObject) return null;
        if (chartDataObject?.countriesArray) {
            setIsVolumePlus(true);
        }
        updateYearsState();
    }, [chartDataObject])
    useEffect(() => {
        if (!chartDataObject) return null;
        if (Object.keys(years).length > 1) {
            $("#myBarSlider").val(999);
            updateSliderChart()
        }
    }, [years])

    if (!chartDataObject) return null;

    const updateYearsState = () => {
        const volumeDateMap = {};

        setYears({})
        setNameArray(chartDataObject.nameArray);
        var volumeArray = chartDataObject.volumeArray;
        const dateArray = chartDataObject.dateArray;
        setProperty(chartDataObject.property === "youtube" ? "YouTube" : "Google");

        volumeArray = volumeArray.map(item => item.split(",").map(i => parseInt(i)));

        // Calculate the volumeArrayPercentages
        const updatedVolumeArrayPercentages = volumeArray.reduce((acc, item) => {
            item.forEach((num, index) => {
                if (acc[index]) {
                    acc[index] += num;
                } else {
                    acc[index] = num;
                }
            });
            return acc;
        }, []);

        const maxVolumeArrayPercentages = updatedVolumeArrayPercentages.reduce((a, b) => a + b);

        // Update the volumeArrayPercentages state
        const updatedPercentages = updatedVolumeArrayPercentages.map(num => {
            return (num / maxVolumeArrayPercentages * 100).toFixed(2);
        });

        setVolumeArrayPercentages(updatedPercentages);

        for (let index = 0; index < dateArray.length; index++) {
            const date = dateArray[index];
            const volumeString = volumeArray[index];
            volumeDateMap[date] = volumeString;
        };

        const tempYears = {};
        Object.entries(volumeDateMap).forEach(([key, value]) => {
            if (Object.keys(tempYears).includes(key.substring(key.length - 4))) {
                value.forEach((val, index) => {
                    const parsedValue = parseInt(val);
                    if (tempYears[key.substring(key.length - 4)][index]) {
                        tempYears[key.substring(key.length - 4)][index] += parsedValue;
                    } else {
                        tempYears[key.substring(key.length - 4)][index] = parsedValue;
                    }
                });
            } else {
                tempYears[key.substring(key.length - 4)] = Array(value.length).fill(0);
            }
        });
        //Years currently as numerical values

        var biggestInt = 0;
        Object.entries(tempYears).forEach(([key, value]) => {
            const max = value.reduce((a, b) => a + b);
            tempYears[key] = value.map(num => {
                const percentage = Math.ceil(num / max * 100);
                if (biggestInt < percentage) biggestInt = percentage;
                return ((num / max * 100).toFixed(2))
            });
        });
        setPercentageBiggestInt(biggestInt);
        setYears(prevState => {
            const updatedYears = { ...prevState, ...tempYears };
            return updatedYears;
        });
        //Years now as percentages
    }


    const updateSliderChart = async (value) => {

        $('#myBarSliderChart')?.remove(); //.remove() this is my <canvas> element

        var chartSubTitlesElement = document.querySelector('#chartSubTitlesSlider');
        if (chartSubTitlesElement) {
            chartSubTitlesElement.insertAdjacentHTML('afterend', `<canvas id="myBarSliderChart" ref=${barSliderChartRef} />`);
        }


        if (Object.keys(years).length <= 1) return null;
        var concludingChart = new Chart(document.getElementById('myBarSliderChart'), {
            type: 'bar',
            data: {
                labels: nameArray,
                datasets: [{
                    data: Object.values(years)[value] ? Object.values(years)[value] : volumeArrayPercentages,
                    backgroundColor: helperFunctions.coloursArray(),
                    borderColor: helperFunctions.coloursArray(),
                    borderWidth: 1
                }]
            },
            options: {
                animation: {
                    duration: 0
                },
                scales: {
                    xAxes: [{
                        ticks: {
                            fontSize: 20
                        }
                    }],
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                            max: (percentageBiggestInt === 0 ? 100 : percentageBiggestInt + 3),
                        },
                        scaleLabel: {
                            display: true,
                            labelString: `Total % Share Of Search`,
                            fontSize: 20
                        },
                    }]
                },
                legend: {
                    display: false,
                    labels: {
                        // This more specific font property overrides the global property
                        display: false,
                        fontSize: 0,
                    }
                },
            }
        });

        concludingChart.options.animation.onComplete = function () {
            var ctx = this.chart.ctx;
            // ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily);
            ctx.font = Chart.helpers.fontString(16, "bold", Chart.defaults.global.defaultFontFamily);
            ctx.textAlign = "center";
            ctx.textBaseline = "bottom";
            concludingChart.data.datasets.forEach(function (dataset, i) {
                var meta = concludingChart.controller.getDatasetMeta(i);
                meta.data.forEach(function (bar, index) {
                    var data = parseFloat(dataset.data[index]).toFixed(1) + "%";
                    ctx.fillText(data, bar._model.x, bar._model.y - 5);
                });
            })
        }
        if (concludingChart.width < 100) {
            concludingChart.width = 700;
        }
        concludingChart.update();
    }

    const handleSliderChange = async e => {
        updateSliderChart(parseInt(e.target.value) - 1);
    }

    const headerHoverText = () => {
        return (
            <div id="graphInfoIText5">
                This chart shows the % share of search for each search term in each year. Click on the blue bar at the bottom to view a year. You can also click and hold the blue dot and scroll left (or right) to view share of search changes across the years.
            </div>);
    }


    return (
        <div className="">
            {Object.keys(years).length <= 1 ? null :
                <div id="myBarSliderChartDiv">
                    <p class="ChartTitles ChartTitle5">{property} Total % Share of Search over the Years</p>
                    {headerHoverText()}
                    <p class="chartSubTitles" id="chartSubTitlesSlider">{getChartSubTitleString({ refactoredPost })}</p>
                    <canvas
                        id="myBarSliderChart"
                        ref={barSliderChartRef}
                    />
                    <div id="myBarSliderDiv">
                        <input id="myBarSlider" type='range' min='1' max={Object.keys(years).length + 1} step='1' onChange={(e) => handleSliderChange(e)} list="sliderTicks" />
                        <datalist id="sliderTicks">
                            {Object.keys(years).map(year => (
                                <p id="datalistOption" label={year} value={year}>{year}</p>
                            ))}
                            <p id="datalistOption" label="All" value="All">All</p>
                        </datalist>
                    </div>
                </div>
            }
        </div>
    )
}

export default SliderBarChart;