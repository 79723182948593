class CompareDates {

  static compare_dates(date1, date2) {
    if (date1 > date2) return ("Now > Date2");
    else if (date1 < date2) return ("Date2 > Now");
    else return ("Now = Date2");
  }

}

export default CompareDates;
