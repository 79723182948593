export const NewFeatureHomepage = ({ show }) => {
  if (show) {
    return (
      <div id="newFeatureDiv">
        <p id="newFeatureText">
          * NEW! Create your own reports to be sent to you via email every week or month *
        </p>
      </div >
    )
  }
  return null;
}
