import reportsImage from '../../../images/reportsInfo.png';

export const ReportInfoPopup = ({ showInformationPopup }) => (
  <div className="Popup">
    <div className="PopupBox" id="reportsPopupBox">
      <p id="PopupHeaderText"><b>My Reports Information</b></p>
      <div className="PopupBodyDiv" id="reportsPopupBody">
        <img src={reportsImage} id="reportsInfoImg" />
        <ul>
          <li id="reportInfoBullet">Scroll previously saved searches and click to fill search terms, country and category.</li>
          <li id="reportInfoBullet">Reports are sent on the 14th of each month.</li>
          <li id="reportInfoBullet">Enter the name for your report and select ‘Save’.</li>
        </ul>
      </div>
      <p id="reportQuestionsText">
        If you have any questions or suggestions for improvement, please email Frank Harrison at <a href="mailto:frank@croftanalytics.com">frank@croftanalytics.com</a>
      </p>
      <button id="cancelOk" onClick={(e) => showInformationPopup(false)}>OK</button>
    </div>
  </div>
)
