export const EmailVerificationPopup = ({ setShowEmailVerificationPopup }) => {
    return (
        <div className="Popup">
            <div className="PopupBox">
                <h2 id="PopupHeaderTextCenter">FINAL STEP TO CREATE YOUR ACCOUNT</h2>
                <p className="PopupBodyText">An email has been sent to you - open the link in the email to verify your account.<br /></p>
                <p className="PopupBodyText">If you cannot find the email, please check your junk/spam inbox or contact frank@croftanalytics.com</p>
                <button type="button" id="okButton" onClick={(e) => {
                    setShowEmailVerificationPopup(false);
                    window.location.replace("/login");
                }}>LOG IN</button>
            </div>
        </div>
    );
}