import '../Css/ComponentCss/Testimonials.css';
import { useEffect } from 'react';

const Testimonials = () => {

    let slideIndex = 1;
    useEffect(() => {
        showSlides(slideIndex);
    }, []);

    var testimonials = [
        {
            text: "We’ve been using the Share of Searching tool for a couple of years now and it has fed many of our strategies, pitches, and client arguments when it comes to how we should approach our media strategy not only in terms of digital but offline as well.The very intuitive and friendly interface, the fact that we can use a lot more queries to compare versus Google Trends, the clear overview of seasonality and the new addition of the YouTube search feature, makes it one of our go - to platforms for research, analysis, and insights.Great work!",
            company: "EssenceMediacom",
            reviewer: "Andrea Andrus",
            reviewerTitle: "Digital Strategy & New Business Romania"
        },
        {
            text: "Share of Searching is a useful tool that allows us to know more about categories or industries.Once the connection between Share Of Search and Share Of Market is established, you could use SOS as a proxy(SOM) to recommend a budget.Since SOM is a metric that takes time to have.",
            company: "Publicis Groupe",
            reviewer: "Carolina Garcia",
            reviewerTitle: "Senior Data Analyst"
        },
        {
            text: "Share of Searching is a fantastic tool that has enabled me to mature the organic search capability for the organisation. It has helped me to discover trends with competitors so we could apply some of this logic to predict what could happen with our brands, post merger. It has been instrumental in measuring brand awareness and organic share of search in our category. I’ve been very impressed with how receptive and responsive the team at SOS are to feedback and they’ve always been able to resolve any issues in a timely manner.",
            company: "Australian Retirement Trust",
            reviewer: "Mona Doncevska",
            reviewerTitle: "Digital Growth Marketing Manager – Organic Search"
        },
        {
            text: "Share of search is a valuable tool for Tempur to evaluate our brand health and make informed decisions about marketing strategies and investments. By tracking our share of search over time, we can get a sense of how our brand is performing relative to our competitors and whether we are gaining or losing mindshare among our audience. We use the Croft Analytics tools to make crucial decisions to continue our media spend or pull back if it does not have the desired effect.",
            company: "Tempur Sealy International",
            reviewer: "Ben Wilkes",
            reviewerTitle: "International Marketing Manager"
        },
        {
            text: "I use Share of search for market share, product launches, competitor and trend analysis and much more. It has helped me tremendously in taking data driven decisions.",
            company: "Vitamail",
            reviewer: "Daniel Sandvik",
            reviewerTitle: "CCO"
        },
        {
            text: "Share of Searching enables us to accelerate time consuming calculations for brand marketing.Share of Searching is not just an important tool, but a valuable witness of our ups and downs.",
            company: "TK Services International",
            reviewer: "Mykhailo Ivonin",
            reviewerTitle: "Head of Brand"
        },
        {
            text: "We use Share of Search as one of our key KPIs as there’s more to gaining customer share of mind and consideration than just ad share of voice, the strong relationship to market share in many cases demonstrates this.",
            company: "Inchcape Australia",
            reviewer: "Jonathon Quirk",
            reviewerTitle: "Group Consumer Planning & Insights"
        },
    ].sort(() => Math.random() - 0.5);

    testimonials = testimonials.reduce(([a, b]) =>
        (b.push(...a.splice(Math.random() * a.length | 0, 1)), [a, b]), [[...testimonials], []])[1];

    // Next/previous controls
    function plusSlides(n) {
        showSlides(slideIndex += n);
    }

    // Thumbnail image controls
    function currentSlide(n) {
        showSlides(slideIndex = n);
    }

    function showSlides(n) {
        let i;
        let slides = document.getElementsByClassName("Testimonial");
        let dots = document.getElementsByClassName("dot");
        if (n > slides.length) { slideIndex = 1 }
        if (n < 1) { slideIndex = slides.length }
        for (i = 0; i < slides.length; i++) {
            slides[i].style.display = "none";
        }
        for (i = 0; i < dots.length; i++) {
            dots[i].className = dots[i].className.replace(" active", "");
        }
        slides[slideIndex - 1].style.display = "block";
        dots[slideIndex - 1].className += " active";
    }



    return (
        <>
            <div className="TestimonialsDiv">
                <div className="TestimonialsTextDiv" onClick={() => plusSlides(1)}>
                    {testimonials.map((review) => {
                        return (
                            <div className="Testimonial Fade">
                                <p id="testimonialReviewer">
                                    <span id="reviewerSpan">{review.reviewer},&nbsp;</span>
                                    <span id="reviewerSpan">{review.reviewerTitle},&nbsp;</span>
                                    <span id="reviewerSpan">{review.company}</span></p>
                                <p id="testimonialText">“{review.text}”</p>
                            </div>
                        )
                    })}
                </div>
            </div >

            <div style={{ textAlign: "center" }}>
                {testimonials.map((item, i) => <span class="dot" onClick={() => currentSlide(i + 1)}></span>)}
            </div>

            <div class="HP-Border-Break" />
        </>
    )
}

export default Testimonials;
