import { useState, useEffect } from 'react';
import { Button } from './Button';
import $ from 'jquery';
import 'jquery-ui/ui/widgets/sortable';
import 'jquery-ui/ui/widgets/draggable';
import 'jquery-ui/ui/widgets/droppable';
import 'bootstrap';
import CountriesSelect from './CountriesSelect';
import { autoDateChanged, getStartDateStartValue } from '../Helpers/HelpersConstants';
import 'selectize/dist/css/selectize.css';
import 'selectize/dist/css/selectize.default.css';
import 'selectize';
import CategorySelect from './CategorySelect';

const SearchColumn = ({ refactoredPost, setRefactoredPost, environment, requirementsObj, isSubscriber, setShowSubscribersOnly, setShowEditList, setShowSavedSearches, showAllSavedSearchesFunc, setShowSaveSearch, formSubmitted, setFormSubmitted, handleMultiSubmit, userInfo, setSavedSearchToDelete }) => {
    const endDateMonthLoadValue = `${new Date().getMonth() + 1}`.length === 1 ? `0${new Date().getMonth() + 1}` : `${new Date().getMonth() + 1}`
    const endDateDayLoadValue = `${new Date().getDate()}`.length === 1 ? `0${new Date().getDate()}` : `${new Date().getDate()}`
    const [currentInputText, setCurrentInputText] = useState();
    const isIndexPage = (window.location.pathname === "/search")
    const isSimilarPage = (window.location.pathname === "/similarsearch")
    const isVolumePlusPage = (window.location.pathname === "/countrysearch")

    useEffect(() => {
        const fetchData = async () => {
            if (formSubmitted) {
                await handleMultiSubmit();
            }

            $(document).ready(function () {
                $('#multiSubmitInput').selectize({
                    create: true,
                    persist: false,
                    plugins: ["drag_drop", "remove_button"],
                    placeholder: isSimilarPage ? "Please enter a search term" : "Please enter search terms",
                    maxItems: requirementsObj.singleSearchTerm ? 1 : isIndexPage ? 30 : 50,
                    onChange: function (valueIsArray) {
                        setCurrentInputText(valueIsArray)
                    }
                });
            });
            setTimeout(() => {
                $('#multiSubmitInput')[0]?.selectize?.clear();
                if (refactoredPost.searchTerms.length > 0) {
                    refactoredPost.searchTerms.forEach(term => {
                        $('#multiSubmitInput')[0].selectize.addOption({ value: term, text: term });
                        $('#multiSubmitInput')[0].selectize.addItem(term)
                    })
                } else if (currentInputText) {
                    currentInputText.forEach(term => {
                        $('#multiSubmitInput')[0].selectize.addOption({ value: term, text: term });
                        $('#multiSubmitInput')[0].selectize.addItem(term)
                    })
                }
            }, 200);
        };

        fetchData();
    }, [formSubmitted, refactoredPost]);


    const addToRefactoredPost = (field, value) => {
        setRefactoredPost(prevState => ({ ...prevState, [field]: value }));
    }

    const startDateMax = () => {
        if (!isIndexPage) {
            var startDateMaxYear = new Date().getFullYear()
            var startDateMaxMonth = (endDateMonthLoadValue - 2 === 0) ? 12 : endDateMonthLoadValue - 2
            if (startDateMaxMonth === 12) startDateMaxYear = startDateMaxYear - 1;
            startDateMaxMonth = `${startDateMaxMonth}`.length === 1 ? `0${startDateMaxMonth}` : startDateMaxMonth
            return `${startDateMaxYear}-${startDateMaxMonth}-${endDateDayLoadValue}`
        }
        return `${startDateMaxYear}-${endDateMonthLoadValue}-${endDateDayLoadValue}`
    }

    const checkSearchTermsInput = (e) => {
        e.preventDefault();
        const terms = currentInputText;

        if (requirementsObj.singleSearchTerm) {
            addToRefactoredPost('searchTerms', terms);
            $("#multiSubmitInput").val("");
            return;
        }
        if (terms.filter((term) => term.length > 104).length > 0) {
            window.alert("One or more of your search terms is too long, please reduce.")
        } else {
            const rPST = refactoredPost.searchTerms.map(item => item.trim());
            addToRefactoredPost('searchTerms', [...new Set(rPST.concat(terms))])
            $("#multiSubmitInput").val("");
        }
    }

    const checkDate = date => {
        if (new Date(date) > new Date()) {
            return (`${new Date().getFullYear()}-${endDateMonthLoadValue}-${new Date().getDate()}`)
        } else if (new Date(date) < new Date(requirementsObj.minDate)) {
            return (requirementsObj.minDate)
        } else {
            return date;
        }
    }

    const submitHandler = async (e) => {
        e.preventDefault();
        if (refactoredPost.searchTerms.length === 0 && (!currentInputText || currentInputText === "") && $("#multiSubmitInput").val() === "") {
            alert("Please enter at least one search term.")
            return;
        }
        if (!currentInputText) {
            alert("Please enter at least one search term.")
            return;
        }
        if (isVolumePlusPage && refactoredPost.countries.length < 2) {
            alert("Please enter at least two search countries.")
            return;
        }
        if (currentInputText.length > 30) {
            alert(`Please reduce the number of search terms to below 31. Current: ${refactoredPost.searchTerms.length ? refactoredPost.searchTerms.length : `${currentInputText.length}`}`)
            return;
        }
        setFormSubmitted(true);
        await setCurrentFormInputsToState(e)
    }


    const setCurrentFormInputsToState = async (e) => {
        e.preventDefault();
        if (requirementsObj.singleSearchTerm) {
            var searchTerms = $("#multiSubmitInput").val();
        } else {
            var searchTerms = $("#searchingFor").text() !== '' ? $("#searchingFor").text().split(",").filter(item => item).map(term => term.trim()) : $("#multiSubmitInput").val().filter(item => item).map(term => term.trim());
        }

        var property = document.getElementById('youtubeProperty')?.checked ? 'youtube' : 'web';
        var narrowSearch = document.getElementById('searchTypeTickboxNarrow')?.checked;
        var startDate = checkDate($("#startDate").val());
        var endDate = checkDate($("#endDate").val());
        var email = userInfo.email
        var accountId = userInfo.accountId

        const country = refactoredPost.country || "Worldwide";
        const countries = refactoredPost.countries || [];
        const countryShorthand = refactoredPost.countryShorthand || "";
        const state = refactoredPost.state || "";
        const stateShorthand = refactoredPost.stateShorthand || "";
        const region = refactoredPost.region || "";
        const regionShorthand = refactoredPost.regionShorthand || "";
        const category = refactoredPost.category || "All";
        const categoryId = refactoredPost.categoryId || 0;

        setRefactoredPost(prevState => ({ ...prevState, searchTerms, countries, country, countryShorthand, category, categoryId, state, stateShorthand, region, regionShorthand, startDate, endDate, property, email, accountId, narrowSearch }))

        return ({
            searchTerms, countries, country, countryShorthand, category, categoryId, state, stateShorthand, region, regionShorthand, startDate, endDate, property
        })
    }

    const submitSaveSearch = (e) => {
        if (!isSubscriber) {
            setShowSubscribersOnly(true);
            return;
        }
        $("#saveSearchFields").text("");
        setCurrentFormInputsToState(e).then((e) => {
            e.countries = e.countries.map(country => country.country)
            if (e.searchTerms.length === 0) { } else {
                setShowSaveSearch(true);
                const gORy = e.property === "web" ? "Google" : "YouTube";
                $("#saveSearchFields").append(
                    (e.countries.length > 0 ?
                        "<p><b>Country:</b> " + (e.countries) + " "
                        :
                        "<p><b>Country:</b> " + (e.country) + " ") +
                    (e.state !== "Not selected" ? ("<b>State:</b> " + e.state + " <br />") : "") +
                    (e.region !== "Not selected" ? ("<b>Region:</b> " + e.region + " <br />") : "") +
                    "</p>" +
                    (e.category !== "Not selected" ? ("<p><b>Category:</b> " + e.category + " <br /></p>") : "") +
                    "<p style='white-space: break-spaces;'><b>Start Date:</b> " + e.startDate + "   " +
                    "<b>End Date:</b> " + e.endDate + " <br /></p>" +
                    "<p style='white-space: break-spaces;'><b>Search Property:</b> " + gORy + "</p>" +
                    "<p><b>Search List:</b> " + e.searchTerms.join(",") + " </p>");
            }
        });
    }

    const clearMultiSubmitInput = () => {
        const selectizeInstance = $('#multiSubmitInput').data('selectize');
        // Clear the selected items
        selectizeInstance.clear();
    }

    return (
        <div class="SearchColumn">
            <div class="SearchColumnPadding">
                <h1 className="Search-Col-Margins" id="searchPageTitle">Select Search Criteria</h1>

                {requirementsObj.retrieveSavedSearches && (
                    <Button
                        text='Retrieve Searches'
                        onClick={() => {
                            if (!isSubscriber) {
                                setShowSubscribersOnly(true)
                            } else {
                                setShowSavedSearches(true);
                                showAllSavedSearchesFunc();
                            }
                        }}
                        id='retrieveSearchesSubmitButton'
                    />
                )}

                <CountriesSelect environment={environment} addToRefactoredPost={addToRefactoredPost} refactoredPost={refactoredPost} />

                {requirementsObj.categories && (

                    <CategorySelect environment={environment} addToRefactoredPost={addToRefactoredPost} refactoredPost={refactoredPost} />

                )}

                <div class="DateRange">
                    {requirementsObj.startDate && (
                        <div class="DateDiv Width-35per">
                            <label for="startDate" id="startDateLabel">Start date:</label>
                            <input
                                type="date"
                                id="startDate"
                                name="start-date"
                                value={refactoredPost.startDate || getStartDateStartValue({ isIndexPage }) || '2004-01-01'}
                                min={requirementsObj.minDate || "2004-01-01"}
                                max={startDateMax()}
                                onChange={(e) => {
                                    addToRefactoredPost('startDate', e.target.value)
                                }}
                            />
                        </div>
                    )}
                    {requirementsObj.endDate && (
                        <>
                            <div class="DateDiv Width-25per">
                                <label for="autoDate" id="autoDateLabel"></label>
                                <select
                                    id="autoDate"
                                    name="autoDate"
                                    onChange={(e) => {
                                        autoDateChanged(e.target.value, addToRefactoredPost);
                                    }}
                                >
                                    <option value="0">Custom</option>
                                    <option value="3">Past 3 months</option>
                                    <option value="6">Past 6 months</option>
                                    <option value="12">Past year</option>
                                    <option value="36">Past 3 years</option>
                                </select>
                            </div>
                            <div class="DateDiv Width-35per">
                                <label for="endDate" id="endDateLabel">End date:</label>
                                <input
                                    type="date"
                                    id="endDate"
                                    name="end-date"
                                    value={refactoredPost.endDate || `${new Date().getFullYear()}-${endDateMonthLoadValue}-${endDateDayLoadValue}`}
                                    min={requirementsObj.minDate || "2004-01-01"}
                                    max={`${new Date().getFullYear()}-${endDateMonthLoadValue}-${endDateDayLoadValue}`}
                                    onChange={(e) => {
                                        addToRefactoredPost('endDate', e.target.value)
                                    }}
                                />
                            </div>
                        </>
                    )}
                </div>

                {requirementsObj.wideNarrow && (
                    <div className="Search-Col-Margins" id="searchTypeTickboxDiv">
                        <input type="radio" id="searchTypeTickboxNarrow" name="searchTypeTickboxNarrow" checked={refactoredPost.narrowSearch} onChange={(e) => {
                            addToRefactoredPost('narrowSearch', true)
                        }} />
                        <label id="radioLabels" for="searchTypeTickboxNarrow">Narrow</label><br />
                        <input type="radio" id="searchTypeTickboxWide" name="searchTypeTickboxWide" checked={!refactoredPost.narrowSearch} onChange={(e) => {
                            addToRefactoredPost('narrowSearch', false)
                        }} />
                        <label id="radioLabels" for="searchTypeTickboxWide">Wide</label><br />
                    </div>
                )}

                {requirementsObj.properties && (
                    <div class="PropertyDiv Search-Col-Margins">
                        <input type="radio" id="webProperty" name="propertyType" value="web" checked={refactoredPost.property === "web"} onChange={(e) => {
                            addToRefactoredPost('property', e.target.value)
                        }} />
                        <label id="radioLabels" for="webProperty">Google</label><br />
                        <input type="radio" id="youtubeProperty" name="propertyType" value="youtube" checked={refactoredPost.property === "youtube"} onChange={(e) => {
                            if (new Date(refactoredPost.startDate) < new Date('2008-01-01') || !refactoredPost.startDate) {
                                addToRefactoredPost('startDate', '2008-01-01')
                            }
                            addToRefactoredPost('property', e.target.value)
                        }} />
                        <label id="radioLabels" for="youtubeProperty">YouTube</label><br />
                    </div>
                )}

                <form className="Search-Col-Margins" onSubmit={(e) => checkSearchTermsInput(e)}>
                    <p id="searchListText">{requirementsObj.singleSearchTerm ? 'Single Search Term' : 'Search List'}</p><br />
                    <div id="searchInputRow">
                        <select id="multiSubmitInput" multiple></select>
                        <button type="button" className={isSimilarPage && "ClearMultiSubmitInputSimilar"} id="clearMultiSubmitInput" onClick={() => clearMultiSubmitInput()}>X</button>
                    </div>
                </form>

                {requirementsObj.saveSearch && (
                    <button className="Search-Col-Margins" type="button" id="saveSearchSubmitButton" onClick={(e) => {
                        submitSaveSearch(e)
                    }}>Save Search</button>
                )}

                <button disabled={formSubmitted} className="Search-Col-Margins" type="button" id="criteriaSubmitButton" onClick={(e) => submitHandler(e)}>SUBMIT</button>
            </div >
            <p id="sourceText"><i>Source of search data: Google</i></p>
        </div >
    )
}

export default SearchColumn;
