import '../Css/ComponentCss/Pricing.css';

const Pricing = ({ createAccountClicked, loginClicked }) => {
    return (
        <div id="pricingDiv">
            <h1 id="pricingTitle">PRICING</h1>
            <div id="pricingButtonsDiv">
                <div id="priceButtonTextDiv" onClick={(e) => createAccountClicked(e)}>
                    <button type="button" id="signupPricingButton">
                        <b id="pricingButtonTitle">Free Trial</b>
                    </button>
                    <div className="PricingSubDiv">
                        <p id="pricingText">Click to sign in and start using Share of Searching without any obligation (no credit card required) for two weeks.<br />&nbsp;</p>
                        <ul id="pricingList">
                            <li id="pricingBullet">	&#9758; &nbsp; 14 days</li>
                            <li id="pricingBullet">	&#9758; &nbsp; Volume Trends (up to 10 queries daily)*</li>
                            <li id="pricingBullet">	&#9758; &nbsp; Country Trends (up to 5 queries daily)*</li>
                            <li id="pricingBullet">	&#9758; &nbsp; Similar Terms (up to 10 queries daily)*</li>
                            <li id="pricingBullet">	&#9758; &nbsp; Index Trends</li>
                            <li id="pricingBullet">	&#9758; &nbsp; No card details required</li>
                        </ul>
                    </div>
                </div>
                <div id="priceButtonTextDiv" onClick={(e) => loginClicked(e)}>
                    <button type="button" id="signupPricingButton2">
                        <b id="pricingButtonTitle">Subscribe</b>
                    </button>
                    <div className="PricingSubDiv">
                        <p id="pricingText">At any time during your free trial (or afterwards), sign in and select My Account for the payment link to use Share of Searching for one year for £500.</p>
                        <ul id="pricingList">
                            <li id="pricingBullet">	&#9758; &nbsp; One year</li>
                            <li id="pricingBullet">	&#9758; &nbsp; Volume Trends (up to 40 queries daily)*</li>
                            <li id="pricingBullet">	&#9758; &nbsp; Country Trends (up to 5 queries daily)*</li>
                            <li id="pricingBullet">	&#9758; &nbsp; Similar Terms (up to 40 queries daily)*</li>
                            <li id="pricingBullet">	&#9758; &nbsp; Index Trends</li>
                            <li id="pricingBullet">	&#9758; &nbsp; Export charts to PowerPoint and data to CSV/Excel</li>
                            <li id="pricingBullet">	&#9758; &nbsp; Save & retrieve search criteria</li>
                            <li id="pricingBullet">	&#9758; &nbsp; Create and schedule monthly reports</li>
                        </ul>
                    </div>
                </div>
            </div>
            <p id="forAdditionalDailyUses"><i>* Additional queries available, please get in touch.</i></p>
        </div>
    )
}

export default Pricing;