import React, { useEffect, useRef } from 'react';
import '../Css/ComponentCss/CountriesSelect.css';
import $ from 'jquery';
import 'selectize/dist/css/selectize.css';
import 'selectize/dist/css/selectize.default.css';
import 'selectize';
import { useLayoutEffect, useState } from 'react';

const CountriesSelect = ({ environment, addToRefactoredPost, refactoredPost }) => {
    const selectRef = useRef(null);
    const isVolumeSearchPrem = window.location.pathname === '/countrysearch';
    const isSimilarPage = window.location.pathname === '/similarsearch';
    const selectizeInstanceRef = useRef(null);
    const [currentCountries, setCurrentCountries] = useState({ country: '', countryShorthand: '' });


    useEffect(() => {
        const fetchDataAndInitializeSelect2 = async () => {
            const countries = await getCountries(); // Wait for countries to be fetched

            selectizeInstanceRef.current = $('#select-tools').selectize({
                plugins: ["remove_button"],
                maxItems: isVolumeSearchPrem ? 5 : 1,
                valueField: 'id',
                labelField: 'title',
                searchField: 'title',
                create: false,
                onChange: function (values) {
                    // Get the selected options
                    const selectedOptions = values.map(value => {
                        let option;
                        if (isVolumeSearchPrem) {
                            option = $(selectRef.current).siblings('.selectize-control').find('.item[data-value="' + value + '"]');
                        } else {
                            option = $(selectRef.current).siblings('.selectize-control').find('.option[data-value="' + value + '"]');
                        }
                        let selectedLanguageName = null;
                        if (isSimilarPage) {
                            const selectedCountry = countries.find(c => c.location_name === option.text());
                            if (selectedCountry) {
                                selectedLanguageName = selectedCountry.language_name;
                            }
                        }
                        return {
                            countryShorthand: value,
                            country: option.text(),
                            language_name: selectedLanguageName
                        };
                    });

                    // If needed, you can call your countryClicked function with the selected values and texts
                    countryClicked(selectedOptions);
                }
            })
        };

        fetchDataAndInitializeSelect2();
    }, []); // Empty dependency array to run the effect only once

    useEffect(() => {
        setTimeout(() => {
            // Check if selectize is initialized
            const selectizeInstance = $('#select-tools')[0]?.selectize;
            if (selectizeInstance) {
                if (currentCountries !== refactoredPost.countries) {
                    selectizeInstance.clear();
                    var existingItems = Object.values(selectizeInstance.options) || null;

                    if (existingItems) {
                        refactoredPost?.countries?.forEach((term, i) => {
                            if (!isVolumeSearchPrem && i > 0) { return; }
                            term.country = term.country.trim();
                            const itemToFind = existingItems.filter(item => item?.text === term.country || item?.title === term.country)
                            if (itemToFind.length > 0) {
                                selectizeInstance.addItem(itemToFind[0].value || itemToFind[0].id);
                            }
                        });
                    }
                }
            } else {
                console.error('Selectize is not initialized on #select-tools');
            }
        }, 200);
    }, [refactoredPost])

    const getCountries = async () => {
        const response = await fetch(environment.url + 'countriesstatesregionsjson');
        const body = await response.json();
        if (response.status !== 200) throw Error(body.message);
        var countries = Object.values(body).map(item => Object.values(item))[0];
        if (isSimilarPage) {
            countries = countries.filter(country => country.language_name);
        }
        countries.forEach(country => {
            const option = document.createElement('option');
            option.value = country.country_iso_code;
            option.textContent = country.location_name;
            selectRef.current.appendChild(option);
        });
        return countries
    };

    const countryClicked = (value) => {
        value = value.map(c => {
            return { countryShorthand: c.countryShorthand, country: isVolumeSearchPrem ? c.country.slice(0, -1) : c.country }
        })

        if (isVolumeSearchPrem) {
            setCurrentCountries(value)
            addToRefactoredPost('countries', value)
            if (value.length === 5) {
                $('#select-tools-selectized').prop('disabled', true);
            }
        } else if (!isVolumeSearchPrem && value.length === 1) {
            setCurrentCountries(value) //needs to be first
            addToRefactoredPost('countries', value)
            $('#select-tools-selectized').prop('disabled', true);
            addToRefactoredPost('country', value[0].country)
            addToRefactoredPost('countryShorthand', value[0].countryShorthand)
            if (isSimilarPage) { //needs to be last
                addToRefactoredPost('language_name', value[0].language_name)
            }
        } else {
            $('#select-tools-selectized').prop('disabled', false);
            addToRefactoredPost('country', '')
            addToRefactoredPost('countryShorthand', '')
        }
    }

    return (
        <div className="Search-Col-Margins">
            <p id="countryCategoryText">Country:</p>
            <select className="Width-100" id="select-tools" ref={selectRef} multiple placeholder="Worldwide">
                <option data-value="">Worldwide</option>
            </select>

        </div>
    );
};

export default CountriesSelect;
