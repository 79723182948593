import PropTypes from 'prop-types';

export const NotAllowedHerePopup = ({ setNotAllowed }) => (
    <div className="Popup">
        <div className="PopupBox">
            <p id="PopupHeaderText" className="Width-100 CenterText">SORRY, THIS MODULE IS ONLY FOR SUBSCRIBERS</p>
            <button id="cancelOk" onClick={(e) => redirectFunc(setNotAllowed)}>OK</button>
        </div>
    </div>
)

function redirectFunc(setNotAllowed) {
    window.location.replace("/accountpage");
    setNotAllowed(false)
}