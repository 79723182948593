import '../../Css/Popup.css';

export const MaintenanceModePopup = () => {
    return (
        <div className="Popup">
            <div className="GenericPopupBox">
                <h2 id="PopupHeaderTextCenter">Down For Maintenance</h2>
                <p className="Text-Center">Share Of Searching is currently undergoing maintenance. We apologise for any inconvenience caused. For further information please contact Frank Harrison at - <a href="mailto:frank@croftanalytics.com">frank@croftanalytics.com</a></p>
            </div>
        </div >
    )
};