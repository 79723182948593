import HelperFunctions from "../../Helpers/HelperFunctions";
import Chart from "chart.js";
import { useEffect, useState } from "react";
import { Constants } from "../Constants";
import $ from 'jquery';
import { coloursArray, getChartSubTitleString } from "../../Helpers/HelpersConstants";

export const CreateMultiAreaGraphRefactored = ({ areaChartRef, searchDataObj,
    chartDataObject, refactoredPost }) => {

    const isVolumePlus = (window.location.pathname === "/countrysearch");
    const searchTermNotCountry = chartDataObject?.selectedTerm ? true : false;

    const updateChart = (rendered) => {
        if (chartDataObject.nameArray.length === 0) return null;

        const searchProperty = searchDataObj.property === "youtube" ? "YouTube" : "Google";

        const countriesArray = chartDataObject.countriesArray || null;
        const selectedTerm = chartDataObject.selectedTerm || null;
        var nameArray = chartDataObject.nameArray;
        var valuesArray = chartDataObject.volumeArray;
        if (!Array.isArray(valuesArray[0])) valuesArray = [valuesArray]
        const dateArray = chartDataObject.dateArray;
        var indexOfSelected = countriesArray?.indexOf(chartDataObject.selectedCountry) || 0
        if (indexOfSelected < 0) indexOfSelected = 0;

        if (chartDataObject.page === Constants.VOLUME_PLUS_TYPE) {
            if (!rendered) {
                countriesArray.forEach((term, x) => {
                    $('#termSelectArea').append(
                        `<option value='${term}'>
                    ${term}
                </option>`)
                })
            }
        }

        // Use for non percentage - graph plot values
        if (nameArray.length === 1) {
            const valuesArrayVolume = []
            valuesArray[0].forEach(() => {
                valuesArrayVolume.push("100");
            })

            valuesArray = valuesArrayVolume

            var combinedValueArray = new Array(valuesArray[0].split(",").length);

            for (var x = 0; x < combinedValueArray.length; x++) {
                combinedValueArray[x] = new Array();
            }
            valuesArray.forEach((item, i) => {
                var vArray = item.split(",");
                for (var c = 0; c < vArray.length; c++) {
                    combinedValueArray[c].push(vArray[c]);
                }
            });

        } else {

            if (isVolumePlus && selectedTerm && (selectedTerm !== "All")) {
                // Use for non percentage - graph plot values
                const indexOfSelectedSearchTerm = nameArray.indexOf(selectedTerm);
                var combinedValueArray = Array.from({ length: valuesArray.length }, () => []);
                valuesArray.forEach((countryResults, x) => {
                    countryResults.forEach((element, i) => {
                        combinedValueArray[x].push(element.split(",")[indexOfSelectedSearchTerm])
                    });
                })

                var countriesTotalPer = Array.from({ length: combinedValueArray[0].length }, () => []);
                combinedValueArray.forEach((countryRes, i) => {
                    countryRes.forEach((x, i2) => {
                        countriesTotalPer[i2].push(parseInt(x))
                    })
                })
                countriesTotalPer = countriesTotalPer.map(x => (
                    x.reduce((accumulator, currentValue) => {
                        return accumulator + currentValue
                    }, 0)
                ))
                combinedValueArray = combinedValueArray.map((countryRes, i) => (
                    countryRes.map((x, i2) => (
                        ((x / countriesTotalPer[i2]) * 100).toFixed(2)
                    ))
                ))


                nameArray = countriesArray;
            } else {

                // Graph plot points done as a percentage
                if (valuesArray.length === 0) return null;
                var combinedValueArray = new Array(valuesArray[indexOfSelected][0].split(",").length);
                for (var x = 0; x < combinedValueArray.length; x++) {
                    combinedValueArray[x] = new Array();
                }
                if (combinedValueArray.length === 1) {
                    valuesArray[indexOfSelected].forEach((item, i) => {
                        var vArray = item.split(",");
                        for (var c = 0; c < vArray.length; c++) {
                            combinedValueArray[c].push(vArray[c]);
                        }
                    });
                } else {
                    valuesArray[indexOfSelected].forEach((item, i) => {
                        var vArray = item.split(",");
                        var totalOfAll = 0
                        vArray.forEach((item, i) => {
                            totalOfAll = totalOfAll + parseInt(item);
                        });
                        for (var c = 0; c < vArray.length; c++) {
                            var percentageOfCurrent = ((parseInt(vArray[c]) / totalOfAll) * 100).toFixed(2);
                            if (percentageOfCurrent === 'NaN') percentageOfCurrent = '0';
                            combinedValueArray[c].push(percentageOfCurrent);
                        }
                    });
                }
            }
        }

        $('#myAreaChart').remove(); //.remove() this is my <canvas> element
        $('#myAreaChartDiv').append(`<canvas id="myAreaChart" ref=${areaChartRef} />`)
        var concludingChart = new Chart(document.getElementById('myAreaChart'), {
            type: "line",
            data: {
                labels: dateArray,
                datasets: [
                    { label: nameArray[0], data: combinedValueArray[0], fill: true, backgroundColor: coloursArray()[0] },
                ]
            },
            options: {
                animation: {
                    duration: 0
                },
                elements: {
                    point: {
                        radius: 0
                    }
                },
                plugins: {
                    filler: {
                        propagate: true
                    }
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                            max: 100
                        },
                        scaleLabel: {
                            display: true,
                            labelString: `% Share of Search`,
                            fontSize: 20
                        },
                        stacked: true
                    }]
                },
                yAxes: [{
                    ticks: {
                        beginAtZero: true,
                        max: 100
                    }
                }],
                legend: {
                    labels: {
                        // This more specific font property overrides the global property
                        fontColor: 'black',
                        fontSize: 15,
                        fontStyle: 'bold',
                    }
                },
            }
        });
        for (var i = 1; i < nameArray.length; i++) {
            concludingChart.data.datasets.push({
                label: nameArray[i],
                fill: true,
                backgroundColor: coloursArray()[i],
                data: combinedValueArray[i],
                options: {
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true,
                                max: 100
                            },
                            scaleLabel: {
                                display: true,
                                labelString: '% Share of Search',
                                fontSize: 20,
                            },
                            stacked: true
                        }]
                    },
                    plugins: {
                        filler: {
                            propagate: true
                        }
                    }
                },
            });
        }
        // $('.shareOfSearchString').text(this.shareOfSearchString);
        concludingChart.update();
    }

    useEffect(() => {
        if (!chartDataObject) return null;
        setTimeout(() => {
            updateChart()
        }, 500);
    }, [chartDataObject])

    if (!chartDataObject) return null;

    const property = chartDataObject.property === "youtube" ? "YouTube" : "Google";;
    const page = chartDataObject.page;

    var chartSubTitleString = getChartSubTitleString({ refactoredPost, page: isVolumePlus ? Constants.VOLUME_PLUS_TYPE : "", chart: "myAreaChart", chartDataObject })
    const headerHoverText = ({ page }) => {
        const selectedTerm = chartDataObject.selectedTerm || null;
        switch (page) {
            case Constants.INDEX_TYPE:
                return (
                    <div id="graphInfoIText3">
                        This stacked area chart shows the trend in % share of search for each search term. <br />
                        - Each data point is the % share of search for the search term expressed as a % of total volume of search across all the search terms.  <br />
                        - The % share of search numbers add up to 100% when totalled across the search terms.
                    </div>);
            case Constants.VOLUME_TYPE:
                return (
                    <div id="graphInfoIText3">
                        This stacked area chart shows the trend in monthly % share of search volume for each search term. <br />
                        - Each data point is the % share of search for the search term expressed as a % of total volume of search across all the search terms. <br />
                        - The % share of search numbers add up to 100 % when totalled across the search terms for each month.
                    </div>);
            case Constants.VOLUME_PLUS_TYPE:
                if (selectedTerm) {
                    return (
                        <div id="graphInfoIText3">
                            This stacked area chart shows the trend in monthly % share of search for the search
                            terms in the selected country.
                            - Each data point is the % share of search for the search term expressed as % of the
                            total volume of search across all the search terms.
                            - The % share of search numbers add up to 100% when totalled across the search
                            terms for a displayed month.
                        </div>);
                }
                return (
                    <div id="graphInfoIText3">
                        This stacked area chart shows the trend in monthly % share of search for the search
                        terms in the selected country.
                        - Each data point is the % share of search for the search term expressed as % of the
                        total volume of search across all the search terms.
                        - The % share of search numbers add up to 100% when totalled across the search
                        terms for a displayed month.
                    </div>);
            case Constants.SIMILAR_TYPE:
                return (
                    <div id="graphInfoIText3"> This stacked area chart shows the trend in % share of search for each search term. <br />
                        - Each data point is the % share of search for the search term expressed as a % of total volume of search across all the search terms. <br />
                        - The % share of search numbers add up to 100 % when totalled across the search terms.
                    </div>
                )
            default:
                return (
                    <div id="graphInfoIText3">
                        This stacked area chart shows the trend in % share of search for each search term. <br />
                        - Each data point is the % share of search for the search term expressed as a % of total volume of search across all the search terms.  <br />
                        - The % share of search numbers add up to 100% when totalled across the search terms.
                    </div>);
        }
    }

    return (
        <div id="myAreaChartDiv">
            <p class="ChartTitles ChartTitle3">{(isVolumePlus && searchTermNotCountry) ? `Country % Share of Google Search for Search Term` : (isVolumePlus && !searchTermNotCountry) ? `Search Term % Share of Google Search for selected country` : `${property} Trend in % Share of Search`}</p>
            {headerHoverText({ page })}
            <p class="chartSubTitles">{chartSubTitleString}</p>
            {isVolumePlus && <p class="chartSubTitles Font-Bold Color-Darkgrey">{chartDataObject.selectedTerm ? chartDataObject.selectedTerm : chartDataObject.selectedCountry ? chartDataObject.selectedCountry : chartDataObject.countriesArray[0]}</p>}
            <canvas
                id="myAreaChart"
                ref={areaChartRef}
                style={{ height: "auto" }}
            />
        </div>
    )
}
