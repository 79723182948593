import React, { useEffect } from 'react'
import $ from 'jquery';
import "../../Css/ComponentCss/IndexTrendsStatusController.css"
import { updateSetting } from '../../Helpers/HelpersConstants';

export const IndexTrendsStatusController = ({ environment }) => {

    useEffect(() => {
    }, [])

    const sliderChange = (value) => {

        const slider = document.getElementById("myRange");

        if (slider) {
            slider.className = ""; // Clear existing classes

            switch (parseInt(value)) {
                case 1:
                    updateSetting({ name: "IndexStatus", status: "ON", environment })
                    slider.classList.add("IndexStatusSlider");
                    slider.classList.add("green");
                    break;
                case 2:
                    updateSetting({ name: "IndexStatus", status: "WARNING", environment })
                    slider.classList.add("IndexStatusSlider");
                    slider.classList.add("orange");
                    break;
                case 3:
                    updateSetting({ name: "IndexStatus", status: "OFF", environment })
                    slider.classList.add("IndexStatusSlider");
                    slider.classList.add("red");
                    break;
                default:
                    slider.classList.add("IndexStatusSlider");
                    slider.classList.add("grey");
                    break;
            }
        } else {
            console.error("Slider element not found");
        }
    }

    return (
        <div className="IndexStatusComponentDiv">
            <label>Index Trends Status:</label>
            <div className="slidecontainer">
                <div className="IndexStatusText">
                    <p>ON</p>
                    <p>WARNING</p>
                    <p>OFF</p>
                </div>
                <input type="range" min="1" max="3" className="IndexStatusSlider" id="myRange" onChange={e => sliderChange(e.target.value)} />
            </div>
        </div>
    )
}
