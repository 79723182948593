import HelperFunctions from "../../Helpers/HelperFunctions";
import Chart from "chart.js";
import ShareOfSearchRetrieval from "../../../ShareOfSearchRetrieval";
import excelIcon from '../../../images/excelIcon.png';
import { useEffect, useState } from "react";
import { Constants } from "../Constants";
import $ from 'jquery';
import { coloursArray, getAllVolumePlusPercentageDataForGraphs, getChartSubTitleString, getDDMMMYYYYGraphDate, getPercentageDataForGraphs, removeEndCommas } from "../../Helpers/HelpersConstants";

export const CreateMultiPercentGraphRefactored = ({ chartDataObject, percentChartRef, searchDataObj, downloadContainerElement, isSubscriber, setShowSubscribersOnly, refactoredPost }) => {
    const isVolumePlus = (window.location.pathname === "/countrysearch");

    useEffect(() => {
        if (!chartDataObject) return null;
        setTimeout(() => {
            updateChart()
        }, 500);
    }, [chartDataObject])

    if (!chartDataObject) return null;

    const property = chartDataObject.property === "youtube" ? "YouTube" : "Google";;
    const page = chartDataObject.page;

    var chartSubTitleString = getChartSubTitleString({ refactoredPost, page: isVolumePlus ? Constants.VOLUME_PLUS_TYPE : "", chart: "myChart", chartDataObject })

    const headerHoverText = ({ page }) => {
        const selectedTerm = chartDataObject.selectedTerm || null;
        switch (page) {
            case Constants.INDEX_TYPE:
                return (
                    <div id="graphInfoIText2">
                        This line chart shows the trend in % share of search for each search term. <br />
                        - Each data point is the % share of search for the search term expressed as a % of total volume of search across all the search terms. <br />
                        - The % share of search numbers add up to 100% when totalled across the search terms for a displayed date
                    </div>);
            case Constants.VOLUME_TYPE:
                return (
                    <div id="graphInfoIText2">
                        This line chart shows the trend in monthly % share of search volume for each search term. <br />
                        - Each data point is the % share of search for the search term expressed as a % of total volume of search across all the search terms. <br />
                        - The % share of search numbers add up to 100 % when totalled across the search terms for a displayed month.
                    </div>);
            case Constants.VOLUME_PLUS_TYPE:
                if (selectedTerm) {
                    return (
                        <div id="graphInfoIText2">
                            This chart shows the monthly % contribution of search volume for the selected
                            search term in each country to the total volume of search for the selected search
                            term across all the countries.
                            - Each data point is the % share of search for the selected search term expressed as
                            % of the total volume of search for that search term across the countries.
                            - The % share of search numbers add up to 100% when totalled across the
                            countries for a displayed month.
                        </div>);
                }
                return (
                    <div id="graphInfoIText2">
                        This chart shows the trend in monthly % share of search for the search terms in the
                        selected country.
                        - Each data point is the % share of search for the search term expressed as % of the
                        total volume of search across all the search terms.
                        - The % share of search numbers add up to 100% when totalled across the search
                        terms for a displayed month.
                    </div>);
            case Constants.SIMILAR_TYPE:
                return (
                    <div id="graphInfoIText2">
                        This line chart shows the trend in % share of search for each search term. <br />
                        - Each data point is the % share of search for the search term expressed as a % of total volume of search across all the search terms. <br />
                        - The % share of search numbers add up to 100 % when totalled across the search terms for a displayed date.
                    </div>
                )
            default:
                return (
                    <div id="graphInfoIText2">
                        This line chart shows the trend in % share of search for each search term. <br />
                        - Each data point is the % share of search for the search term expressed as a % of total volume of search across all the search terms. <br />
                        - The % share of search numbers add up to 100% when totalled across the search terms for a displayed date
                    </div>);
        }
    }

    const updateChart = (rendered) => {

        const countriesArray = chartDataObject.countriesArray || null;
        const selectedTerm = chartDataObject.selectedTerm || null;
        var nameArray = chartDataObject.nameArray;
        var valuesArrayVolume = chartDataObject.volumeArray;
        if (!Array.isArray(valuesArrayVolume[0])) valuesArrayVolume = [valuesArrayVolume]

        const dateArray = chartDataObject.dateArray;
        var indexOfSelected = countriesArray?.indexOf(chartDataObject.selectedCountry) || 0
        if (selectedTerm) { indexOfSelected = nameArray?.indexOf(selectedTerm) || 0 }
        if (indexOfSelected < 0) indexOfSelected = 0;

        const searchProperty = searchDataObj.property === "youtube" ? "YouTube" : "Google";
        var valuesArray = valuesArrayVolume;
        const isIndexPage = window.location.pathname === "/search";

        if (chartDataObject.page === Constants.VOLUME_PLUS_TYPE) {
            if (!rendered) {
                countriesArray.forEach((term, x) => {
                    $('#termSelectPercent').append(
                        `<option value='${term}'>
                    ${term}
                </option>`)
                })
            }
        }


        nameArray = removeEndCommas(nameArray);

        if (nameArray.length === 1) {
            valuesArray = [];
            valuesArrayVolume[indexOfSelected].forEach((item, i) => {
                valuesArray.push("100");
            });

            // Use for non perrcentage - graph plot values

            var combinedValueArray = new Array(valuesArray[0].split(",").length);
            for (var x = 0; x < combinedValueArray.length; x++) {
                combinedValueArray[x] = [];
            }
            valuesArray.forEach((item, i) => {
                var vArray = item.split(",");
                for (var c = 0; c < vArray.length; c++) {
                    combinedValueArray[c].push(vArray[c]);
                }
            });

        } else {
            // Graph plot points done as a percentage
            const volumePlusObj = { isVolumePlus, nameArray, selectedTerm, countriesArray }
            var combinedValueArray = getPercentageDataForGraphs({ valuesArray, indexOfSelected, volumePlusObj, forSearchTerm: false })
            var allVolumePercentages = getAllVolumePlusPercentageDataForGraphs({ valuesArray, indexOfSelected, volumePlusObj, forSearchTerm: false })
            if (countriesArray?.length > 1 && selectedTerm) nameArray = countriesArray;
        }

        // below match with line: 314;
        // var barChartPercentages = []
        // valuesArrayVolume[indexOfSelected].forEach(item => {
        //     item.split(',').forEach((num, index) => {
        //         if (barChartPercentages[index]) {
        //             barChartPercentages[index] += num
        //         } else { barChartPercentages[index] = num }
        //     })
        // })
        // const maxBarChartPercentages = barChartPercentages.reduce((a, b) => a + b);
        // barChartPercentages = barChartPercentages.map(num =>
        //     (num / maxBarChartPercentages * 100).toFixed(2)
        // )

        var valueForBarYaxes = 0;


        let barChartMaxY = valueForBarYaxes;
        if ((parseInt(barChartMaxY) >= 50)) {
            if ((parseInt(barChartMaxY) + 5) % 5 === 0) {
                barChartMaxY = (parseInt(barChartMaxY) + 5);
            }
            if (((parseInt(barChartMaxY) + 4) % 5) === 0) {
                barChartMaxY = (parseInt(barChartMaxY) + 4);
            }
            if (((parseInt(barChartMaxY) + 3) % 5) === 0) {
                barChartMaxY = (parseInt(barChartMaxY) + 4);
            }
            if (((parseInt(barChartMaxY) + 2) % 5) === 0) {
                barChartMaxY = (parseInt(barChartMaxY) + 4);
            }
            if (((parseInt(barChartMaxY) + 6) % 5) === 0) {
                barChartMaxY = (parseInt(barChartMaxY) + 6);
            }
        } else {
            if ((parseInt(barChartMaxY) + 2) % 2 === 0) {
                barChartMaxY = (parseInt(barChartMaxY) + 2);
            } else { barChartMaxY = (parseInt(barChartMaxY) + 3) }
        }

        $('#myChart').remove(); //.remove() this is my <canvas> element
        $('#myChartDiv').append(`<canvas id="myChart" ref=${percentChartRef} />`)

        var concludingChart = new Chart(document.getElementById('myChart'), {
            type: "line",
            data: {
                labels: dateArray,
                datasets: [
                    { label: nameArray[0], data: combinedValueArray[0], fill: false, borderColor: coloursArray()[0] },
                ]
            },
            options: {
                animation: {
                    duration: 0
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                        },
                        scaleLabel: {
                            display: true,
                            labelString: `% Share of Search`,
                            fontSize: 20,
                        },
                    }]
                },
                legend: {
                    labels: {
                        // This more specific font property overrides the global property
                        fontColor: 'black',
                        fontSize: 15,
                        fontStyle: 'bold',
                    }
                },
            }
        })
        for (var i = 1; i < nameArray.length; i++) {
            concludingChart.data.datasets.push({
                label: nameArray[i],
                fill: false,
                borderColor: coloursArray()[i],
                data: combinedValueArray[i],
            });
        }
        var country = searchDataObj.country ? searchDataObj.country.toUpperCase() : null;
        var category = searchDataObj.category ? searchDataObj.category.toUpperCase() : null;
        var startdate = !searchDataObj.startDate ? null : getDDMMMYYYYGraphDate(searchDataObj.startDate)
        var enddate = !searchDataObj.endDate ? null : getDDMMMYYYYGraphDate(searchDataObj.endDate)
        var shorthands = "";
        if (searchDataObj.stateShorthand !== "" && searchDataObj.regionShorthand !== "") {
            shorthands = searchDataObj.stateName + " | " + searchDataObj.region
        }
        if (searchDataObj.regionShorthand === "" && searchDataObj.stateShorthand !== "") {
            shorthands = searchDataObj.stateName
        }

        for (var c = 0; c < dateArray.length; c++) {
            var date = dateArray[c].split(" ");
            if (date.length === 3) { // if date is done in days (> 3 and its done in weeks || < 3 and its done in months)
                // if (parseInt(date[1].substring(0, date[1].length)) < 10) {
                //     date[1] = "0" + date[1];
                // }
                date = [date[1], date[0], date[2]];
            }
            dateArray[c] = date.join(" ");
            dateArray[c] = dateArray[c].replace(',', '');
            dateArray[c] = dateArray[c].replace('\u2013', '-');
        }

        var elementToRemove = document.getElementById("percentageDownloadbutton");

        // Check if the element exists before attempting to remove it
        if (elementToRemove) {
            elementToRemove.parentNode.removeChild(elementToRemove);
        }

        var button = document.createElement("button");
        button.id = "percentageDownloadbutton"
        button.innerHTML = `<div id="downloadButtonDiv"><img id='downloadIcon' style="margin-right: 10%" src=${excelIcon} /><b>EXPORT % SHARE</b></div>`
        button.onclick = function () {

            if (!isSubscriber) {
                setShowSubscribersOnly(true);
                return;
            }

            var namesArray = nameArray
            var datesArray = dateArray
            var valuesArray = combinedValueArray

            var cva = [];
            for (var x = 0; x < valuesArray[0].length; x++) {
                cva.push(new Array());
            }
            for (var c = 0; c < valuesArray.length; c++) {
                for (var x = 0; x < cva.length; x++) {
                    cva[x].push(valuesArray[c][x])
                }
            }


            var countriesArr = chartDataObject?.countriesArray || ""
            const volumePlusArr = [null,];

            if (isVolumePlus) {
                valuesArray.forEach((country, i) => {
                    volumePlusArr.push(countriesArr[i]);
                    nameArray.forEach((term, o) => {
                        if (o !== 0) {
                            volumePlusArr.push(null)
                        }
                    })
                })
            }

            var rows = [
                [`${searchProperty} % SHARE OF SEARCH / ` + country +
                    (isIndexPage && shorthands !== "" ? ` ( ${shorthands} )` : "") +
                    (isIndexPage ? ` | ${category}` : "") +
                    " | " + startdate +
                    " - " + enddate],
                (isVolumePlus ? volumePlusArr : null),
                ["Dates", namesArray[0]]
            ];

            if (isVolumePlus) {
                // Iterate over valuesArray and namesArray to populate rows[2]
                for (var o = 0; o < allVolumePercentages.length; o++) {
                    for (var x = (o === 0 ? 1 : 0); x < namesArray.length; x++) {
                        rows[2].push(namesArray[x]);
                    }
                }

                // allVolumePercentages = [[['37.95', '31.02', '31.02'], ['37.95', '31.02', '31.02'], ['45.78', '37.40', '16.82'], ['43.47', '43.47', '13.06']]
                for (var x = 0; x < datesArray.length; x++) {
                    var arr = allVolumePercentages.map(data => data[x].join());
                    rows.push([datesArray[x], ...arr]);
                }

            } else {
                rows[1] = ["Dates", namesArray[0]]; // Set rows[1] as the array containing "Dates"
                rows.splice(2, 1); // Remove row[2]
                for (var x = 1; x < namesArray.length; x++) {
                    rows[1].push(namesArray[x]);
                }
                for (var c = 0; c < cva.length; c++) {
                    rows.push(Array.of(datesArray[c], cva[c]));
                }
            }

            let csvContent = "";

            rows.forEach(function (rowArray) {
                let row = rowArray.join(",");
                csvContent += row + "\r\n";
            });

            var encodedUri = encodeURI(csvContent);
            var link = document.createElement("a");
            link.setAttribute("href", "data:text/csv;charset=utf-8,\uFEFF" + encodedUri);
            link.setAttribute("download", "percentageSOS.csv");
            document.body.appendChild(link); // Required for FF

            link.click(); // This will download the data file named "my_data.csv".
        }

        downloadContainerElement.appendChild(button);

        concludingChart.update();

    }

    return (
        <div id="myChartDiv">
            <p class="ChartTitles ChartTitle2">{isVolumePlus && chartDataObject.selectedTerm ? `Country % Share of Google Search for Search Term` : `${property} Trend in % Share of Search`}</p>
            {headerHoverText({ page })}
            <p class="chartSubTitles">{chartSubTitleString}</p>
            {isVolumePlus && <p class="chartSubTitles Font-Bold Color-Darkgrey">{chartDataObject.selectedTerm ? chartDataObject.selectedTerm : chartDataObject.selectedCountry ? chartDataObject.selectedCountry : chartDataObject.countriesArray[0]}</p>}
            < canvas
                id="myChart"
                ref={percentChartRef}
                style={{ height: "auto" }}
            />
        </div>
    );
}