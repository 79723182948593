import React, { Component } from 'react';
import './Css/ResetPassword.css';
import './Css/VerifyEmail.css';
import logo from '../images/ShareOfSearching.png';
import HelperFunctions from './Helpers/HelperFunctions';


class VerifyEmail extends Component {

  helperFunctions = new HelperFunctions;
  userEmail = "";
  environmentUrl = 'https://servershareofsearch.herokuapp.com/api/';
  isLocal = false;

  componentDidMount() {
    const environment = this.helperFunctions.testEnvironment(window.location.hostname);
    this.environmentUrl = environment.url;
    this.isLocal = environment.localhost;
    this.checkUrlLink(this);
    document.getElementById('navBar')?.remove();
  }

  checkUrlLink = async e => {
    var currentUrl = window.location.href;
    var currentUrlArray = currentUrl.split("/")
    var currentCode = currentUrlArray[currentUrlArray.length - 1];
    var accountInfo = [];
    accountInfo.push(currentCode);

    const response = await fetch(this.environmentUrl + 'getemailverificationlinkbycode', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ post: accountInfo }),
    });

    const body = await response.json();
    if (body.length < 1) {
      alert("Error: Unable to verify account. For problems logging in please contact frank@croftanalytics.com");
      window.location.replace("/");
    }
    this.userEmail = body[0].Email;
    this.logIn(e);
  }

  logIn = async e => {

    var accountInfo = [];
    accountInfo.push(this.userEmail);

    const responseAllowed = await fetch(this.environmentUrl + 'setallowedsearchtrue', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ post: accountInfo }),
    });
    const responseAllowedSearch = await responseAllowed.json();

    const response = await fetch(this.environmentUrl + 'deleteemailverificationlink', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ post: accountInfo }),
    });
    const responseDeleted = await response.json();

    if (responseAllowedSearch != true || responseDeleted != true) {
      alert("Error: Unable to verify account. For problems logging in please contact frank@croftanalytics.com");
    }
  }

  goToLogIn = async e => {
    e.preventDefault()
    window.location.replace("https://www.shareofsearching.com/login");
  }



  render() {
    document.title = "Share Of Searching";
    return (
      <div className="ResetPassword">
        <img src={logo} alt="Logo" id="sosingImage" />
        <div id="resetPasswordBox">
          <form id="resetPasswordForm" onSubmit={(e) => this.goToLogIn(e)}>
            <p id="verifyEmailHeader">Thank you, your email address has been verified! </p>
            <button id="submit" type="submit">Log In</button>
          </form>
        </div>
      </div>
    );
  }



}

export default VerifyEmail;
