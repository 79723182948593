import React, { useState, useEffect } from 'react';
import './Css/ForgotPassword.css';
import logo from '../images/ShareOfSearching.png';
import $ from 'jquery';
import HelperFunctions from './Helpers/HelperFunctions';


const ForgotPassword = () => {

  const helperFunctions = new HelperFunctions;
  const [environmentUrl, setEnvironmentUrl] = useState('https://servershareofsearch.herokuapp.com/api/');
  const [emailError, setEmailError] = useState(false);
  const [showResetPopup, setShowResetPopup] = useState(false);

  var isLocal = false;
  const environment = helperFunctions.testEnvironment(window.location.hostname);
  isLocal = environment.localhost;

  useEffect(() => {
    setEnvironmentUrl(environment.url);
  }, [])

  const sendEmailController = async (e) => {
    e.preventDefault();
    var randomNumber = Math.ceil(Math.random() * 10);
    var randomCode = await makeid(randomNumber);
    var accountEmail = $("#emailInputByUser").val();
    if (accountEmail === '' || !accountEmail || !accountEmail.includes('@')) {
      setEmailError(true);
      return;
    } else {
      setEmailError(false);
    }

    checkEmail(accountEmail);
    resetPasswordSendEmail(randomCode, accountEmail);
  }

  const checkEmail = async (accountEmail) => {
    var emailInfo = [];
    emailInfo.push(accountEmail);
    const checkEmailResponse = await fetch(environmentUrl + 'checkemailexists', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ post: emailInfo }),
    });
    const body = await checkEmailResponse.json();
    if (body < 1) {
      alert("Error: Email not associated to any account");
      window.location.reload();
    }
  }

  const resetPasswordSendEmail = async (randomCode, accountEmail) => {
    var codeInfo = [];
    codeInfo.push(randomCode);
    codeInfo.push(accountEmail);
    var accountInfo = { accountEmail, randomCode };

    const setLinkResponse = await fetch(environmentUrl + 'setrandomlink', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ post: codeInfo }),
    });

    const allowedSetLinkBoolean = await setLinkResponse.json();
    if (allowedSetLinkBoolean !== true) {
      alert("Error: Cannot send reset email");
      window.location.replace("https://www.shareofsearching.com/login");
    }

    setShowResetPopup(true)

    const response = await fetch(environmentUrl + 'sendEmail', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ post: accountInfo }),
    });

    const allowedLoginBoolean = await response.json();
    if (allowedLoginBoolean === true) {
      setShowResetPopup(true)
    }
  }

  const hidePopup = async (e) => {
    e.preventDefault();
    setShowResetPopup(false);
    window.location.replace("/login");
  }

  const makeid = async (length) => {
    var result = [];
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result.push(characters.charAt(Math.floor(Math.random() *
        charactersLength)));
    }
    return result.join('');
  }

  return (
    <div className="ForgotPassword">
      {showResetPopup && (
        <div className="ResetPasswordPopup">
          <div id="resetPasswordPopupBox">
            <p id="popupResetPasswordText"><b>A Reset Password Email Has Been Sent</b></p>
            <p id="smallresetPasswordText">Please check your email for a reset password link. It may have gone into junk/spam. Follow the link in the email to reset your password</p><br />
            <button id="cancelOk" onClick={(e) => hidePopup(e)}>OK</button>
          </div>
        </div>
      )}
      <div id="navHiderForgottenPassword"></div>
      <img src={logo} alt="Logo" id="sosingImage" />
      <div className="ForgotPasswordFormDiv">
        <p id="forgotPasswordText">Forgotten Password?</p>
        <p id="subHeaderForgottenText">Enter your email address, then click Submit to receive a 'reset password' email</p>
        <form className="ForgotPasswordForm" onSubmit={(e) => sendEmailController(e)}>
          <div id="emailContainerLine">
            <input
              id="emailInputByUser"
              type="email"
              placeholder="Email"
              required
              style={{ border: emailError ? 'solid red' : '' }}
            />
          </div>
          <div id="centerSubmit">
            <button id="submitForgottenPasswordButton" type="submit">Submit</button>
          </div>
        </form>
      </div>
    </div>
  );



}

export default ForgotPassword;
