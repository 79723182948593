import HelperFunctions from "../../Helpers/HelperFunctions";
import Chart from "chart.js";
import $ from 'jquery';
import { useEffect, useState } from "react";
import { Constants } from "../Constants";
import { coloursArray, getChartSubTitleString } from "../../Helpers/HelpersConstants";

export const CreateMultiBarGraphRefactored = ({ chartDataObject, barChartRef, searchDataObj, refactoredPost }) => {

    const isVolumePlus = (window.location.pathname === "/countrysearch");

    const updateChart = (rendered) => {

        if (chartDataObject.nameArray.length === 0) return null;

        const countriesArray = chartDataObject.countriesArray || null;
        const selectedTerm = chartDataObject.selectedTerm || null;
        var nameArray = chartDataObject.nameArray;
        var valuesArray = chartDataObject.volumeArray;
        if (!Array.isArray(valuesArray[0])) valuesArray = [valuesArray]
        const dateArray = chartDataObject.dateArray;
        var indexOfSelected = countriesArray?.indexOf(chartDataObject.selectedCountry) || 0
        if (indexOfSelected < 0) indexOfSelected = 0;

        if (chartDataObject.page === Constants.VOLUME_PLUS_TYPE) {
            if (!rendered) {
                countriesArray.forEach((term, x) => {
                    $('#termSelectBar').append(
                        `<option value='${term}'>
                    ${term}
                </option>`)
                })
            }
        }

        if (isVolumePlus && selectedTerm && (selectedTerm !== "All")) {

            const indexOfSelectedSearchTerm = nameArray.indexOf(selectedTerm);
            var barChartPercentages = []
            var transposedArray = Array.from({ length: valuesArray[0].length }, () => []);

            valuesArray.forEach((countryRes, i) => {
                countryRes.forEach((c, o) => {
                    const transposedValue = c.split(",")[indexOfSelectedSearchTerm];
                    transposedArray[o].push(parseInt(transposedValue));
                });
            });

            transposedArray.forEach(item => {
                item.forEach((num, index) => {
                    if (barChartPercentages[index]) {
                        barChartPercentages[index] += num
                    } else { barChartPercentages[index] = num }
                })
            })
            var volumeTotals = [...barChartPercentages].map(num => num.toLocaleString());
            const maxBarChartPercentages = barChartPercentages.reduce((a, b) => a + b);
            barChartPercentages = barChartPercentages.map(num =>
                (num / maxBarChartPercentages * 100).toFixed(2)
            )

            nameArray = countriesArray;

        } else {
            valuesArray = valuesArray[indexOfSelected].map(item => item.split(",").map(i => parseInt(i)));
            var barChartPercentages = []
            valuesArray.forEach(item => {
                item.forEach((num, index) => {
                    if (barChartPercentages[index]) {
                        barChartPercentages[index] += num
                    } else { barChartPercentages[index] = num }
                })
            })
            var volumeTotals = [...barChartPercentages].map(num => num.toLocaleString());
            const maxBarChartPercentages = barChartPercentages.reduce((a, b) => a + b);
            barChartPercentages = barChartPercentages.map(num =>
                (num / maxBarChartPercentages * 100).toFixed(2)
            )
        }

        var valueForBarYaxes = Math.max(...barChartPercentages)

        let barChartMaxY = valueForBarYaxes;
        barChartMaxY = Math.ceil(barChartMaxY / 10) * 10.5;

        const searchProperty = searchDataObj.property === "youtube" ? "YouTube" : "Google";
        var combinedValueArray = barChartPercentages;
        if (combinedValueArray.length < 1) { $('#barChartProblemTitle').append('<br /> <b style="color: red">DATA NOT AVAILABLE FOR ALL BRANDS FOR THE WHOLE PERIOD</b>') };


        $('#myBarChart').remove(); //.remove() this is my <canvas> element
        $('#myBarChartDiv').append(`<canvas id="myBarChart" ref=${barChartRef} />`)

        var concludingChart = new Chart(document.getElementById('myBarChart'), {
            type: 'bar',
            data: {
                labels: nameArray,
                datasets: [{
                    data: barChartPercentages,
                    backgroundColor: coloursArray(),
                    borderColor: coloursArray(),
                    borderWidth: 1
                }]
            },
            options: {
                animation: {
                    duration: 0
                },
                scales: {
                    xAxes: [{
                        ticks: {
                            fontSize: 20
                        }
                    }],
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                            max: barChartMaxY,
                        },
                        scaleLabel: {
                            display: true,
                            labelString: `Total % Share Of Search`,
                            fontSize: 20
                        },
                    }]
                },
                legend: {
                    display: false,
                    labels: {
                        // This more specific font property overrides the global property
                        display: false,
                        fontSize: 0,
                    }
                },
            }
        });

        concludingChart.options.animation.onComplete = function () {
            var ctx = this.chart.ctx;
            // ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily);
            ctx.textAlign = "center";
            ctx.textBaseline = "bottom";
            concludingChart.data.datasets.forEach(function (dataset, i) {
                var meta = concludingChart.controller.getDatasetMeta(i);
                meta.data.forEach(function (bar, index) {
                    var data = parseFloat(dataset.data[index]).toFixed(1) + "%";
                    ctx.font = Chart.helpers.fontString(16, "bold", Chart.defaults.global.defaultFontFamily);
                    ctx.fillText(data, bar._model.x, bar._model.y - 15);
                    if (page !== Constants.INDEX_TYPE) {
                        ctx.font = Chart.helpers.fontString(12, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily);
                        ctx.fillText(`(${volumeTotals[index]})`, bar._model.x, bar._model.y);
                    }
                });
            })
        }
        if (concludingChart.width < 100) {
            concludingChart.width = 700;
        }
        concludingChart.update();
    };

    useEffect(() => {
        if (!chartDataObject) return null;
        setTimeout(() => {
            updateChart()
        }, 500);
    }, [chartDataObject])

    if (!chartDataObject) return null;

    const property = chartDataObject.property === "youtube" ? "YouTube" : "Google";;
    const page = chartDataObject.page;

    var chartSubTitleString = getChartSubTitleString({ refactoredPost, page: isVolumePlus ? Constants.VOLUME_PLUS_TYPE : "", chart: "myBarChart", chartDataObject })
    const headerHoverText = ({ page }) => {
        const selectedTerm = chartDataObject.selectedTerm || null;

        switch (page) {

            case Constants.INDEX_TYPE:
                return (
                    <div id="graphInfoIText4">
                        This chart shows the % share search for each search term as a % of total search volume across all the terms for the whole of the defined period. <br />
                        - The % share of search numbers add up to 100% across the search terms.
                    </div>);
            case Constants.VOLUME_TYPE:
                return (
                    <div id="graphInfoIText4">
                        This chart shows the % share search for each search term as a % of total search volume across all the terms for the whole of the defined period. <br />
                        - The % share of search numbers add up to 100 % across the search terms.
                    </div>);
            case Constants.VOLUME_PLUS_TYPE:
                if (selectedTerm) {
                    return (
                        <div id="graphInfoIText4">
                            This chart shows the % contribution of search volume for the selected search term
                            in each country to the total volume of search for that search term across all the
                            countries over the whole of the defined period.
                            - Each data point is the % share of search for the selected search term expressed as
                            % of the total volume of search for that search term across the countries.
                            - The % share of search numbers add up to 100% when totalled across the
                            countries.
                        </div>);
                }
                return (
                    <div id="graphInfoIText4">
                        This chart shows the % share of search for each search term as a % of the total
                        search volume across all the search terms for the whole of the date period in the
                        selected country.
                        - The % share of search numbers add up to 100% across the search terms.
                        - The numbers in brackets are the total volume of search for the search term across
                        the period.
                    </div>);
            case Constants.SIMILAR_TYPE:
                return (
                    <div id="graphInfoIText4">
                        This chart shows the % share search for each search term as a % of total search volume across all the terms for the whole of the defined period. <br />
                        - The % share of search numbers add up to 100 % across the search terms.
                    </div>)
            default:
                return (<div id="graphInfoIText4">
                    This chart shows the % share search for each search term as a % of total search volume across all the terms for the whole of the defined period. <br />
                    - The % share of search numbers add up to 100% across the search terms.
                </div>)
        }
    }

    return (
        <div id="myBarChartDiv">
            <p class="ChartTitles ChartTitle4">{isVolumePlus && chartDataObject.selectedTerm ? `% Share of Total Search For A Search Term Across Countries` : `${property} Total % Share of Search`}</p>
            {headerHoverText({ page })}
            <p class="chartSubTitles">{chartSubTitleString}</p>
            {isVolumePlus && <p class="chartSubTitles Font-Bold Color-Darkgrey">{chartDataObject.selectedTerm ? chartDataObject.selectedTerm : chartDataObject.selectedCountry ? chartDataObject.selectedCountry : chartDataObject.countriesArray[0]}</p>}
            <canvas
                id="myBarChart"
                ref={barChartRef}
                style={{ height: "auto" }}
            />
        </div>
    )
}