import React, { useState, useEffect, useRef } from 'react'
import { ExpiredSubscriptionPopup } from '../Components/Popups/ExpiredSubscriptionPopup'
import { SubscribersOnly } from '../Components/Popups/SubscribersOnly'
import { NoPermissionPopup } from '../Components/Popups/NoPermissionPopup'
import { NoPermissionLimitReachedPopup } from '../Components/Popups/NoVolumePermissionPopup'
import { SavedSearchConfirmDelete } from '../Components/Popups/SavedSearchConfirmDelete'
import { SaveSearchPopup } from '../Components/Popups/SaveSearchPopup'
import { EditSearchListPopup } from '../Components/Popups/EditSearchListPopup'
import { SavedSearchesPopup } from '../Components/Popups/SavedSearchesPopup'
import { Tabs } from '../Components/Tabs'
import SearchColumn from '../Components/SearchColumn'
import GraphColumn from '../Components/GraphColumn'
import { Footer } from '../Components/Footer'
import { Constants } from '../Components/Constants'
import { checkPageAllowed, createPowerpointDownloadButtons, editSearchesOk, getClientInfo, handleMultiSubmit, minDate, resetSearchWords, saveSearchOk, savedSearchDeleteFunc, setStickyHeader, showAllSavedSearchesFunc, tabHandler } from '../Helpers/HelpersConstants'
import { GenericPopup } from '../Components/Popups/GenericPopup'

const SearchVolumePrem = ({ location }) => {
    const { environment, userInfo } = location.state || {};
    if (!userInfo) window.location.href = ("/login");

    const [refactoredPost, setRefactoredPost] = useState({ searchTerms: [], property: "web", countries: [] });
    const [showEditList, setShowEditList] = useState(false);
    const [showSavedSearchDelete, setShowSavedSearchDelete] = useState(false);
    const [savedSearchesToRender, setSavedSearchesToRender] = useState([]);
    const [showSubscribersOnly, setShowSubscribersOnly] = useState(false);
    const [showSaveSearch, setShowSaveSearch] = useState(false);
    const [saveSearchSubmit, setSaveSearchSubmit] = useState(false);
    const [saveSearchNameInput, setSaveSearchNameInput] = useState('');
    const [savedSearchToDelete, setSavedSearchToDelete] = useState({});
    const [showSavedSearches, setShowSavedSearches] = useState(false);
    const [showNoPermission, setShowNoPermission] = useState(false);
    const [scrollDown, setScrollDown] = useState(false);
    const [numberOfRunSearches, setNumberOfRunSearches] = useState(0);
    const [responseToPostVolume, setResponseToPostVolume] = useState();
    const [dateArray, setDateArray] = useState([]);
    const [volumeArray, setVolumeArray] = useState([]);
    const [searchDataObj, setSearchDataObj] = useState({});
    const [chartDataObject, setChartDataObject] = useState();
    const [showGraphText, setShowGraphText] = useState(true);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [selectedYearlyChartTerm, setSelectedYearlyChartTerm] = useState();
    const [selectedYearlyPercentChartTerm, setSelectedYearlyPercentChartTerm] = useState();
    const chartRef = useRef()
    const chartRefPerForSearchTerm = useRef()
    const areaChartRef = useRef()
    const barChartRef = useRef()
    const barChartRefForSearchTerm = useRef()
    const volumeChartRef = useRef()
    const countryVolumeChartRef = useRef()
    const countryVolumeTotalChartRef = useRef()
    const barSliderChartRef = useRef()
    const yearlyChartRef = useRef()
    const yearlyPercentChartRef = useRef()
    const [chartsLoaded, setChartsLoaded] = useState(false);
    const [nonReturnedCountriesPopup, setNonReturnedCountriesPopup] = useState({ show: false, headerText: '', bodyText: '' });

    const addToRefactoredPost = (field, value) => {
        setRefactoredPost(prevState => ({ ...prevState, [field]: value }));
    }

    const addToChartDataObject = (field, value) => {
        setChartDataObject(prevState => ({ ...prevState, [field]: value }));
    }

    useEffect(async () => {
        if (saveSearchSubmit) {
            const response = await fetch(environment.url + 'setsavedsearch', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ post: refactoredPost }),
            });
            let responseBool = await response.json();
            setSaveSearchSubmit(false);
        }
    }, [saveSearchSubmit])

    useEffect(() => {
        tabHandler(window.location.pathname);
        addToRefactoredPost('email', userInfo.email);
        addToRefactoredPost('accountId', userInfo.accountId);
        tabHandler(window.location.pathname);
        getClientInfo(addToRefactoredPost);
        var header = document.getElementById("navBar");
        var sticky = header.offsetTop;
        window.onscroll = async () => await setStickyHeader(header, sticky, setScrollDown);
    }, [])

    useEffect(() => {
        createPowerpointDownloadButtons({
            refactoredPost,
            downloadContainerElement: document.getElementById("downloadContainer"),
            page: Constants.VOLUME_PLUS_TYPE,
            isSubscriber: userInfo.isSubscriber,
            setShowSubscribersOnly,
            chartDataObject
        }
        );
        if (chartDataObject?.nonReturnedCountries.length > 0) {
            setNonReturnedCountriesPopup({ show: true, headerText: "Sorry, data for the following countries could not be retrieved:", bodyText: chartDataObject.nonReturnedCountries.map(country => country.country).join(', ') })
        }
    }, [refactoredPost, selectedYearlyChartTerm, selectedYearlyPercentChartTerm, chartsLoaded, chartDataObject])


    const requirementsObj = { countries: true, categories: false, statesRegions: false, startDate: true, endDate: true, properties: false, saveSearch: true, retrieveSavedSearches: true, wideNarrow: false, singleSearchTerm: false, minDate: minDate() }

    return (
        <div>
            {userInfo.isExpired && <ExpiredSubscriptionPopup />}
            {showSubscribersOnly && <SubscribersOnly setShowSubscribersOnly={setShowSubscribersOnly} />}
            {!userInfo.verified && <NoPermissionPopup />}
            {nonReturnedCountriesPopup.show && <GenericPopup closeFunction={setNonReturnedCountriesPopup} headerText={nonReturnedCountriesPopup.headerText} bodyText={nonReturnedCountriesPopup.bodyText} context="nonReturnedCountriesPopup" />}
            {(userInfo.dailyLimitsReached.includes(Constants.VOLUME_PLUS_TYPE) || showNoPermission) && <NoPermissionLimitReachedPopup dailyLimitReached={true} searchType={Constants.VOLUME_PLUS_TYPE} />}
            {showSavedSearchDelete && <SavedSearchConfirmDelete savedSearchToDelete={savedSearchToDelete} showAllSavedSearchesFunc={() => showAllSavedSearchesFunc({ environment, setShowSavedSearchDelete, setShowSavedSearches, savedSearchDeleteFunc: savedSearchDeleteFunc, setSavedSearchesToRender })} setShowSavedSearchDelete={setShowSavedSearchDelete} environment={environment} />}
            {showSaveSearch && <SaveSearchPopup setSaveSearchNameInput={setSaveSearchNameInput} setShowSaveSearch={setShowSaveSearch} saveSearchOk={saveSearchOk} addToRefactoredPost={addToRefactoredPost} setSaveSearchSubmit={setSaveSearchSubmit} />}
            {showEditList && (<EditSearchListPopup editSearchesOk={() => editSearchesOk} />)}
            {showSavedSearches && <SavedSearchesPopup savedSearchesOk={setShowSavedSearches} showAllSavedSearchesFunc={showAllSavedSearchesFunc} savedSearchesProps={{ environment, setShowSavedSearchDelete, setShowSavedSearches, savedSearchDeleteFunc: savedSearchDeleteFunc, setSavedSearchesToRender, addToRefactoredPost, setSavedSearchToDelete }} savedSearchesToRender={savedSearchesToRender} />}

            <Tabs window={window} selected={"searchVolume+"} environment={environment} userInfo={userInfo} />
            <div class="SearchColumns">
                {userInfo && <SearchColumn refactoredPost={refactoredPost} setRefactoredPost={setRefactoredPost} environment={environment} requirementsObj={requirementsObj} isSubscriber={userInfo.isSubscriber} setShowSubscribersOnly={setShowSubscribersOnly} setShowSavedSearches={setShowSavedSearches} showAllSavedSearchesFunc={() => showAllSavedSearchesFunc({ environment, setShowSavedSearchDelete, setShowSavedSearches, savedSearchDeleteFunc: savedSearchDeleteFunc, setSavedSearchesToRender, addToRefactoredPost, resetSearchWords, setSavedSearchToDelete })} setShowEditList={setShowEditList} setShowSaveSearch={setShowSaveSearch} formSubmitted={formSubmitted} setFormSubmitted={setFormSubmitted} handleMultiSubmit={() => handleMultiSubmit({
                    environment,
                    userInfo,
                    numberOfRunSearches,
                    setShowGraphText,
                    refactoredPost,
                    chartRef,
                    barChartRef,
                    volumeChartRef,
                    areaChartRef,
                    setSearchDataObj,
                    setResponseToPostVolume,
                    setChartsLoaded,
                    setFormSubmitted,
                    setNumberOfRunSearches,
                    setChartDataObject,
                    setDateArray,
                    setVolumeArray,
                    searchType: Constants.VOLUME_PLUS_TYPE
                })} userInfo={userInfo} setShowSavedSearchDelete={setShowSavedSearchDelete} />}

                <GraphColumn chartRef={chartRef} areaChartRef={areaChartRef} barChartRef={barChartRef} volumeChartRef={volumeChartRef} countryVolumeChartRef={countryVolumeChartRef} countryVolumeTotalChartRef={countryVolumeTotalChartRef} yearlyChartRef={yearlyChartRef} yearlyPercentChartRef={yearlyPercentChartRef} chartRefPerForSearchTerm={chartRefPerForSearchTerm} barChartRefForSearchTerm={barChartRefForSearchTerm} showGraphText={showGraphText} page={Constants.VOLUME_PLUS_TYPE} barSliderChartRef={barSliderChartRef} chartDataObject={chartDataObject} searchDataObj={searchDataObj} downloadContainerElement={document.getElementById("downloadContainer")} isSubscriber={userInfo.isSubscriber} setShowSubscribersOnly={setShowSubscribersOnly} refactoredPost={refactoredPost} setSelectedYearlyChartTerm={setSelectedYearlyChartTerm} setSelectedYearlyPercentChartTerm={setSelectedYearlyPercentChartTerm} addToChartDataObject={addToChartDataObject} addToRefactoredPost={addToRefactoredPost} chartsLoaded={chartsLoaded} userInfo={userInfo} />
            </div>
            <Footer withExtras />
        </div>
    )
}

export default SearchVolumePrem