import '../../Css/Popup.css';
import HelperFunctions from '../../Helpers/HelperFunctions';
import EnquireFeedbackForm from '../EnquireFeedbackForm';

export const EnquirePopup = ({ setShowContactPopup }) => {
    const close = () => { setShowContactPopup(false) }
    var helperFunctions = new HelperFunctions;
    var environmentUrl = 'https://servershareofsearch.herokuapp.com/api/';

    const environment = helperFunctions.testEnvironment(window.location.hostname);
    environmentUrl = environment.url;

    return (
        <div className="Popup">
            <div className="GenericPopupBox Width-50per" id="PopupBox">
                <p className="Text-Center">For any additional information or enquiries please contact Frank Harrison at - <a href="mailto:frank@croftanalytics.com">frank@croftanalytics.com</a> or complete the form below.</p>
                <EnquireFeedbackForm environmentUrl={environmentUrl} />
                <button class="PopupCancelButton Bck-Red Margin-Top-1per" onClick={(e) =>
                    close()
                }>Close</button>
            </div>
        </div >
    )
};