import HelperFunctions from "../../Helpers/HelperFunctions";
import Chart from "chart.js";
import $ from 'jquery';
import excelIcon from '../../../images/excelIcon.png';
import { useEffect, useState } from "react";
import { Constants } from "../Constants";
import { coloursArray, getChartSubTitleString, getDDMMMYYYYGraphDate, removeEndCommas } from "../../Helpers/HelpersConstants";

export const CreateMultiCountryTotalVolumeGraph = ({ chartDataObject, countryVolumeTotalChartRef, searchDataObj, downloadContainerElement, isSubscriber, setShowSubscribersOnly, refactoredPost }) => {
    const isVolumePlus = (window.location.pathname === "/countrysearch");



    const updateChart = () => {

        var nameArray = chartDataObject.nameArray;
        var valuesArray = chartDataObject.volumeArray;
        if (!Array.isArray(valuesArray[0])) valuesArray = [valuesArray]
        const dateArray = chartDataObject.dateArray;
        const countriesArray = chartDataObject.countriesArray || null;
        const selectedTerm = chartDataObject.selectedTerm || null;

        var indexOfSelected = countriesArray?.indexOf(chartDataObject.selectedCountry) || 0
        if (indexOfSelected < 0) indexOfSelected = 0;

        const searchProperty = searchDataObj.property === "youtube" ? "YouTube" : "Google";
        if (!valuesArray || valuesArray.length === 0) { return null };
        const localColoursArray = coloursArray();

        var combinedValueArray = [...valuesArray];

        combinedValueArray = combinedValueArray.map(country => (
            country.map(res => (res.split(",").reduce(function (acc, val) {
                return acc + parseInt(val, 10);
            }, 0)))
        ))

        nameArray = removeEndCommas(countriesArray);


        $('#myCountryVolumeTotalChart').remove(); //.remove() this is my <canvas> element
        $('#myCountryVolumeTotalChartDiv').append(`<canvas id="myCountryVolumeTotalChart" ref=${countryVolumeTotalChartRef} />`)

        const canvas = countryVolumeTotalChartRef.current;
        var ctx = canvas.getContext('2d'); // Get the 2D rendering context

        // Ensure that the canvas element exis
        if (!canvas) return;

        // Clear the previous chart instance, if it exists
        if (canvas.chart) {
            canvas.chart.destroy();
        }
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        var concludingChart = new Chart(document.getElementById('myCountryVolumeTotalChart'), {
            type: "line",
            data: {
                labels: dateArray,
                datasets: [
                    { label: nameArray[0], data: combinedValueArray[0], fill: false, borderColor: localColoursArray[0] },
                ]
            },
            options: {
                animation: {
                    duration: 0
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                            userCallback: function (value, index, values) {
                                return value.toLocaleString();
                            },
                        },
                        scaleLabel: {
                            display: true,
                            labelString: "Search Volume",
                            fontSize: 20,
                        },
                    }]
                },
                legend: {
                    labels: {
                        // This more specific font property overrides the global property
                        fontColor: 'black',
                        fontSize: 15,
                        fontStyle: 'bold',
                    }
                },
            }
        });
        for (var i = 1; i < nameArray.length; i++) {
            concludingChart.data.datasets.push({
                label: nameArray[i],
                fill: false,
                borderColor: localColoursArray[i],
                data: combinedValueArray[i],
            });
        }

        var country = searchDataObj.country ? searchDataObj.country.toUpperCase() : null;
        var category = searchDataObj.category ? searchDataObj.category.toUpperCase() : null;
        var startdate = !searchDataObj.startDate ? null : getDDMMMYYYYGraphDate(searchDataObj.startDate)
        var enddate = !searchDataObj.endDate ? null : getDDMMMYYYYGraphDate(searchDataObj.endDate)
        var shorthands = "";
        if (searchDataObj.stateShorthand !== "" && searchDataObj.regionShorthand !== "") {
            shorthands = searchDataObj.stateName + " | " + searchDataObj.region
        }
        if (searchDataObj.regionShorthand === "" && searchDataObj.stateShorthand !== "") {
            shorthands = searchDataObj.stateName
        }

        for (var c = 0; c < dateArray.length; c++) {
            var date = dateArray[c].split(" ");
            // if (parseInt(date[1].substring(0, date[1].length - 1)) < 10) {
            // }
            dateArray[c] = date.join(" ");
            dateArray[c] = dateArray[c].replace(',', '');
            dateArray[c] = dateArray[c].replace('\u2013', '-');
        }


        document.getElementById('myCountryVolumeTotalChart').onclick = function (evt) {
            var activePoints = concludingChart.getElementsAtEvent(evt);
            const index = activePoints[0]?._index;
            if (activePoints.length) {
                var mouse_position = Chart.helpers.getRelativePosition(evt, concludingChart.chart);

                activePoints = $.grep(activePoints, function (activePoint, index) {
                    var leftX = activePoint._model.x - 5,
                        rightX = activePoint._model.x + 5,
                        topY = activePoint._model.y + 5,
                        bottomY = activePoint._model.y - 5;

                    return mouse_position.x >= leftX && mouse_position.x <= rightX && mouse_position.y >= bottomY && mouse_position.y <= topY;
                });
                const activeDataSet = parseInt(activePoints[0]._datasetIndex);
                const searchedFor = nameArray[activeDataSet].trim();
                const chartData = activePoints[0]._chart.data;
                let indexDate = chartData.labels[index];
                const monthToSearch = [];
                let yearToSearch = 2022;
                indexDate.split(" ").forEach((item, i) => {
                    if (item.length === 4 && parseInt(item) > 0) { yearToSearch = parseInt(item) }
                    if (item.length !== 3) { return }
                    if (parseInt(item) >= 0) { return }
                    monthToSearch.push(item)
                });
                if (indexDate.length < 14) {
                    window.open("https://www.google.com/search?q=" + searchedFor + " " + indexDate);
                } else {
                    window.open("https://www.google.com/search?q=" + searchedFor + " " + monthToSearch[monthToSearch.length - 1] + " " + yearToSearch);
                }

                // Uncomment the 4 lines below & delete this line, to just get Month & Year google searches. e.g. 'bob Jan 2010'
                // while(!isNaN(parseInt(indexDate.charAt(0))))
                // {
                //  indexDate = indexDate.substring(1).trim();
                // }
            }
        };

        concludingChart.update();
    }

    useEffect(() => {
        if (!chartDataObject) return null;
        setTimeout(() => {
            updateChart()
        }, 500);
    }, [chartDataObject])

    if (!chartDataObject) return null;

    const property = chartDataObject.property === "youtube" ? "YouTube" : "Google";;
    const page = chartDataObject.page;

    var chartSubTitleString = getChartSubTitleString({ refactoredPost, page: Constants.VOLUME_PLUS_TYPE, chart: "myCountryVolumeTotalChart", chartDataObject })

    const headerHoverText = ({ page }) => {
        const selectedTerm = chartDataObject.selectedTerm || null;

        switch (page) {
            case Constants.VOLUME_PLUS_TYPE:
                return (
                    <div id="graphInfoIText8" >
                        This chart shows the trend in monthly search volume totalled across the search
                        terms in each country. These data are sourced from Google Keyword Planner.
                        - Each data point is the month’s total volume of searching across the search terms
                        in the country.
                        - These data are rolled up or down by Google so it is possible to see several months
                        with the same search volume
                    </div >);
            default:
                return (
                    <div id="graphInfoIText8" >
                        This chart shows the trend in monthly search volume totalled across the search
                        terms in each country. These data are sourced from Google Keyword Planner.
                        - Each data point is the month’s total volume of searching across the search terms
                        in the country.
                        - These data are rolled up or down by Google so it is possible to see several months
                        with the same search volume
                    </div >);
        }
    }

    return (
        <div id="myCountryVolumeTotalChartDiv">
            <p class="ChartTitles ChartTitle8">{property} Trend in Total Search Volume across All Search Terms by Country</p>
            {headerHoverText({ page })}
            <p class="chartSubTitles">{chartSubTitleString}</p>
            <canvas
                id="myCountryVolumeTotalChart"
                ref={countryVolumeTotalChartRef}
                style={{ height: "auto" }}
            />
        </div>
    );
};