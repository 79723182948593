import React, { useEffect, useState } from 'react';
import HelperFunctions from '../Helpers/HelperFunctions';
import { Constants } from './Constants';
import SliderBarChart from './GraphHelpers/SliderBarChart';
import { CreateMultiAreaGraphRefactored } from './GraphHelpers/areaChart';
import { CreateMultiBarGraphRefactored } from './GraphHelpers/barChart';
import { CreateMultiVolumeGraphRefactored } from './GraphHelpers/volumeChart';
import { CreateMultiPercentGraphRefactored } from './GraphHelpers/percentageChart';
import CreateMultiYearlyGraphRefactored from './GraphHelpers/yearlyChart';
import CreateMultiYearlyPercentGraphRefactored from './GraphHelpers/yearlyPercentChart';
import { getDaysBetweenDates, getSearchPageText } from '../Helpers/HelpersConstants';
import LoadingIcon from './LoadingIcon';
import '../Css/ComponentCss/GraphColumn.css'
import { CreateMultiCountryVolumeGraph } from './GraphHelpers/countryVolumeChart';
import { CreateMultiCountryTotalVolumeGraph } from './GraphHelpers/countryVolumeTotalChart';
import { CreateMultiPercentGraphForSearchTerm } from './GraphHelpers/percentageChartForSearchTerm';
import { CreateMultiBarGraphForSearchTerm } from './GraphHelpers/barChartForSearchTerm';

const GraphColumn = ({ chartRef, areaChartRef, barChartRef, barSliderChartRef, volumeChartRef, countryVolumeChartRef, countryVolumeTotalChartRef, yearlyChartRef, yearlyPercentChartRef, chartRefPerForSearchTerm, barChartRefForSearchTerm, showGraphText, page, chartDataObject, searchDataObj, downloadContainerElement, isSubscriber, setShowSubscribersOnly, refactoredPost, setSelectedYearlyChartTerm, setSelectedYearlyPercentChartTerm, selectedYearlyChartTerm, selectedYearlyPercentChartTerm, addToChartDataObject, addToRefactoredPost, chartsLoaded, userInfo }) => {
    const moreThan3YearsAndNotVolumePlus = (getDaysBetweenDates(refactoredPost.startDate, refactoredPost.endDate) > 1095 && (page !== Constants.VOLUME_PLUS_TYPE));
    const [refactoredPostCountries, setRefactoredPostCountries] = useState()

    useEffect(() => {
        if (refactoredPost?.countries) {
            setRefactoredPostCountries(refactoredPost.countries)
        }
    }, [chartsLoaded])


    return (
        <div className="GraphColumn">
            {showGraphText && (getSearchPageText({ page, userInfo }))
            }
            <LoadingIcon />
            <div id="downloadContainer">
            </div>
            <div className="GraphContainer">
                {(chartsLoaded && addToChartDataObject && refactoredPostCountries) &&
                    <div className="GraphFilters Margin-Bottom-1per">
                        <p className="Margin-Bottom-0 Margin-Right-1per Font-Bold">Filters:</p>
                        <div className="GraphFilter Margin-Right-1per">
                            <label>Country</label>
                            <select
                                onChange={(e) => {
                                    addToChartDataObject("selectedCountry", e.target.value)
                                    addToChartDataObject("selectedTerm", null)
                                    addToRefactoredPost("country", e.target.value)
                                }} id="countryTrendsCountrySelect">
                                {chartDataObject && chartDataObject.countriesArray.map(c => {
                                    return <option key={c} value={c}>{c}</option>
                                })}
                                <option key={"All"} value="All" disabled >All</option>
                            </select>
                        </div>
                        <div className="GraphFilter">
                            <label>Search Term</label>
                            <select
                                onChange={(e) => {
                                    addToChartDataObject("selectedCountry", null)
                                    addToChartDataObject("selectedTerm", e.target.value)
                                    addToRefactoredPost("country", refactoredPostCountries.map(x => x.country).join(', '))
                                }} id="countryTrendsTermSelect">
                                <option key={"All"} value="All" disabled>All</option>
                                {refactoredPostCountries && refactoredPost.searchTerms.map(c => {
                                    return <option key={c} value={c.toLowerCase()}>{c.toLowerCase()}</option>
                                })}
                            </select>
                        </div>
                    </div>
                }
                <CreateMultiVolumeGraphRefactored volumeChartRef={volumeChartRef} chartDataObject={chartDataObject} searchDataObj={searchDataObj} downloadContainerElement={downloadContainerElement} isSubscriber={isSubscriber} setShowSubscribersOnly={setShowSubscribersOnly} refactoredPost={refactoredPost} />
                {(page === Constants.VOLUME_PLUS_TYPE && chartDataObject?.selectedTerm) && (
                    <CreateMultiPercentGraphForSearchTerm percentChartRef={chartRefPerForSearchTerm} chartDataObject={chartDataObject} searchDataObj={searchDataObj} downloadContainerElement={downloadContainerElement} isSubscriber={isSubscriber} setShowSubscribersOnly={setShowSubscribersOnly} refactoredPost={refactoredPost} />
                )}
                <CreateMultiPercentGraphRefactored percentChartRef={chartRef} chartDataObject={chartDataObject} searchDataObj={searchDataObj} downloadContainerElement={downloadContainerElement} isSubscriber={isSubscriber} setShowSubscribersOnly={setShowSubscribersOnly} refactoredPost={refactoredPost} />
                {(!chartDataObject?.selectedTerm) &&
                    <CreateMultiAreaGraphRefactored areaChartRef={areaChartRef} chartDataObject={chartDataObject} searchDataObj={searchDataObj} refactoredPost={refactoredPost} />
                }
                {(page === Constants.VOLUME_PLUS_TYPE && chartDataObject?.selectedTerm) && (
                    <CreateMultiBarGraphForSearchTerm barChartRef={barChartRefForSearchTerm} chartDataObject={chartDataObject} searchDataObj={searchDataObj} refactoredPost={refactoredPost} />
                )}
                <CreateMultiBarGraphRefactored barChartRef={barChartRef} chartDataObject={chartDataObject} searchDataObj={searchDataObj} refactoredPost={refactoredPost} />
                {page === Constants.VOLUME_PLUS_TYPE && (
                    <>
                        <CreateMultiCountryVolumeGraph countryVolumeChartRef={countryVolumeChartRef} chartDataObject={chartDataObject} searchDataObj={searchDataObj} downloadContainerElement={downloadContainerElement} isSubscriber={isSubscriber} setShowSubscribersOnly={setShowSubscribersOnly} refactoredPost={refactoredPost} />
                        <CreateMultiCountryTotalVolumeGraph countryVolumeTotalChartRef={countryVolumeTotalChartRef} chartDataObject={chartDataObject} searchDataObj={searchDataObj} downloadContainerElement={downloadContainerElement} isSubscriber={isSubscriber} setShowSubscribersOnly={setShowSubscribersOnly} refactoredPost={refactoredPost} />
                    </>
                )}

                {moreThan3YearsAndNotVolumePlus && (
                    <>
                        <CreateMultiYearlyGraphRefactored yearlyChartRef={yearlyChartRef} chartDataObject={chartDataObject} searchDataObj={searchDataObj} refactoredPost={refactoredPost} isPercentData={false} setSelectedYearlyChartTerm={setSelectedYearlyChartTerm} />
                        <CreateMultiYearlyPercentGraphRefactored yearlyPercentChartRef={yearlyPercentChartRef} chartDataObject={chartDataObject} searchDataObj={searchDataObj} refactoredPost={refactoredPost} isPercentData={true} setSelectedYearlyPercentChartTerm={setSelectedYearlyPercentChartTerm} />
                    </>
                )}
                {(page !== Constants.VOLUME_PLUS_TYPE && page !== Constants.SIMILAR_TYPE) && (
                    <SliderBarChart chartDataObject={chartDataObject} barSliderChartRef={barSliderChartRef} refactoredPost={refactoredPost} />
                )}
                {(page === Constants.VOLUME_TYPE) && (
                    <div id="dataResults">
                    </div>
                )}
            </div>
        </div >
    )
}

export default GraphColumn;