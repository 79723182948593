import HelperFunctions from "../../Helpers/HelperFunctions";
import Chart from "chart.js";
import $ from 'jquery';
import { useEffect, useState } from "react";
import { Constants } from "../Constants";
import '../../Css/GraphsCss/YearlyGraphs.css';
import { getChartSubTitleString, getDaysBetweenDates, getYearsInRangeReversed } from "../../Helpers/HelpersConstants";

const CreateMultiYearlyGraphRefactored = ({ chartDataObject, yearlyChartRef, searchDataObj, downloadContainerElement, isSubscriber, setShowSubscribersOnly, refactoredPost, setSelectedYearlyChartTerm }) => {
    const isVolumePlus = (window.location.pathname === "/countrysearch");


    useEffect(() => {
        if (!chartDataObject) return null;
        if (isVolumePlus) {
            return null;
        }

        $('#termSelect').empty();
        updateChart()
    }, [chartDataObject])

    const [dataObject, setDataObject] = useState(false)

    var weeklyDateRanges = [
        "01-07 Jan",
        "08-14 Jan",
        "15-21 Jan",
        "22-28 Jan",
        "29-04 Feb",
        "05-11 Feb",
        "12-18 Feb",
        "19-25 Feb",
        "26-04 Mar",
        "05-11 Mar",
        "12-18 Mar",
        "19-25 Mar",
        "26-01 Apr",
        "02-08 Apr",
        "09-15 Apr",
        "16-22 Apr",
        "23-29 Apr",
        "30-06 May",
        "07-13 May",
        "14-20 May",
        "21-27 May",
        "28-03 Jun",
        "04-10 Jun",
        "11-17 Jun",
        "18-24 Jun",
        "25-01 Jul",
        "02-08 Jul",
        "09-15 Jul",
        "16-22 Jul",
        "23-29 Jul",
        "30-05 Aug",
        "06-12 Aug",
        "13-19 Aug",
        "20-26 Aug",
        "27-02 Sep",
        "03-09 Sep",
        "10-16 Sep",
        "17-23 Sep",
        "24-30 Sep",
        "01-07 Oct",
        "08-14 Oct",
        "15-21 Oct",
        "22-28 Oct",
        "29-04 Nov",
        "05-11 Nov",
        "12-18 Nov",
        "19-25 Nov",
        "26-02 Dec",
        "03-09 Dec",
        "10-16 Dec",
        "17-23 Dec",
        "24-30 Dec"
    ];

    var monthlyDateRanges = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
    ];

    var weeklyOrMonthly = 52; //weekly

    // Continue the pattern for the entire year


    if (!chartDataObject) return null;


    const helperFunctions = new HelperFunctions();
    var nameArray = chartDataObject.nameArray;
    var valuesArray = chartDataObject.volumeArray;
    var dateArray = [...chartDataObject.dateArray];
    const daysBetween = getDaysBetweenDates(dateArray[0], dateArray[1]);
    if (daysBetween > 7) weeklyOrMonthly = 12 //monthly
    const property = chartDataObject.property === "youtube" ? "YouTube" : "Google";;
    const page = chartDataObject.page;

    var chartSubTitleString = getChartSubTitleString({ refactoredPost, chartDataObject })

    const headerHoverText = ({ page }) => {
        switch (page) {

            case Constants.INDEX_TYPE:
                return (
                    <div id="graphInfoIText5">
                        This chart compares the annual trends in the {property} search volume for the selected search term from January to December each year. <br />
                        Each line shows the search volume for the term for each year.
                    </div>);
            case Constants.VOLUME_TYPE:
                return (
                    <div id="graphInfoIText5">
                        This chart shows the annual trend in {property} search volume for the selected search term from January to December each year. <br />
                        Each line shows the search volume for the search term across January to December each year.
                    </div>);
            case Constants.SIMILAR_TYPE:
                return (
                    <div id="graphInfoIText5">
                        This chart compares the annual trends in the {property} search volume for the selected search term from January to December each year. <br />
                        Each line shows the search volume for the term for each year.
                    </div>);
            default:
                return (
                    <div id="graphInfoIText5">
                        This chart compares the annual trends in the {property} search volume for the selected search term from January to December each year. <br />
                        Each line shows the search volume for the term for each year.
                    </div>);
        }
    }
    const searchProperty = searchDataObj.property === "youtube" ? "YouTube" : "Google";
    const isIndexPage = window.location.pathname === "/search";

    nameArray = helperFunctions.removeEndCommas(nameArray);

    // below match with line: 314;
    var barChartPercentages = []
    valuesArray.forEach(item => {
        item.split(',').forEach((num, index) => {
            if (barChartPercentages[index]) {
                barChartPercentages[index] += num
            } else { barChartPercentages[index] = num }
        })
    })
    const maxBarChartPercentages = barChartPercentages.reduce((a, b) => a + b);
    barChartPercentages = barChartPercentages.map(num =>
        (num / maxBarChartPercentages * 100).toFixed(2)
    )
    var valueForBarYaxes = 0;


    let barChartMaxY = valueForBarYaxes;
    if ((parseInt(barChartMaxY) >= 50)) {
        if ((parseInt(barChartMaxY) + 5) % 5 === 0) {
            barChartMaxY = (parseInt(barChartMaxY) + 5);
        }
        if (((parseInt(barChartMaxY) + 4) % 5) === 0) {
            barChartMaxY = (parseInt(barChartMaxY) + 4);
        }
        if (((parseInt(barChartMaxY) + 3) % 5) === 0) {
            barChartMaxY = (parseInt(barChartMaxY) + 4);
        }
        if (((parseInt(barChartMaxY) + 2) % 5) === 0) {
            barChartMaxY = (parseInt(barChartMaxY) + 4);
        }
        if (((parseInt(barChartMaxY) + 6) % 5) === 0) {
            barChartMaxY = (parseInt(barChartMaxY) + 6);
        }
    } else {
        if ((parseInt(barChartMaxY) + 2) % 2 === 0) {
            barChartMaxY = (parseInt(barChartMaxY) + 2);
        } else { barChartMaxY = (parseInt(barChartMaxY) + 3) }
    }

    const getYearlyData = () => {
        return new Promise((resolve, reject) => {
            const startYear = parseInt(dateArray[0].split(" ").filter(i => i.length === 4).join(""));
            const endYear = parseInt(dateArray[dateArray.length - 1].split(" ").filter(i => i.length === 4).join(""));
            if (startYear === endYear || startYear + 1 === endYear) {
                reject("Years are not valid.");
            }

            const dateCounts = {};

            dateArray = dateArray.reverse();
            dateArray.forEach(date => {
                const year = parseInt(date.split(" ").filter(i => i.length === 4).join(""));
                if (dateCounts[year]) dateCounts[year] = dateCounts[year] + 1;
                else {
                    dateCounts[year] = 1;
                }
            });

            // Split into subarrays of desired sizes
            const subVolumeArray = [];

            var entriesSliceIndex = 0
            Object.entries(dateCounts).forEach((year, x) => {
                const key = year[0];
                const subarray = valuesArray.slice(entriesSliceIndex, entriesSliceIndex + year[1]);
                subVolumeArray.push(subarray);
                entriesSliceIndex += year[1];
            });

            subVolumeArray.reverse();


            const nameObjectDataByYears = subVolumeArray.map((year, x) => {
                var object = {};
                year.forEach(volumes => {
                    volumes.split(",").forEach((value, i) => {
                        if (!object[nameArray[i]]) object[nameArray[i]] = [];
                        object[nameArray[i]].push(value);
                    });
                });
                return object;
            });

            resolve({ nameObjectDataByYears });
        });
    };

    const getSelectedTermData = (selectedTerm, nameObjectDataByYears) => {
        const dataObj = []

        nameObjectDataByYears.forEach((year, x) => {
            while (year[selectedTerm].length < weeklyOrMonthly) {
                if (x !== 0) year[selectedTerm].unshift('-')
                else year[selectedTerm].push('-')
            };
            dataObj.push(year[selectedTerm])
        })
        return dataObj;
    }

    const updateChart = async (rendered) => {

        $('#myYearlyChart').remove(); //.remove() this is my <canvas> element
        $('#myYearlyChartDiv').append(`<canvas id="myYearlyChart" ref=${yearlyChartRef} />`)

        if (!rendered) {
            nameArray.forEach((term, x) => {
                $('#termSelect').append(
                    `<option value='${term}'>
                    ${term}
                </option>`)
            })
        }

        const selectedTerm = $('#termSelect').val();

        setSelectedYearlyChartTerm(prev => {
            if (prev === selectedTerm) return ` ${selectedTerm} `
            else return selectedTerm
        });

        var selectedTermData;

        const largeDataObject = await getYearlyData();
        setDataObject(largeDataObject);
        selectedTermData = getSelectedTermData(selectedTerm, largeDataObject.nameObjectDataByYears)

        const yearsInRange = getYearsInRangeReversed(refactoredPost.startDate, refactoredPost.endDate);


        const headerLabels = selectedTermData.map((year, x) => {
            return (yearsInRange[x])
        })


        var concludingChart = new Chart(document.getElementById('myYearlyChart'), {
            type: "line",
            data: {
                labels: weeklyOrMonthly === 12 ? monthlyDateRanges : weeklyDateRanges,
                datasets: [
                    { label: headerLabels[0], data: selectedTermData[0], fill: false, borderColor: helperFunctions.coloursArray()[0] },
                ]
            },
            options: {
                animation: {
                    duration: 0
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                        },
                        scaleLabel: {
                            display: true,
                            labelString: `Trend in Search Volume`,
                            fontSize: 20
                        },
                    }]
                },
                legend: {
                    labels: {
                        // This more specific font property overrides the global property
                        fontColor: 'black',
                        fontSize: 15,
                        fontStyle: 'bold',
                    }
                },
            }
        })
        for (var i = 1; i < selectedTermData.length; i++) {
            concludingChart.data.datasets.push({
                label: headerLabels[i],
                fill: false,
                borderColor: helperFunctions.coloursArray()[i],
                data: selectedTermData[i],
            });
        }

        concludingChart.update();

    }

    return (
        <div id="myYearlyChartDiv">
            <p class="ChartTitles ChartTitle5">{property} Annual Trend in Search Volume for a Search Term</p>
            {headerHoverText({ page })}
            <p class="chartSubTitles">{chartSubTitleString}</p>
            <select className="SelectYearlyTerm" id="termSelect" onChange={(e) => updateChart(e)}>
            </select>
            <canvas
                id="myYearlyChart"
                ref={yearlyChartRef}
                style={{ height: "auto" }}
            />
        </div>
    )
}

export default CreateMultiYearlyGraphRefactored;