import $ from 'jquery';
import { useEffect, useState } from 'react';
import HelperFunctions from '../../Helpers/HelperFunctions';

export const EditReportPopup = ({ reportData, setEditing, selectedReportData, setReportData, environmentUrl }) => {
  var helperFunctions = new HelperFunctions;
  var selectedReportDataId = selectedReportData.reportId;

  useEffect((e) => {
    helperFunctions.getCountries(e, environmentUrl);
    helperFunctions.getCategories(e, environmentUrl);
    setTimeout(function () {
      fillInputs(e)
    }, 500);
  }, []);

  const [disableCategories, setDisableCategories] = useState(false);

  function fillInputs() {
    let elementEditReportName = document.getElementById('editReportName');
    elementEditReportName.value = selectedReportData.reportName;

    let elementEditReportSearchTerms = document.getElementById('editReportSearchTerms');
    elementEditReportSearchTerms.value = selectedReportData.searchTerms;

    // let elementEditReportSearchType = document.getElementById('editReportSearchType');
    // elementEditReportSearchType.value = selectedReportData.searchType;
    // searchTypeChanged(selectedReportData.searchType);

    let elementCountriesId = document.getElementById('editReportCountriesId');
    if (selectedReportData.searchCountryShorthand !== 'undefined' && selectedReportData.searchCountryShorthand !== undefined && selectedReportData.searchCountryShorthand !== null) {
      elementCountriesId.value = selectedReportData.searchCountryShorthand
    }

    // let elementCategories = document.getElementById('editReportCategories');
    // elementCategories.value = selectedReportData.searchCategoryId;

    let elementSearchPeriod = document.getElementById('searchPeriod');
    elementSearchPeriod.value = selectedReportData.searchPeriod;

    // let elementReportFrequency = document.getElementById('reportFrequency');
    // elementReportFrequency.value = selectedReportData.reportFrequency;
  }

  function searchTypeChanged(type) {
    if (type === "VOLUME") {
      var selectobject = document.getElementById("searchPeriod");
      var selectSearchPropertyObject = document.getElementById("editReportSearchProperty");
      // for (var i = 0; i < selectobject.length; i++) {
      //   if (selectobject.options[i].value == 'LAST_WEEK' || selectobject.options[i].value == 'LAST_MONTH') {
      //     selectobject.remove(i);
      //     i--;
      //   }
      // }
      // for (var i = 0; i < selectSearchPropertyObject.length; i++) {
      //   if (selectSearchPropertyObject.options[i].value == 'youtube')
      //     selectSearchPropertyObject.remove(i);
      // }
      setDisableCategories(true);
    } else {
      var selectobject = document.getElementById("searchPeriod");
      var selectSearchPropertyObject = document.getElementById("editReportSearchProperty");
      var containsLastWeek = false;
      var containsYoutube = false
      for (var i = 0; i < selectobject.length; i++) {
        if (selectobject.options[i].value == 'LAST_WEEK') containsLastWeek = true;
      }
      for (var i = 0; i < selectSearchPropertyObject.length; i++) {
        if (selectSearchPropertyObject.options[i].value == "youtube") containsYoutube = true;
      }
      if (!containsLastWeek) {
        var x = document.getElementById("searchPeriod");
        var option = document.createElement("option");
        option.value = "LAST_WEEK";
        option.text = "Last week";
        x.add(option, x[0]);
      }
      if (!containsYoutube) {
        var x = document.getElementById("editReportSearchProperty");
        var option = document.createElement("option");
        option.value = "youtube";
        option.text = "YouTube";
        x.add(option, x[0]);
      }
      setDisableCategories(false);
    }
  }

  return (
    <div className="EditReportPopup">
      <div id="editReportPopupBox">
        <h3 id="editReportHeaderText"> Edit Report </h3>
        <div id="myReport">

          <label id="editReportNameLabel" for="editReportName">Name:</label>
          <input
            id="editReportName"
            type="text"
          />

          {/* <label id="editReportSearchTypeLabel" for="editReportSearchType">Type:</label>
          <select id="editReportSearchType" onChange={(e) => searchTypeChanged(e.target.value)}>
            <option value="INDEX">INDEX</option>
            <option value="VOLUME">VOLUME</option>
          </select> */}

          {/* <label
            id="editReportSearchPropertyLabel"
            for="editReportSearchProperty"
            style={{ textDecoration: `${disableCategories ? 'line-through' : ''}` }}
          >
            Search Property:
          </label>
          <select id="editReportSearchProperty">
            <option value="web">Google</option>
            <option value="youtube">YouTube</option>
          </select> */}

          <label id="editReportSearchTermsLabel" for="editReportSearchTerms">Search Terms:</label>
          <input
            id="editReportSearchTerms"
            type="text"
          />

          <label id="countriesIdLabel" for="editReportCountriesId">Country:</label>
          <select name="countries" id="editReportCountriesId">
            <option value="">Worldwide</option>
          </select>

          {/* <label
            id="categoriesLabel"
            for="editReportCategories"
            style={{ textDecoration: `${disableCategories ? 'line-through' : ''}` }}
          >
            Category:
          </label>
          <select
            name="categories"
            id="editReportCategories"
            disabled={disableCategories}
            style={{ textDecoration: `${disableCategories ? 'line-through' : ''}` }}
          >
            <option value="0" style={{ textDecoration: `${disableCategories && 'line-through'}` }}>All</option>
          </select> */}

          <label id="searchPeriodLabel" for="searchPeriod">Period:</label>
          <select name="searchPeriod" id="searchPeriod">
            <option value="LAST_THREE_MONTHS">Last 3 months</option>
            <option value="LAST_SIX_MONTHS">Last 6 months</option>
            <option value="LAST_TWELVE_MONTHS">Last 12 months</option>
            <option value="LAST_TWENTY_FOUR_MONTHS">Last 24 months</option>
            <option value="LAST_THIRTY_SIX_MONTHS">Last 36 months</option>
            <option value="LAST_FOURTY_EIGHT_MONTHS">Last 48 months</option>
          </select>

          {/* <label id="reportFrequencyLabel" for="reportFrequency">Frequency:</label>
          <select name="reportFrequency" id="reportFrequency">
            <option value="WEEKLY">WEEKLY</option>
            <option value="MONTHLY">MONTHLY</option>
          </select> */}

        </div>
        <div className="EditReportSubmitButtons">
          <button id="cancelOk" onClick={(e) => dataToSave(e, reportData, setReportData, setEditing, environmentUrl, selectedReportDataId)}>Save</button>
          <button id="cancelOk" onClick={(e) => setEditing(false)}>Close</button>
        </div>
      </div>
    </div >
  )
};

async function dataToSave(e, reportData, setReportData, setEditing, environmentUrl, selectedReportDataId) {
  const id = (sessionStorage.getItem("id"));
  const email = sessionStorage.getItem("user");

  const reportName = $("#editReportName").val();
  const reportCreatedEdited = new Date().toISOString().slice(0, 19).replace('T', ' ');
  const searchTerms = $("#editReportSearchTerms").val();
  const searchType = "VOLUME";
  const searchCountryShorthand = $('#editReportCountriesId').find(":selected").val();
  const searchCountry = $('#editReportCountriesId').find(":selected").text();
  const searchCategoryId = (searchType === "VOLUME" ? null : parseInt($('#editReportCategories').find(":selected").val()));
  const searchCategory = (searchType === "VOLUME" ? "-" : $('#editReportCategories').find(":selected").text());
  const searchPeriod = $('#searchPeriod').find(":selected").val();
  const reportFrequency = "MONTHLY";
  const property = "web";

  if (reportName.length < 1) {
    $("#editReportNameLabel").css({ 'font-weight': 'bold' });
    alert("Report name cannot be empty.");
    return;
  } else if (searchTerms.length < 1) {
    $('#editReportSearchTermsLabel').css({ 'font-weight': 'bold' });
    alert("Search terms cannot be empty.");
    return;
  }



  const selectedReportData = {
    reportName,
    reportCreatedEdited,
    searchTerms,
    searchType,
    searchCountry,
    searchCountryShorthand,
    searchCategory,
    searchCategoryId,
    searchPeriod,
    reportFrequency,
    property
  }

  const newReportData = reportData.map(report => {
    if (report.reportId === selectedReportDataId) {
      report.reportName = reportName
      report.reportCreatedEdited = reportCreatedEdited
      report.searchTerms = searchTerms
      report.searchType = searchType
      report.searchCountry = searchCountry
      report.searchCountryShorthand = searchCountryShorthand
      report.searchCategory = searchCategory
      report.searchCategoryId = searchCategoryId
      report.searchPeriod = searchPeriod
      report.reportFrequency = reportFrequency
      report.property = property
    }
    return report;
  })

  setReportData(newReportData)

  const response = await fetch(environmentUrl + 'reports/EditReport', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ post: { email: email, id: id, report: selectedReportData, reportId: selectedReportDataId } }),
  });
  const addedResponse = await response.json();

  if (addedResponse === false) {
    alert("Error: Report not updated.");
    window.location.reload();
  }

  setEditing(false);
}
