import $ from 'jquery';
import CompareDates from '../CompareDates';
import PptxGenJS from "pptxgenjs";
import powerPointIcon from "../../images/powerPointIcon.png";
import { Constants } from '../Components/Constants';
import { getDaysBetweenDates } from './HelpersConstants';
export default class HelperFunctions {
  coloursArray() {
    return ['#000000', '#FFB399', '#FF33FF', '#ff6600', '#00B3E6',
      '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
      '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A',
      '#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
      '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC',
      '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
      '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680',
      '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
      '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3',
      '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF'];
  }
  months() {
    return ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  }
  longMonths() {
    return ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"];
  }

  parseDate(str) {
    var mdy = str.split('/');
    return new Date(mdy[2], mdy[0] - 1, mdy[1]);
  }

  datediff(first, second) {
    // Take the difference between the dates and divide by milliseconds per day.
    // Round to nearest whole number to deal with DST.
    return Math.round((second - first) / (1000 * 60 * 60 * 24));
  }

  removeEndCommas(searchedItem) {
    if (searchedItem) {
      while (searchedItem[searchedItem.length - 1] === ",") {
        searchedItem = searchedItem.slice(0, -1);
      }
    }
    return (searchedItem);
  }

  testEnvironment(hostname) {
    if (hostname === "localhost") {
      return { url: 'http://localhost:5000/api/', localhost: true };
    } else {
      return { url: 'https://servershareofsearch.herokuapp.com/api/', localhost: false };
    }
  }

  getClientInfo(addToRefactoredPost) {
    $.getJSON('https://ipapi.co/json/', function (data) {
      addToRefactoredPost('ip', data.ip);
      addToRefactoredPost('countryName', data.country_name)
      addToRefactoredPost('city', data.city)
    });
  }

  getStartDateStartValue() {
    const today = new Date()
    today.setFullYear(today.getFullYear() - 4);
    const month = (today.getMonth() + 1).toString().length === 1 ? `0${today.getMonth() + 1}` : today.getMonth() + 1
    const day = (today.getDate()).toString().length === 1 ? `0${today.getDate()}` : today.getDate()
    // ${today.toLocaleString("default", { month: "2-digit" })}
    return `${today.getFullYear()}-${month}-${day}`
  }

  getMMMYYYYGraphDate(date) {
    var dateArr = []
    if (date.includes("/")) {
      dateArr = date.split("/");
    } else {
      dateArr = date.split("-");
    }
    dateArr[1] = this.months()[dateArr[1] - 1];
    dateArr.pop();

    return dateArr.reverse().join(" ")
  }

  getDDMMMYYYYGraphDate(date) {
    var dateArr = []
    if (date.includes("/")) {
      dateArr = date.split("/");
    } else {
      dateArr = date.split("-");
    }
    dateArr[1] = this.months()[dateArr[1] - 1];

    return dateArr.reverse().join(" ")
  }

  getYYYYMMDDFromDate(date) {
    const yyyy = date.getFullYear();
    let mm = date.getMonth() + 1; // Months start at 0!
    let dd = date.getDate();

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;

    return (yyyy + '-' + mm + '-' + dd);
  }

  propertyFormatText(text) {
    if (text === 'web') {
      return 'Google'
    }
    return 'YouTube'
  }

  setStickyHeader(header, sticky, setScrollDown) {
    if (window.pageYOffset > sticky) {
      header.classList.add("StickyHeader");
    } else {
      header.classList.remove("StickyHeader");
    }
    setScrollDown(true);
  }

  tabHandler(url) {
    if (url === ('/countrysearch')) {
      $("#multiQueryTab").css("background-color", "rgb(84,155,53)");
      $("#multiQueryTab").css("color", "white");
      $("#multiQueryTab").css("border-bottom", "thin solid darkgreen");
      $("#multiQueryTab").css("border-left", "thin solid darkgreen");
      $("#multiQueryTab").css("border-right", "thin solid darkgreen");
      $("#multiQueryTab").css("cursor", "auto");
    }
    if (url === ('/volumesearch')) {
      $("#volumeSearchTab").css("background-color", "rgb(84,155,53)");
      $("#volumeSearchTab").css("color", "white");
      $("#volumeSearchTab").css("border-bottom", "thin solid darkgreen");
      $("#volumeSearchTab").css("border-left", "thin solid darkgreen");
      $("#volumeSearchTab").css("border-right", "thin solid darkgreen");
      $("#volumeSearchTab").css("cursor", "auto");
    }
    if (url === ('/similarsearch')) {
      $("#similarSearchTab").css("background-color", "rgb(84,155,53)");
      $("#similarSearchTab").css("color", "white");
      $("#similarSearchTab").css("border-bottom", "thin solid darkgreen");
      $("#similarSearchTab").css("border-left", "thin solid darkgreen");
      $("#similarSearchTab").css("border-right", "thin solid darkgreen");
      $("#similarSearchTab").css("cursor", "auto");
    }
  }

  getUserAccountInfo() {
    return ({ email: sessionStorage.getItem("user"), accountId: parseInt(sessionStorage.getItem("id")) / 500 })
  }

  searchTermsTooLong(array, text) {
    let count = 0;
    array.forEach((item, i) => {
      if (item.length > 104) {
        count += 1;
        $("#inputErrorText").text(text)
        $("#criteriaSubmitButton").prop("disabled", true);
        $("#plusButton").prop("disabled", true);
        $("#criteriaSubmitButton").css({ 'background-color': 'grey', 'cursor': 'default' });
      } else {
        if (count === 0) {
          $("#criteriaSubmitButton").prop("disabled", false);
          $("#plusButton").prop("disabled", false);
          $("#inputErrorText").empty();
          $("#criteriaSubmitButton").css({ 'background-color': 'rgb(84,130,53)', 'cursor': 'pointer' });
        }
      }
    });
  }


  async getCountries(e, environmentUrl) {
    const response = await fetch(environmentUrl + 'countriesjson');
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    var countriesToIdArrays = body.map
    countriesToIdArrays.forEach((item) => {
      $("#countriesId").append(
        '<option value=' +
        item[1] + ' id="countryList">' +
        item[0] + '</option><br />');
      $("#editReportCountriesId").append(
        '<option value=' +
        item[1] + ' id="countryList">' +
        item[0] + '</option><br />');
    });
  }

  async getCategories(e, environmentUrl) {
    const response = await fetch(environmentUrl + 'categoriesjson');
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    var categoriesToIdArrays = body.map
    categoriesToIdArrays.forEach((item) => {
      if (item[0][item[0].length - 1] === item[0][item[0].length - 1].toUpperCase()) {
        $("#categories").append('<optgroup label="-----">');
        $("#categories").append('<option value=' + item[1] + ' id="categoriesListHeader">' + item[0] + '</option><br />');
        $("#editReportCategories").append('<optgroup label="-----">');
        $("#editReportCategories").append('<option value=' + item[1] + ' id="categoriesListHeader">' + item[0] + '</option><br />');
      } else {
        $("#categories").append(
          '<option value=' +
          item[1] + ' id="categoriesList">' +
          item[0] + '</option><br />');
        $("#editReportCategories").append(
          '<option value=' +
          item[1] + ' id="categoriesList">' +
          item[0] + '</option><br />');
      }
    });
  }

  async getRegions(environment) {
    var country = $("#countriesId option:selected").text().trim();
    if (country !== "Worldwide") {
      const response = await fetch(environment.url + 'countriesandregionjson', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ post: country }),
      });
      const body = await response.json();
      $("#statesAndRegionBox").css("display", "block");
      $("#statesId").css("display", "block");
      if (response.status !== 200) throw Error(body.message);
      body.map.forEach((item, i) => {
        if (item.length > 0) {
          this.removeOptionsButOne(document.getElementById("regionsId"));
          $("#regionsId").css("display", "block");
          var thisState = $("#statesId option:selected").text();
          if (thisState === "Not selected") {
            $("#regionsId").css("display", "none");
          }
          item.forEach((state2, o) => {
            if (state2[0] == thisState) {
              if (typeof state2[2] !== "undefined") {
                state2[2].forEach((region, p) => {
                  var regionOpt = document.createElement('option');
                  regionOpt.value = region[1];
                  regionOpt.innerHTML = region[0];
                  regionOpt.setAttribute("id", `region${i}`);
                  $("#regionsId").append(
                    regionOpt
                  );
                });
              } else {
                $("#regionsId").css("display", "none");
              }
            }
          });
        } else {
          $("#statesAndRegionBox").css("display", "none");
        };
      });
    }
  }

  removeOptionsButOne(selectElement) {
    var i, L = selectElement.options.length - 1;
    for (i = L; i >= 1; i--) {
      selectElement.remove(i);
    }
  }

  createPowerpointDownloadButtons(
    {
      refactoredPost,
      downloadContainerElement,
      page,
      isSubscriber,
      setShowSubscribersOnly,
      selectedYearlyChartTerm,
      selectedYearlyPercentChartTerm
    }
  ) {
    if (refactoredPost.searchTerms.length <= 0 || !document.getElementById("myVolumeChart")) return;
    const isIndexPage = page === Constants.INDEX_TYPE;
    if (document.getElementById("powerpointDownloadbutton") !== null) {
      document.getElementById("powerpointDownloadbutton").remove()
    }
    var button = document.createElement("button");
    button.id = "powerpointDownloadbutton"
    button.innerHTML = `<div id="downloadButtonDiv"><img id='downloadIcon' style="margin-right: 10%" src=${powerPointIcon} /><b>EXPORT CHARTS</b></div>`
    button.onclick = function () {
      if (!isSubscriber) {
        setShowSubscribersOnly(true);
        return;
      }
      this.powerpointDownload({ refactoredPost, isIndexPage, selectedYearlyChartTerm, selectedYearlyPercentChartTerm });
    }.bind(this);
    downloadContainerElement.appendChild(button);
  }

  powerpointDownload({ refactoredPost, isIndexPage, selectedYearlyChartTerm, selectedYearlyPercentChartTerm }) {
    const moreThan3Years = getDaysBetweenDates(refactoredPost.startDate, refactoredPost.endDate) > 1095

    var volumeGraph = document.getElementById("myVolumeChart").getContext("2d");
    var volumeCanvas = volumeGraph.canvas;
    var volumeDataURL = volumeCanvas.toDataURL();

    var myGraph = document.getElementById("myChart").getContext("2d");
    var myCanvas = myGraph.canvas;
    var myDataURL = myCanvas.toDataURL();

    var areaGraph = document.getElementById("myAreaChart").getContext("2d");
    var areaCanvas = areaGraph.canvas;
    var areaDataURL = areaCanvas.toDataURL();

    var barGraph = document.getElementById("myBarChart").getContext("2d");
    var barCanvas = barGraph.canvas;
    var barDataURL = barCanvas.toDataURL();

    if (moreThan3Years) {
      var yearlyGraph = document.getElementById("myYearlyChart").getContext("2d");
      var yearlyCanvas = yearlyGraph.canvas;
      var yearlyDataURL = yearlyCanvas.toDataURL();

      var yearlyPercentGraph = document.getElementById("myYearlyPercentChart").getContext("2d");
      var yearlyPercentCanvas = yearlyPercentGraph.canvas;
      var yearlyPercentDataURL = yearlyPercentCanvas.toDataURL();
    }

    var pptx = new PptxGenJS();
    var slide1 = pptx.addNewSlide();
    var slide2 = pptx.addNewSlide();
    var slide3 = pptx.addNewSlide();
    var slide4 = pptx.addNewSlide();
    if (moreThan3Years) {
      var slide5 = pptx.addNewSlide();
      var slide6 = pptx.addNewSlide();
    }

    var country = refactoredPost.country.toUpperCase();
    var category = refactoredPost.category.toUpperCase();
    var startdate = refactoredPost.startDate
    var enddate = refactoredPost.endDate
    var shorthands = "";
    if (refactoredPost.stateShorthand !== "" && refactoredPost.regionShorthand !== "") {
      shorthands = refactoredPost.state + " | " + refactoredPost.region
    }
    if (refactoredPost.regionShorthand === "" && refactoredPost.stateShorthand !== "") {
      shorthands = refactoredPost.state
    }
    var searchInfoText = country +
      (shorthands !== "" ? ` ( ${shorthands} )` : "") +
      " | " + category +
      " | " + this.getDDMMMYYYYGraphDate(startdate) +
      " - " + this.getDDMMMYYYYGraphDate(enddate);
    const property = this.propertyFormatText(refactoredPost.property);

    var footerSourceText = "Source: shareofsearching.com, Google"

    slide1.addText(`${property} Trend in Search Volume ${isIndexPage ? 'Index' : ''}`, { y: 0, w: "100%", h: 1, align: "center", bold: true, fontSize: 16 });
    slide1.addText(searchInfoText, { y: 0.3, w: "100%", h: 1, align: "center", fontSize: 10, bold: false });
    slide1.addText(footerSourceText, { x: '10%', y: "85%", w: 5, h: 1, fontSize: 10, bold: false });
    slide1.addImage({ data: volumeDataURL, x: 1, y: 1, w: 8, h: 4 });

    slide2.addText(`${property} Trend in % Share of Search`, { y: 0, w: "100%", h: 1, align: "center", bold: true, fontSize: 16 });
    slide2.addText(searchInfoText, { y: 0.3, w: "100%", h: 1, align: "center", fontSize: 10, bold: false });
    slide2.addText(footerSourceText, { x: '10%', y: "85%", w: 5, h: 1, fontSize: 10, bold: false });
    slide2.addImage({ data: myDataURL, x: 1, y: 1, w: 8, h: 4 });

    slide3.addText(`${property} Trend in % Share of Search`, { y: 0, w: "100%", h: 1, align: "center", bold: true, fontSize: 16 });
    slide3.addText(searchInfoText, { y: 0.3, w: "100%", h: 1, align: "center", fontSize: 10, bold: false });
    slide3.addText(footerSourceText, { x: '10%', y: "85%", w: 5, h: 1, fontSize: 10, bold: false });
    slide3.addImage({ data: areaDataURL, x: 1, y: 1, w: 8, h: 4 });

    slide4.addText(`${property}Total % Share of Search`, { y: 0, w: "100%", h: 1, align: "center", bold: true, fontSize: 16 });
    slide4.addText(searchInfoText, { y: 0.3, w: "100%", h: 1, align: "center", fontSize: 10, bold: false });
    slide4.addText(footerSourceText, { x: '10%', y: "85%", w: 5, h: 1, fontSize: 10, bold: false });
    slide4.addImage({ data: barDataURL, x: 1, y: 1, w: 8, h: 4 });

    if (moreThan3Years) {
      slide5.addText(`${property} Trend in Search Volume ${isIndexPage ? 'Index' : ''}`, { y: 0, w: "100%", h: 1, align: "center", bold: true, fontSize: 16 });
      slide5.addText(searchInfoText, { y: 0.3, w: "100%", h: 1, align: "center", fontSize: 10, bold: false });
      slide5.addText(selectedYearlyChartTerm, { y: 0.6, w: "100%", h: 1, align: "center", fontSize: 12, bold: true });
      slide5.addText(footerSourceText, { x: '10%', y: "86%", w: 5, h: 1, fontSize: 10, bold: false });
      slide5.addImage({ data: yearlyDataURL, x: 1, y: 1.2, w: 8, h: 4 });

      slide6.addText(`${property} Trend in % Share of Search`, { y: 0, w: "100%", h: 1, align: "center", bold: true, fontSize: 16 });
      slide6.addText(searchInfoText, { y: 0.3, w: "100%", h: 1, align: "center", fontSize: 10, bold: false });
      slide6.addText(selectedYearlyPercentChartTerm, { y: 0.6, w: "100%", h: 1, align: "center", fontSize: 12, bold: true });
      slide6.addText(footerSourceText, { x: '10%', y: "86%", w: 5, h: 1, fontSize: 10, bold: false });
      slide6.addImage({ data: yearlyPercentDataURL, x: 1, y: 1.2, w: 8, h: 4 });
    }

    pptx.writeFile({ fileName: "ShareOfSearchingCharts.pptx" });
  }

  resetCharts({ refactoredPost, chartRef, barChartRef, barSliderChartRef, volumeChartRef, areaChartRef, page }) {
    const isVolumePage = page === Constants.VOLUME_TYPE;
    var shorthands = "";
    if (refactoredPost.stateShorthand !== "" && refactoredPost.regionShorthand !== "") {
      shorthands = refactoredPost.state + " | " + refactoredPost.region
    }
    if (refactoredPost.regionShorthand === "" && refactoredPost.stateShorthand !== "") {
      shorthands = refactoredPost.state
    }

    $('#downloadContainer').empty(); //.remove() this is my <canvas> element
  }

  getChartHoverText(page) {
    switch (page) {
      case Constants.INDEX_TYPE:
        return ({
          1: `<div id="graphInfoIText1">
            This chart shows the trend in relative search volume (shown as an index) for the search terms. These data are sourced from Google Trends. <br />
            - The data are shown as an index, with the peak of 100 being for the search term with the highest search volume during the period - all other data points are volumes indexed relative to the search term volume shown as 100. <br />
            - The Google methodology for these data is to use a random sample of all search queries. This means the data can vary from one search query to another, and the variance will be greater for low volume terms. <br />
            - Click on data dots to view Google search results for the term and date (helps to explain search spikes)
          </div>`,
          2: `<div id="graphInfoIText2">
            This line chart shows the trend in % share of search for each search term. <br />
            - Each data point is the % share of search for the search term expressed as a % of total volume of search across all the search terms. <br />
            - The % share of search numbers add up to 100% when totalled across the search terms for a displayed date
          </div>`,
          3: `<div id="graphInfoIText3">
            This stacked area chart shows the trend in % share of search for each search term. <br />
            - Each data point is the % share of search for the search term expressed as a % of total volume of search across all the search terms.  <br />
            - The % share of search numbers add up to 100% when totalled across the search terms.
          </div>`,
          4: `<div id="graphInfoIText4">
            This chart shows the % share search for each search term as a % of total search volume across all the terms for the whole of the defined period. <br />
            - The % share of search numbers add up to 100% across the search terms.
          </div>`,
        });
      case Constants.VOLUME_TYPE:
        return ({
          1: `<div id="graphInfoIText1" >
            This charts shows the trend in monthly search volume for each search term.These data are sourced from Google Keyword Planner. <br />
            - Each data point is the total volume of search for the search term for the month. <br />
            - These data are rolled up or down by Google so it is possible to see several months with the same search volume.
          </div >`,
          2: `<div id="graphInfoIText2">
            This line chart shows the trend in monthly % share of search volume for each search term. <br />
            - Each data point is the % share of search for the search term expressed as a % of total volume of search across all the search terms. <br />
            - The % share of search numbers add up to 100 % when totalled across the search terms for a displayed month.
          </div>`,
          3: `<div id="graphInfoIText3">
            This stacked area chart shows the trend in monthly % share of search volume for each search term. <br />
            - Each data point is the % share of search for the search term expressed as a % of total volume of search across all the search terms. <br />
            - The % share of search numbers add up to 100 % when totalled across the search terms for each month.
          </div>`,
          4: `<div id="graphInfoIText4">
            This chart shows the % share search for each search term as a % of total search volume across all the terms for the whole of the defined period. <br />
            - The % share of search numbers add up to 100 % across the search terms.
          </div>`,
        });
      case Constants.SIMILAR_TYPE:
        return ({
          1: `<div id="graphInfoIText1" >
            This chart shows the trend in relative search volume(shown as an index) for the search terms.These data are sourced from Google Trends. <br />
            - The data are shown as an index, with the peak of 100 being for the search term with the highest search volume during the period - all other data points are volumes indexed relative to the search term volume shown as 100. <br />
            - The Google methodology for these data is to use a random sample of all search queries.This means the data can vary from one search query to another, and the variance will be greater for low volume terms. <br />
            - Click on data dots to view Google search results for the term and date(helps to explain search spikes)
          </div>`,
          2: `<div id="graphInfoIText2">
            This line chart shows the trend in % share of search for each search term. <br />
            - Each data point is the % share of search for the search term expressed as a % of total volume of search across all the search terms. <br />
            - The % share of search numbers add up to 100 % when totalled across the search terms for a displayed date.
          </div>`,
          3: `<div id="graphInfoIText3"> This stacked area chart shows the trend in % share of search for each search term. <br />
            - Each data point is the % share of search for the search term expressed as a % of total volume of search across all the search terms. <br />
            - The % share of search numbers add up to 100 % when totalled across the search terms.
          </div>`,
          4: `<div id="graphInfoIText4">
            This chart shows the % share search for each search term as a % of total search volume across all the terms for the whole of the defined period. <br />
            - The % share of search numbers add up to 100 % across the search terms.
          </div>`,
        })
      default:
        return ({ 1: '', 2: '', 3: '', 4: '' })
    }
  }

}
