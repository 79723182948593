import PropTypes from 'prop-types';

export const EditSearchListPopup = ({editSearchesOk}) => (
  <div className="EditSearchListPopup">
    <div id="editSearchListBox">
      <p id="editSearchListText"><b>Edit Search List</b></p>
      <p id="editSearchListText">Change position, delete, or edit search terms. Hit enter to save edits.</p>
      <div id="searchesContainer">
      </div>
      <button id="editSaveOk" onClick={(e) => editSearchesOk(e)}>Save</button>
    </div>
  </div>
)
