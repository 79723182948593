import React, { Component } from 'react';
import './Css/CreateAccount.css';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import logo from '../images/ShareOfSearching.png';
import HelperFunctions from './Helpers/HelperFunctions';
import { useState, useEffect } from 'react';
import { EmailVerificationPopup } from './Components/Popups/EmailVerificationPopup';
import { PrivacyPolicyPopup } from './Components/Popups/PrivacyPolicyPopup';


const CreateAccount = () => {

  const helperFunctions = new HelperFunctions;
  const [environmentUrl, setEnvironmentUrl] = useState('https://servershareofsearch.herokuapp.com/api/');
  const [isLocal, setIsLocal] = useState(false);
  const [accountDetails, setAccountDetails] = useState({ tAndCAgree: false });
  const [blackList, setBlackList] = useState(["+", "sdvgdf", "adfskj", "jeoce", "omeie"]);
  const [showEmailVerificationPopup, setShowEmailVerificationPopup] = useState(false);
  const [showPrivacyPolicyPopup, setShowPrivacyPolicyPopup] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    const environment = helperFunctions.testEnvironment(window.location.hostname);
    setEnvironmentUrl(environment.url);
    setIsLocal(environment.localhost);
    getCountries();
    $('head').append(
      "<script> function gtag_report_conversion(url) { var callback = function () { if (typeof(url) != 'undefined') { window.location = url; } }; gtag('event', 'conversion', { 'send_to': 'AW-1037309005/Fqc9CPLauJADEM2o0O4D', 'event_callback': callback }); return false; } </script>");
    document.title = "Share Of Searching";
  }, []);

  const addToAccountDetails = async (field, value) => {
    setAccountDetails(prevState => ({ ...prevState, [field]: value }));
  }

  const addToFormErrors = async (field, value) => {
    setFormErrors(prevState => ({ ...prevState, [field]: value }));
  }

  const getCountries = async e => {
    const response = await fetch(environmentUrl + 'countriesjson');
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    var countriesToIdArrays = body.map
    countriesToIdArrays.forEach((item) => {
      $("#countriesList").append(
        '<option value=' +
        item[1] + ' id="countryList">' +
        item[0] + '</option><br />');
    });
  }

  const makeid = (length) => {
    var result = [];
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result.push(characters.charAt(Math.floor(Math.random() *
        charactersLength)));
    }
    return result.join('');
  }

  const sendVerificationEmail = async (e) => {
    var randomNumber = Math.ceil(Math.random() * 10) + 3;
    var randomCode = makeid(randomNumber);
    var accountEmail = accountDetails.email
    var accountName = (accountDetails.firstName + " " + accountDetails.lastName);
    var accountInfo = { accountEmail, randomCode, accountName };

    const setLinkResponse = await fetch(environmentUrl + 'setemailverificationlink', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ post: accountInfo }),
    });
    const setLinkResponseJson = await setLinkResponse.json();
    if (setLinkResponseJson === true || setLinkResponseJson === "true") {
    } else {
      alert("Error: Verification email was unable to be sent.")
    }

    const emailVerificationResponse = await fetch(environmentUrl + 'sendVerificationEmail', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ post: accountInfo }),
    });
    const emailVerificationResponseJson = await emailVerificationResponse.json();
    if (emailVerificationResponseJson === true || emailVerificationResponseJson === "true") {
    } else {
      alert("Error: Verification email was unable to be sent.");
    }
  }

  const checkAccountDetails = () => {

    var allowed = true;

    if (!accountDetails.firstName) {
      addToFormErrors("firstName", true);
      allowed = false;
    } else {
      addToFormErrors("firstName", false);
    }
    if (!accountDetails.lastName) {
      addToFormErrors("lastName", true)
      allowed = false;
    } else {
      addToFormErrors("lastName", false);
    }
    if (!accountDetails.email) {
      addToFormErrors("email", true)
      allowed = false;
    } else {
      addToFormErrors("email", false);
    }
    if (!accountDetails.password) {
      addToFormErrors("password", true)
      allowed = false;
    } else {
      addToFormErrors("password", false);
    }
    if (!accountDetails.tAndCAgree) {
      addToFormErrors("tAndCAgree", true)
      allowed = false;
    } else {
      addToFormErrors("tAndCAgree", false);
    }

    return allowed;
  }

  const createAccount = async (e) => {
    e.preventDefault();

    const allowed = await checkAccountDetails();

    if (!allowed) return;

    const currentDateTime = new Date().toLocaleString();

    const emailDomain = accountDetails.email.split("@")[1];
    let apiEmailFailedCheck = false;
    if (blackList.some(word => accountDetails.email.toLowerCase().includes(word.toLowerCase())) === true) {
      apiEmailFailedCheck = true
    } else {
      fetch(`https://mailcheck.p.rapidapi.com/?domain=${emailDomain}`, {
        "method": "GET",
        "headers": {
          "x-rapidapi-host": "mailcheck.p.rapidapi.com",
          "x-rapidapi-key": "2a466e2cb3msheed2d3b10a191bbp16a2cdjsnfdf3f7a3b52c"
        }
      })
        .then(response => {
          const responseBoolean = response.json();
          responseBoolean.then(res => {
            if (!res.valid || res.text.toLowerCase().includes("disposable") || res.risk > 75) {
              apiEmailFailedCheck = true;
            }
          }).catch(err => {
            console.error(err)
          })
        })
        .catch(err => {
          console.error(err);
        });
    }

    setTimeout(async function () {
      let ipAddress = "";
      await $.getJSON('https://ipapi.co/json/', function (data) {
        ipAddress = (data.ip);
      });
      const ipBL = ["90.63.211.203"]
      if (ipBL.includes(ipAddress)) {
        return;
      }
      accountDetails.ipAddress = ipAddress
      if (apiEmailFailedCheck === true) {
        fetch(`https://script.google.com/macros/s/AKfycbwZ3xpZfyU9adFSmokgREhxdkr1LklYD3qu9YA_79DYwLrJ9kKhuMnICLG8BgMoimwc/exec?firstname=${accountDetails.firstName}&&lastname=${accountDetails.lastName}&&email=${accountDetails.email}&&ip=${ipAddress}&&creation=${currentDateTime}`)
        alert("Error: Sorry but there is a problem with your email address so we are unable to create your account. Please contact frank@croftanalytics.com for help.");
        window.location.reload();
      } else {
        const response = await fetch(environmentUrl + 'createnewaccount', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ post: accountDetails }),
        });

        const responseBoolean = await response.json();
        if (responseBoolean === true) {
          window.confirm("We believe you already have an account with us. Please contact frank@croftanalytics.com for more information.");
          window.location.reload();
        }
        else {
          setShowEmailVerificationPopup(true);
          sendVerificationEmail(e);
        }
      }
    }, 2000)
  }

  return (
    <div className="CreateAccountPage">

      {showEmailVerificationPopup && <EmailVerificationPopup setShowEmailVerificationPopup={setShowEmailVerificationPopup} />}
      {showPrivacyPolicyPopup && <PrivacyPolicyPopup setShowPrivacyPolicyPopup={setShowPrivacyPolicyPopup} />}

      <div id="navBarCreateAccountHider"></div>
      <a href="/">
        <img src={logo} alt="Logo" id="sosingImage" />
      </a>
      <div className="CreateAccountFormDiv">
        <form className="CreateAccountForm" onSubmit={(e) => createAccount(e)}>
          <p id="CreateAccountHeaderText">Create Account</p>
          <p id="CreateAccountSubHeaderText">For a 14 days free trial create your account below.</p>
          <p id="CreateAccountSubHeaderText">To activate a 12 month subscription for £500 click on the My Account icon after logging in.</p>

          <div id="firstNameLine">
            <input
              id="firstNameTextInput"
              type="text"
              placeholder="First Name*"
              required
              onChange={(e) => addToAccountDetails('firstName', e.target.value)}
              style={{ border: formErrors.firstName ? "2px solid red" : "" }}
            />
          </div>
          <div id="lastNameLine">
            <input
              id="lastNameTextInput"
              type="text"
              placeholder="Last Name*"
              required
              onChange={(e) => addToAccountDetails('lastName', e.target.value)}
              style={{ border: formErrors.lastName ? "2px solid red" : "" }}

            />
          </div>
          <div id="countryLine">
            <select
              name="countries"
              id="countriesList"
              onChange={(e) => {
                addToAccountDetails('countryShorthand', e.target.value)
                addToAccountDetails('country', e.target.options[e.target.selectedIndex].text)
              }}
            >
              <option id="countryValue" value="" disabled selected>Country</option>
            </select>
          </div>
          <div id="companyLine">
            <input
              id="companyTextInput"
              type="text"
              placeholder="Company"
              onChange={(e) => addToAccountDetails('company', e.target.value)}
            />
          </div>
          <div id="jobTitleLine">
            <input
              id="jobTitleTextInput"
              type="text"
              placeholder="Job Title"
              onChange={(e) => addToAccountDetails('jobTitle', e.target.value)}
            />
          </div>
          <div id="emailLine">
            <input
              id="emailTextInput"
              type="email"
              placeholder="Email*"
              required
              onChange={(e) => addToAccountDetails('email', e.target.value)}
              style={{ border: formErrors.email ? "2px solid red" : "" }}
            />
          </div>
          <div id="passwordLine">
            <input
              id="passwordTextInput"
              type="password"
              placeholder="Password (min. 4)*"
              minLength="4"
              required
              onChange={(e) => addToAccountDetails('password', e.target.value)}
              style={{ border: formErrors.password ? "2px solid red" : "" }}
            />
          </div>
          <br />
          <div id="termsAndConditionsBox"
            style={{ border: formErrors.tAndCAgree ? "2px solid red" : "" }}
          >
            <input
              type="checkbox"
              id="tAndCAgree"
              name="tAndCAgree"
              value="Agree"
              required
              onChange={(e) => addToAccountDetails('tAndCAgree', e.target.checked)}
            />
            <p for="tAndCAgree" id="acceptText"> Please <b>tick the box to confirm you agree</b> with our&nbsp;
              <span id="privacyPolicyText" onClick={(e) => setShowPrivacyPolicyPopup(true)}>privacy policy</span>
            </p>
            <br />
          </div>
          <br />
          <div id="centerSubmit">
            <button id="submitButton" type="submit" onclick="return gtag_report_conversion('https://shareofsearching.com/createaccount')"
            >Create Account</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CreateAccount;
