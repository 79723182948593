import React from 'react'

const LoadingIcon = () => {
    return (
        <div id="loadingIcon">
            <div id="bars5">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
            <p id="loadingDataText" > Retrieving data, please wait...</p>
        </div>
    )
}

export default LoadingIcon