import $ from 'jquery';
import HelperFunctions from './HelperFunctions';
import { Constants, SEARCH_FAILED_TEXT, VOLUME_SEARCH_FAILED_TEXT } from '../Components/Constants';
import CompareDates from '../CompareDates';
import powerPointIcon from "../../images/powerPointIcon.png";
import PptxGenJS from "pptxgenjs";

export const coloursArray = () => {
    return [
        '#000000', '#FFB399', '#FF33FF', '#ff6600', '#00B3E6',
        '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
        '#80B300', '#915C83', '#E6B3B3', '#6680B3', '#ff944d',
        '#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#23b28e',
        '#FAEBD7', '#B366CC', '#7FFFD4', '#B33300', '#CC80CC',
        '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
        '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680',
        '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
        '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3',
        '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF'
    ];
}
export const months = () => {
    return ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
}

export const longMonths = () => {
    return ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"];
}

export const datediff = (first, second) => {
    // Take the difference between the dates and divide by milliseconds per day.
    // Round to nearest whole number to deal with DST.
    return Math.round((second - first) / (1000 * 60 * 60 * 24));
}

export const parseDate = (str) => {
    var mdy = str.split('/');
    return new Date(mdy[2], mdy[0] - 1, mdy[1]);
}

export const generateTable = async ({ svi, country, start, end }) => {

    const helperFunctions = new HelperFunctions();

    $('#dataResults').empty();

    const cellColoursDarkToLight = [
        ('rgb(' + 99 + ',' + 190 + ',' + 123 + ')'),
        ('rgb(' + 148 + ',' + 210 + ',' + 165 + ')'),
        ('rgb(' + 190 + ',' + 227 + ',' + 201 + ')'),
        ('rgb(' + 225 + ',' + 241 + ',' + 232 + ')'),
        ('rgb(' + 255 + ',' + 255 + ',' + 255 + ')'),
    ]

    // creates a <table> element and a <tbody> element
    const tbl = document.createElement("table");
    const tblBody = document.createElement("tbody");

    const secondRow = document.createElement("tr");
    for (let index = 0; index <= svi[0].sviData.length; index++) {
        const cell = document.createElement("td");
        const cellText = index === 0 ? document.createTextNode(``) : document.createTextNode(`${svi[0].sviData[index - 1].date}`);
        cell.setAttribute('class', 'DateHeader');
        cell.appendChild(cellText);
        secondRow.appendChild(cell);

    }
    tblBody.appendChild(secondRow);

    svi.forEach(keyword => {
        const row = document.createElement("tr");
        const cell = document.createElement("td");
        cell.setAttribute('class', 'KeywordCell');
        const cellText = document.createTextNode(`${keyword.keyword}`);
        cell.appendChild(cellText);
        row.appendChild(cell);
        const sortedKeywordData = [...new Set(keyword.sviData.map(item => parseInt(item.data)).sort((a, b) => (
            b - a
        )))];
        keyword.sviData.forEach(data => { // data cells
            data.data = parseInt(data.data);
            const cell = document.createElement("td");
            const cellText = document.createTextNode(`${data.data}`);
            sortedKeywordData.forEach((kd, int) => {
                if (data.data === kd && int !== sortedKeywordData.length - 1) {
                    cell.style.backgroundColor = cellColoursDarkToLight[int];
                }
            })
            cell.appendChild(cellText);
            row.appendChild(cell);
        })
        tblBody.appendChild(row);
    })

    // put the <tbody> in the <table>
    tbl.appendChild(tblBody);
    // appends <table> into <body>
    document.getElementById("dataResults").appendChild(tbl)
    // sets the border attribute of tbl to '2'
    tblBody.setAttribute("class", "TableBody")
    tbl.setAttribute("class", "DataTable");
    tbl.setAttribute("id", "dataTableId");
    $('#dataResults').prepend(
        `<div class="TableHeader"><h5 class="TableHeaderText">Google Seasonality Heatmap</h5><p class="TableHeaderText"><p class="TableHeaderText">${country || 'WORLDWIDE'} | ${helperFunctions.getMMMYYYYGraphDate(start)} - ${helperFunctions.getMMMYYYYGraphDate(end)}</p></div>`
    );
    $('#dataResults').append(
        `<p id="heatmapInfo">The Heatmap is based on monthly Google search volumes shown as an index for each keyword over the selected period. The index for each keyword is the volume in the month indexed against the average monthly volume for the keyword.
        </p >`
    );
}

export const getPercentageDataForGraphs = ({ valuesArray, indexOfSelected, volumePlusObj, forSearchTerm }) => {
    const { isVolumePlus, nameArray, selectedTerm, countriesArray } = volumePlusObj;

    const calculatePercentages = (arrValues) => {
        const total = arrValues.reduce((accumulator, value) => accumulator + parseInt(value), 0);
        return arrValues.map(value => ((parseInt(value) / total) * 100).toFixed(2));
    };

    const processCountryResults = (countryResults, index) => {
        return forSearchTerm
            ? countryResults.map((element) => calculatePercentages(element.split(","))[indexOfSelected])
            : countryResults.map((element) => element.split(",")[indexOfSelected]);
    };

    if (isVolumePlus && selectedTerm && selectedTerm !== "All") {
        const indexOfSelectedSearchTerm = nameArray.indexOf(selectedTerm);
        const combinedValueArray = valuesArray.map((countryResults) => processCountryResults(countryResults, indexOfSelectedSearchTerm));


        if (!forSearchTerm) {
            const totalsArray = combinedValueArray[0].map((_, index) => combinedValueArray.reduce((acc, curr) => acc + parseInt(curr[index]), 0));
            combinedValueArray.forEach((countryRes) => countryRes.forEach((value, index) => countryRes[index] = ((parseInt(value) / totalsArray[index]) * 100).toFixed(2)));
        }
        return combinedValueArray;
    } else {
        const flattenedArray = isVolumePlus ? valuesArray[indexOfSelected] : valuesArray.flat(Infinity);
        const combinedValueArray = Array.from({ length: flattenedArray[0].split(",").length }, () => []);

        if (combinedValueArray.length === 1) {
            valuesArray.forEach((item) => item.split(",").forEach((value, index) => combinedValueArray[index].push(value)));
        } else {
            flattenedArray.forEach((item) => {
                const vArray = item.split(",");
                const totalOfAll = vArray.reduce((acc, value) => acc + parseInt(value), 0);
                vArray.forEach((value, index) => combinedValueArray[index].push(((parseInt(value) / totalOfAll) * 100).toFixed(2) || '0'));
            });
        }
        return combinedValueArray;
    }
};

export const getAllVolumePlusPercentageDataForGraphs = ({ valuesArray, indexOfSelected, volumePlusObj, forSearchTerm }) => {
    const { isVolumePlus, nameArray, selectedTerm, countriesArray } = volumePlusObj;

    const calculatePercentages = (arrValues) => {
        const total = arrValues.reduce((accumulator, value) => accumulator + parseInt(value), 0);
        return arrValues.map(value => ((parseInt(value) / total) * 100).toFixed(2));
    };

    const processCountryResults = (countryResults) => {
        return countryResults.map(element => calculatePercentages(element.split(",")));
    };

    const combinedValueArray = valuesArray.map(countryResults => processCountryResults(countryResults));

    return combinedValueArray;
};

export const getYearsInRangeReversed = (startDate, endDate) => {
    const startYear = new Date(startDate).getFullYear();
    const endYear = new Date(endDate).getFullYear();
    const years = [];

    for (let year = startYear; year <= endYear; year++) {
        years.push(year);
    }

    return years.reverse();
}

export const getDaysBetweenDates = (startDateStr, endDateStr) => {
    // e.g. 01 Jan 2018 and 01 Feb 2018
    // Create Date objects for the given strings
    const startDate = new Date(startDateStr);
    const endDate = new Date(endDateStr);

    // Calculate the difference in milliseconds
    const timeDifference = endDate - startDate;

    // Convert milliseconds to days
    const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

    return daysDifference;
}

export const getStartDateStartValue = ({ isIndexPage }) => {
    const today = new Date()
    today.setFullYear(today.getFullYear() - 4)
    if (isIndexPage) {
        return `${today.getFullYear()}-01-01`
    } else {
        var day = (today.getDate()).toString();
        var month = (today.getMonth() + 1).toString()
        if (day.length === 1) day = `0${day}`;
        if (month.length === 1) month = `0${month}`;
        return `${today.getFullYear()}-${month}-${day}`
    };
    // ${today.toLocaleString("default", { month: "2-digit" })}
}

export const autoDateChanged = (value, addToRefactoredPost) => {
    const intValue = parseInt(value)
    const endDate = getYYYYMMDDFromDate(new Date());
    var startDate = new Date();
    var startMonth = startDate.getMonth(); // getMonth() starts at 0
    switch (intValue) {
        case 1:
            startDate.setMonth(startMonth - 1);
            startDate = getYYYYMMDDFromDate(startDate);
            addToRefactoredPost('startDate', startDate);
            addToRefactoredPost('endDate', endDate);
            break;
        case 3:
            startDate.setMonth(startMonth - 3);
            startDate = getYYYYMMDDFromDate(startDate);
            addToRefactoredPost('startDate', startDate);
            addToRefactoredPost('endDate', endDate);
            break;
        case 6:
            startDate.setMonth(startMonth - 6);
            startDate = getYYYYMMDDFromDate(startDate);
            addToRefactoredPost('startDate', startDate);
            addToRefactoredPost('endDate', endDate);
            break;
        case 9:
            startDate.setMonth(startMonth - 9);
            startDate = getYYYYMMDDFromDate(startDate);
            addToRefactoredPost('startDate', startDate);
            addToRefactoredPost('endDate', endDate);
            break;
        case 12:
            startDate.setFullYear(startDate.getFullYear() - 1);
            startDate = getYYYYMMDDFromDate(startDate);
            addToRefactoredPost('startDate', startDate);
            addToRefactoredPost('endDate', endDate);
            break;
        case 36:
            startDate.setFullYear(startDate.getFullYear() - 3);
            startDate = getYYYYMMDDFromDate(startDate);
            addToRefactoredPost('startDate', startDate);
            addToRefactoredPost('endDate', endDate);
            break;
        default:
            break;
    }
}

export const tabHandler = (url) => {
    if (url === ('/search')) {
        $("#multiQueryTab").css("background-color", "rgb(84,155,53)");
        $("#multiQueryTab").css("color", "white");
        $("#multiQueryTab").css("border-bottom", "thin solid darkgreen");
        $("#multiQueryTab").css("border-left", "thin solid darkgreen");
        $("#multiQueryTab").css("border-right", "thin solid darkgreen");
        $("#multiQueryTab").css("cursor", "auto");
    }
    if (url === ('/countrysearch')) {
        $("#volumeSearchPlusTab").css("background-color", "rgb(84,155,53)");
        $("#volumeSearchPlusTab").css("color", "white");
        $("#volumeSearchPlusTab").css("border-bottom", "thin solid darkgreen");
        $("#volumeSearchPlusTab").css("border-left", "thin solid darkgreen");
        $("#volumeSearchPlusTab").css("border-right", "thin solid darkgreen");
        $("#volumeSearchPlusTab").css("cursor", "auto");
    }
    if (url === ('/volumesearch')) {
        $("#volumeSearchTab").css("background-color", "rgb(84,155,53)");
        $("#volumeSearchTab").css("color", "white");
        $("#volumeSearchTab").css("border-bottom", "thin solid darkgreen");
        $("#volumeSearchTab").css("border-left", "thin solid darkgreen");
        $("#volumeSearchTab").css("border-right", "thin solid darkgreen");
        $("#volumeSearchTab").css("cursor", "auto");
    }
    if (url === ('/similarsearch')) {
        $("#similarSearchTab").css("background-color", "rgb(84,155,53)");
        $("#similarSearchTab").css("color", "white");
        $("#similarSearchTab").css("border-bottom", "thin solid darkgreen");
        $("#similarSearchTab").css("border-left", "thin solid darkgreen");
        $("#similarSearchTab").css("border-right", "thin solid darkgreen");
        $("#similarSearchTab").css("cursor", "auto");
    }
}

export const getClientInfo = (addToRefactoredPost) => {
    $.getJSON('https://ipapi.co/json/', function (data) {
        addToRefactoredPost('ip', data.ip);
        addToRefactoredPost('countryName', data.country_name)
        addToRefactoredPost('city', data.city)
    });
}

export const setStickyHeader = (header, sticky, setScrollDown) => {
    if (window.pageYOffset > sticky) {
        header.classList.add("StickyHeader");
    } else {
        header.classList.remove("StickyHeader");
    }
    setScrollDown(true);
}

const getChartDataUrl = (chartId) => {
    if (document.getElementById(chartId)) {
        var graph = document.getElementById(chartId).getContext("2d");
        return graph.canvas.toDataURL();
    }
    return null;
}

export const createPowerpointDownloadButtons = (
    {
        refactoredPost,
        downloadContainerElement,
        page,
        isSubscriber,
        setShowSubscribersOnly,
        selectedYearlyChartTerm,
        selectedYearlyPercentChartTerm,
        chartDataObject
    }
) => {
    if (refactoredPost.searchTerms.length <= 0 || !document.getElementById("myVolumeChart")) return;
    if (document.getElementById("powerpointDownloadbutton") !== null) {
        document.getElementById("powerpointDownloadbutton").remove()
    }
    var button = document.createElement("button");
    button.id = "powerpointDownloadbutton"
    button.innerHTML = `<div id="downloadButtonDiv"><img id='downloadIcon' style="margin-right: 10%" src=${powerPointIcon} /><b>EXPORT CHARTS</b></div>`
    button.onclick = function () {
        if (!isSubscriber) {
            setShowSubscribersOnly(true);
            return;
        }
        powerpointDownload({ refactoredPost, page, selectedYearlyChartTerm, selectedYearlyPercentChartTerm, chartDataObject });
    }.bind(this);
    downloadContainerElement.appendChild(button);
}

export const powerpointDownload = ({ refactoredPost, page, selectedYearlyChartTerm, selectedYearlyPercentChartTerm, chartDataObject }) => {
    const isVolumePage = page === Constants.VOLUME_TYPE;
    const isVolumePlusPage = page === Constants.VOLUME_PLUS_TYPE;
    const isIndexPage = page === Constants.INDEX_TYPE;
    const searchTermNotCountry = chartDataObject?.selectedTerm ? true : false;
    const moreThan3YearsAndNotVolumePlus = (getDaysBetweenDates(refactoredPost.startDate, refactoredPost.endDate) > 1095 && !isVolumePlusPage);

    var country = refactoredPost.country.toUpperCase();
    var category = refactoredPost?.category?.toUpperCase() || '';
    var startdate = refactoredPost.startDate
    var enddate = refactoredPost.endDate
    var shorthands = "";
    if (refactoredPost.stateShorthand !== "" && refactoredPost.regionShorthand !== "") {
        shorthands = refactoredPost.state + " | " + refactoredPost.region
    }
    if (refactoredPost.regionShorthand === "" && refactoredPost.stateShorthand !== "") {
        shorthands = refactoredPost.state
    }

    const chartIds = [
        "myVolumeChart",
        "myChart",
        "myAreaChart",
        "myBarChart",
        "myYearlyChart",
        "myYearlyPercentChart",
        "myCountryVolumeChart",
        "myCountryVolumeTotalChart",
        "myChartForSearchTerm",
        "myBarChartForSearchTerm"
    ];

    const chartDataUrls = chartIds.reduce((acc, chartId) => {
        const chartData = getChartDataUrl(chartId);
        // Check if chartData is truthy (i.e., not null or undefined)
        if (chartData) {
            acc[chartId] = chartData;
        }
        return acc;
    }, {});

    const getSearchInfoText = ({ chart, refactoredPost, chartDataObject }) => {
        const selectedTermOrCountry = chartDataObject?.selectedTerm ? chartDataObject.selectedTerm : chartDataObject?.selectedCountry ? chartDataObject.selectedCountry : chartDataObject?.countriesArray[0] ? chartDataObject.countriesArray[0] : null;

        var searchInfoText = country +
            (shorthands !== "" ? ` ( ${shorthands} )` : "") +
            " | " + category +
            " | " + getDDMMMYYYYGraphDate(startdate) +
            " - " + getDDMMMYYYYGraphDate(enddate);

        if (chart === "myCountryVolumeTotalChart") {
            return "All search terms"
        } else if (chart === "myCountryVolumeChart") {
            return "All countries"
        } else if (isVolumePlusPage) {
            return selectedTermOrCountry + "\n" + getDDMMMYYYYGraphDate(startdate) +
                " - " + getDDMMMYYYYGraphDate(enddate);
        } else if (chart === "myYearlyChart") {
            return selectedYearlyChartTerm
        } else if (chart === "myYearlyPercentChart") {
            return selectedYearlyPercentChartTerm
        } else {
            return searchInfoText
        }

    }

    const property = propertyFormatText(refactoredPost.property);

    const pptx = new PptxGenJS();
    const addSlide = (chart, title, image) => {
        const slide = pptx.addSlide();
        slide.addText(title, { y: 0, w: "100%", h: 1, align: "center", bold: true, fontSize: 16 });
        slide.addText(getSearchInfoText({ chart, refactoredPost, chartDataObject }), { y: 0.3, w: "100%", h: 1, align: "center", fontSize: 10, bold: false });
        slide.addText("Source: shareofsearching.com, Google", { x: '10%', y: "85%", w: 5, h: 1, fontSize: 10, bold: false });

        // Check if the chart data URL is available
        if (image) {
            slide.addImage({ data: image, x: 1, y: 1, w: 8, h: 4 });
        } else {
            // Handle the case where chart data URL is not available
            console.log(`Chart data not available for chart: ${chart}`);
            // You might want to add a placeholder or handle this case accordingly
        }
    };

    addSlide("myVolumeChart", `${property} Trend in Search Volume ${isIndexPage ? 'Index' : ''}`, chartDataUrls["myVolumeChart"]);
    addSlide("myChart", `${isVolumePlusPage && chartDataObject.selectedTerm ? `Country % Share of Google Search for Search Term` : `${property} Trend in % Share of Search`}`, chartDataUrls["myChart"]);
    if (!searchTermNotCountry) {
        addSlide("myAreaChart", `${property} Trend in % Share of Search`, chartDataUrls["myAreaChart"]);
    }
    addSlide("myBarChart", `${isVolumePlusPage && chartDataObject.selectedTerm ? ` % Share of Total Search For A Search Term Across Countries` : `${property} Total % Share of Search`}`, chartDataUrls["myBarChart"]);

    if (moreThan3YearsAndNotVolumePlus) {
        addSlide("myYearlyChart", `${property} Annual Trend in Search Volume for a Search Term`, chartDataUrls["myYearlyChart"]);
        addSlide("myYearlyPercentChart", `${property} Annual Trend in % Share of Search for a Search Term`, chartDataUrls["myYearlyPercentChart"]);
    } else if (isVolumePlusPage) {
        addSlide("myCountryVolumeChart", `${property} Trend in Total Search Volume across All Countries by Search Term`, chartDataUrls["myCountryVolumeChart"]);
        addSlide("myCountryVolumeTotalChart", `${property} Trend in Total Search Volume across All Search Terms by Country`, chartDataUrls["myCountryVolumeTotalChart"]);
        if (searchTermNotCountry) {
            addSlide("myChartForSearchTerm", `${property} Trend in % Share of Search Within Each Country`, chartDataUrls["myChartForSearchTerm"]);
            addSlide("myBarChartForSearchTerm", `% Share of Search Within Each Country for a Search Term`, chartDataUrls["myBarChartForSearchTerm"]);
        }
    }

    pptx.writeFile({ fileName: "ShareOfSearchingCharts.pptx" });
};


export const propertyFormatText = (text) => {
    if (text === 'web') {
        return 'Google'
    }
    return 'YouTube'
}

export const getYYYYMMDDFromDate = (date) => {
    const yyyy = date.getFullYear();
    let mm = date.getMonth() + 1; // Months start at 0!
    let dd = date.getDate();

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;

    return (yyyy + '-' + mm + '-' + dd);
}

export const removeEndCommas = (searchedItem) => {
    if (searchedItem) {
        while (searchedItem[searchedItem.length - 1] === ",") {
            searchedItem = searchedItem.slice(0, -1);
        }
    }
    return (searchedItem);
}

export const resetCharts = ({ refactoredPost, chartRef, barChartRef, barSliderChartRef, volumeChartRef, areaChartRef, page }) => {
    var shorthands = "";
    if (refactoredPost.stateShorthand !== "" && refactoredPost.regionShorthand !== "") {
        shorthands = refactoredPost.state + " | " + refactoredPost.region
    }
    if (refactoredPost.regionShorthand === "" && refactoredPost.stateShorthand !== "") {
        shorthands = refactoredPost.state
    }

    $('#downloadContainer').empty(); //.remove() this is my <canvas> element
}

export const getSearchPageText = ({ page, userInfo }) => {
    switch (page) {
        case Constants.INDEX_TYPE:
            return (
                <div id='homeText'>
                    {userInfo.subscriptionNotificationText && (<p id="subscriptionStatus">
                        <p id="subscriptionStatus" dangerouslySetInnerHTML={{ __html: userInfo.subscriptionNotificationText }} />
                    </p>)}
                    <p id="homeTitleText">About Index Trends</p>
                    <p id="homeBaseText">Enter up to 30 search terms at a time to see trends in Google or YouTube search volumes (relative index) and % share of search for the terms back to 2004. Select a country and the date range. Export the data to Excel and the charts to PowerPoint.</p>
                    <p id="homeTitleText">Use options on the left to:</p>
                    <ul id="filterBulletpoints">
                        <li id="bulletpoint">Select a <b style={{ fontSize: '14px' }}>COUNTRY</b> from the drop down list. </li>
                        <li id="bulletpoint">Optionally select a <b style={{ fontSize: '14px' }}>CATEGORY</b> from the drop down list.</li>
                        <li id="bulletpoint">Select a <b style={{ fontSize: '14px' }}>START DATE</b> and <b style={{ fontSize: '14px' }}>END DATE</b>. Periods of up to 38 weeks will display daily data in the charts, 40 - 269 weeks show weekly data, and 270+ weeks show monthly data.</li>
                        <li id="bulletpoint">Select either <b style={{ fontSize: '14px' }}>GOOGLE</b> or <b style={{ fontSize: '14px' }}>YOUTUBE</b> for the search data.</li>
                        <li id="bulletpoint">Enter the <b style={{ fontSize: '14px' }}>SEARCH LIST</b> as a list of keywords/search terms (max 30). Input each term then press [Enter] or [,] to build the list in the box. To combine volume for terms input [+] between each term. Click and drag a term to move its position in the list. Click [x] to remove a term from the list or [X] at the right of the box to clear all terms.</li>
                        <li id="bulletpoint">Click on <b style={{ fontSize: '14px' }}>SUBMIT</b> to generate the charts (wait time c.15-30 seconds).</li>
                    </ul>
                    <p id="homeBaseText">Click <b style={{ fontSize: '16px' }}>SAVE SEARCH</b> to save your search criteria. Click <b style={{ fontSize: '16px' }}>RETRIEVE SEARCHES</b> to retrieve previously saved criteria.</p>
                    <p id="homeBaseBottomText">If you have any questions or suggestions for improvement please email Frank Harrison at <a href="mailto:frank@croftanalytics.com">frank@croftanalytics.com</a></p>
                </div>
            )
        case Constants.VOLUME_TYPE:
            return (
                <div id="homeText">
                    {userInfo.subscriptionNotificationText && (<p id="subscriptionStatus">
                        <p id="subscriptionStatus" dangerouslySetInnerHTML={{ __html: userInfo.subscriptionNotificationText }} />
                    </p>)}
                    <p id="homeTitleText">About Volume Trends</p>
                    <p id="homeBaseText">Enter up to 30 search terms at a time to see monthly trends in Google search volumes and % share of search for the terms over the last four years. Select a country and date range. Export the data to Excel and the charts to PowerPoint. </p>
                    <p id="homeTitleText">Use the options on the left to:</p>
                    <ul id="filterBulletpoints">
                        <li id="bulletpoint">Select a <b style={{ fontSize: '14px' }}>COUNTRY</b> from the drop down list.</li>
                        <li id="bulletpoint">Select a <b style={{ fontSize: '14px' }}>START DATE</b> and <b style={{ fontSize: '14px' }}>END DATE</b> (earliest start date is four years ago).</li>
                        <li id="bulletpoint">Enter the <b style={{ fontSize: '14px' }}>SEARCH LIST</b> as a list of keywords/search terms (max 30). Input each term then press [Enter] or [,] to build the list in the box. To combine volume for terms input [+] between each term. Click and drag a term to move its position in the list. Click [x] to remove a term from the list or [X] at the right of the box to clear all terms.</li>
                        <li id="bulletpoint">Click on <b style={{ fontSize: '14px' }}>SUBMIT</b> to generate the charts (wait time c.15-30 seconds).</li>
                    </ul>
                    <p id="homeBaseText">Click <b style={{ fontSize: '16px' }}>SAVE SEARCH</b> to save your search criteria. Click <b style={{ fontSize: '16px' }}>RETRIEVE SEARCHES</b> to retrieve previously saved criteria.</p>
                    <p id="homeBaseBottomText">If you have any questions or suggestions for improvement please email Frank Harrison at <a href="mailto:frank@croftanalytics.com">frank@croftanalytics.com</a></p>
                </div>
            )
        case Constants.VOLUME_PLUS_TYPE:
            return (
                <div id="homeText">
                    {userInfo.subscriptionNotificationText && (<p id="subscriptionStatus">
                        <p id="subscriptionStatus" dangerouslySetInnerHTML={{ __html: userInfo.subscriptionNotificationText }} />
                    </p>)}
                    <p id="homeTitleText">About Country Trends</p>
                    <p id="homeBaseText">Enter up to 30 search terms at a time, and select up to five countries, to see monthly trends in Google search volumes and % share of search for the terms over the last four years within and across the countries. Export the data to Excel and the charts to PowerPoint. </p>
                    <p id="homeTitleText">Use the options on the left to:</p>
                    <ul id="filterBulletpoints">
                        <li id="bulletpoint"> Select up to five countries from the drop-down <b style={{ fontSize: '14px' }}>COUNTRY</b> list.</li>
                        <li id="bulletpoint">Select a <b style={{ fontSize: '14px' }}>START DATE</b> and <b style={{ fontSize: '14px' }}>END DATE</b> (earliest start date is four years ago).</li>
                        <li id="bulletpoint">Enter the <b style={{ fontSize: '14px' }}>SEARCH LIST</b> as a list of keywords/search terms (max 30). Input each term then press [Enter] or [,] to build the list in the box. To combine volume for terms input [+] between each term. Click and drag a term to move its position in the list. Click [x] to remove a term from the list or [X] at the right of the box to clear all terms.</li>
                        <li id="bulletpoint">Click on <b style={{ fontSize: '14px' }}>SUBMIT</b> to generate the charts (wait time c.30-120 seconds)</li>
                    </ul>
                    <p id="homeBaseText">Click <b style={{ fontSize: '16px' }}>SAVE SEARCH</b> to save your search criteria. Click <b style={{ fontSize: '16px' }}>RETRIEVE SEARCHES</b> to retrieve previously saved criteria.</p>
                    <p id="homeTitleText">Displayed charts options:</p>
                    <ul id="filterBulletpoints">
                        <li id="bulletpoint">Select a COUNTRY from the drop-down list at the top of the charts to view charts for that country</li>
                        <li id="bulletpoint">Select a SEARCH TERM from the drop-down list at the top of the charts to view charts for the selected search term</li>
                        <li id="bulletpoint">Scroll down to view all charts. Select buttons at the top right to export the data to Excel and charts to PowerPoint.</li>
                    </ul>
                    <p id="homeBaseBottomText">If you have any questions or suggestions for improvement please email Frank Harrison at <a href="mailto:frank@croftanalytics.com">frank@croftanalytics.com</a></p>
                </div>
            )
        case Constants.SIMILAR_TYPE:
            return (
                <div id="homeText">
                    {userInfo.subscriptionNotificationText && (<p id="subscriptionStatus">
                        <p id="subscriptionStatus" dangerouslySetInnerHTML={{ __html: userInfo.subscriptionNotificationText }} />
                    </p>)}
                    <p id="homeTitleText">About Similar Terms</p>
                    <p id="homeBaseText">Enter a single keyword / search term to retrieve related search terms(sourced from Google) and see the monthly trends in Google search volumes and % share of search for the terms over the last four years. Select a country and date range.Export the data to Excel and the charts to PowerPoint.</p>
                    <p id="homeTitleText">Use options on the left to:</p>
                    <ul id="filterBulletpoints">
                        <li id="bulletpoint">Select a <b style={{ fontSize: '14px' }}>COUNTRY</b> from the drop down list</li>
                        <li id="bulletpoint">Select a <b style={{ fontSize: '14px' }}>START DATE</b> and <b style={{ fontSize: '14px' }}>END DATE</b> (earliest start date is four years ago).</li>
                        <li id="bulletpoint">Select either <b style={{ fontSize: '14px' }}>NARROW</b> for related terms that all include the search term or <b style={{ fontSize: '14px' }}>WIDE</b> for broader related terms.</li>
                        <li id="bulletpoint">Enter a <b>SINGLE</b><b style={{ fontSize: '14px' }}> SEARCH TERM</b> such as a brand name or topic.</li>
                        <li id="bulletpoint">Click on <b style={{ fontSize: '14px' }}>SUBMIT</b> to generate the charts (wait time c.15-30 seconds).</li>
                    </ul>
                    <p id="homeBaseBottomText">If you have any questions or suggestions for improvement please email Frank Harrison at <a href="mailto:frank@croftanalytics.com">frank@croftanalytics.com</a></p>
                </div>
            )
        default:
            break;
    }

}

export const searchTermsTooLong = (array, text) => {
    let count = 0;
    array.forEach((item, i) => {
        if (item.length > 104) {
            count += 1;
            $("#inputErrorText").text(text)
            $("#criteriaSubmitButton").prop("disabled", true);
            $("#plusButton").prop("disabled", true);
            $("#criteriaSubmitButton").css({ 'background-color': 'grey', 'cursor': 'default' });
        } else {
            if (count === 0) {
                $("#criteriaSubmitButton").prop("disabled", false);
                $("#plusButton").prop("disabled", false);
                $("#inputErrorText").empty();
                $("#criteriaSubmitButton").css({ 'background-color': 'rgb(84,130,53)', 'cursor': 'pointer' });
            }
        }
    });
}

export const testEnvironment = (hostname) => {
    if (hostname === "localhost") {
        return { url: 'http://localhost:5000/api/', localhost: true };
    } else {
        return { url: 'https://servershareofsearch.herokuapp.com/api/', localhost: false };
    }
}

export const getUserAccountInfo = () => {
    return ({ email: sessionStorage.getItem("user"), accountId: parseInt(sessionStorage.getItem("id")) / 500 })
}

export const searchAllowed = async ({ userInfo, environment, paypalUrl, setPaypalUrl, changeUserInfo }) => {

    // const [userInfo, setUserInfo] = useState({ email, accountId, isSubscriber: false, isExpired: false, allowed: ["none"], dailyLimitsReached: ["none"] });

    var accountInfo = { email: userInfo.email };

    if (userInfo.email === null || userInfo.email === "null") {
        window.location.replace("/login");
    }

    const response = await fetch(environment.url + 'checkallowedsearch', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ post: accountInfo }),
    });

    const allowedSearchResponse = await response.json();

    if (allowedSearchResponse[0].Country === "United Kingdom") {
        setPaypalUrl("https://croftanalytics.com/shareofsearchinguk");
        paypalUrl = "https://croftanalytics.com/shareofsearchinguk";
    }
    changeUserInfo("IndexStatus", allowedSearchResponse[0].IndexStatus)
    changeUserInfo("SubscriptionType", allowedSearchResponse[0].Subscription)
    var allowedSearchBinary = allowedSearchResponse[0].AllowSearch;
    var subEndDate = allowedSearchResponse[0].SubscriptionEndDate;
    var subEndDateVar = new Date(allowedSearchResponse[0].SubscriptionEndDate.replace("T", " ").replace("Z", " ").substring(0, 10));
    var subEndDateText = subEndDateVar.getDate() + " " + longMonths()[subEndDateVar.getUTCMonth()] + " " + subEndDateVar.getFullYear();
    var extendedSubEndDateText = subEndDateVar.getDate() + " " + longMonths()[subEndDateVar.getUTCMonth()] + " " + (subEndDateVar.getFullYear() + 1);
    var subscriptionType = allowedSearchResponse[0].Subscription;
    var daysBetweenDates = datediff(parseDate(new Date().getUTCMonth() + "/" + new Date().getDate() + "/" + new Date().getFullYear()), parseDate(subEndDateVar.getUTCMonth() + "/" + subEndDateVar.getDate() + "/" + subEndDateVar.getFullYear()));

    if (subscriptionType !== "FREE" && subscriptionType !== "EXPIRED") { changeUserInfo("isSubscriber", true) }
    if (subscriptionType === "FREE") {
        changeUserInfo("subscriptionNotificationText", `Your free trial ends on <b> ${subEndDateText} </b> - to subscribe for a year for £500 click <a id="hereAttr" href=${paypalUrl}>here</a>.`)
    } else if (subscriptionType === "EXPIRED") {
        changeUserInfo("subscriptionNotificationText", `Your current subscription has <b>Expired</b> - Click <a id="hereAttr" href=${paypalUrl}>here</a> to renew for another year.`);
    } else if (daysBetweenDates < 31) {
        changeUserInfo("subscriptionNotificationText", `Your subscription ends on <b>${subEndDateText}</b> - to extend it to ${extendedSubEndDateText} click <a id="hereAttr" href=${paypalUrl}>here</a>.`);
    } else if (subscriptionType === "ADMIN") {
        changeUserInfo("subscriptionNotificationText", `Your free trial ends on <b> ${subEndDateText} </b> - to subscribe for a year for £500 click <a id="hereAttr" href=${paypalUrl}>here</a>.`);
    }
    else {
        $("#subscriptionStatus").remove();
    }

    var isExpired = CompareDates.compare_dates(new Date(), new Date(subEndDate))

    if (isExpired === "Now > Date2") {
        var allowSearchToDb = 0;
        accountInfo.allowSearchUpdate = allowSearchToDb;

        const response = await fetch(environment.url + 'setexpired', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ post: accountInfo }),
        });
        changeUserInfo("isExpired", true)
    } else if (allowedSearchBinary === 0 || userInfo.email === null || userInfo.email === "null") {
        changeUserInfo("verified", false)
    }
    if (allowedSearchResponse[0].VolumeSearchCountDaily <= 0) {
        userInfo.dailyLimitsReached.push(Constants.VOLUME_TYPE)
        changeUserInfo("dailyLimitsReached", userInfo.dailyLimitsReached)
    }
    if (allowedSearchResponse[0].SimilarSearchCountDaily <= 0) {
        userInfo.dailyLimitsReached.push(Constants.SIMILAR_TYPE)
        changeUserInfo("dailyLimitsReached", userInfo.dailyLimitsReached)
    }
    if (allowedSearchResponse[0].CountrySearchCountDaily <= 0) {
        userInfo.dailyLimitsReached.push(Constants.VOLUME_PLUS_TYPE)
        changeUserInfo("dailyLimitsReached", userInfo.dailyLimitsReached)
    }

    if (allowedSearchResponse[0].Id !== (sessionStorage.getItem("id") / 500)) {
        alert("Not Allowed");
        window.location.replace("/");
    }
}

export const checkPageAllowed = async ({ environment, userInfo, searchType, refactoredPost }) => {
    var accountInfo = { email: userInfo.email };

    const response = await fetch(environment.url + 'checkallowedsearch', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ post: accountInfo }),
    });

    const allowedSearchResponse = await response.json();
    const ipBL = ["90.63.211.203"]
    var ipAllowed = true;
    if (ipBL.includes(allowedSearchResponse[0].CreationIpAddress) || ipBL.includes(refactoredPost.ip)) {
        ipAllowed = false;
    }

    if (allowedSearchResponse[0].AllowSearch === 0 || !ipAllowed) {
        return { pageStatus: "NOT RELATED", allowed: false };
    }
    if (allowedSearchResponse[0].Id !== (sessionStorage.getItem("id") / 500)) {
        return { pageStatus: "ON", allowed: false };
    }

    if (allowedSearchResponse[0].IndexStatus === "OFF" && searchType === Constants.INDEX_TYPE) {
        return { pageStatus: "OFF", dailyLimitReached: null, allowed: false };
    }

    if (allowedSearchResponse[0].VolumeSearchCountDaily <= 0 && searchType === Constants.VOLUME_TYPE) {
        return { pageStatus: "ON", dailyLimitReached: Constants.VOLUME_TYPE, allowed: false };
    }
    if (allowedSearchResponse[0].SimilarSearchCountDaily <= 0 && searchType === Constants.SIMILAR_TYPE) {
        return { pageStatus: "ON", dailyLimitReached: Constants.SIMILAR_TYPE, allowed: false };
    }
    if (allowedSearchResponse[0].CountrySearchCountDaily <= 0 && searchType === Constants.VOLUME_PLUS_TYPE) {
        return { pageStatus: "ON", dailyLimitReached: Constants.VOLUME_PLUS_TYPE, allowed: false };
    }

    return { allowed: true };
}

export const getDDMMMYYYYGraphDate = (date) => {
    var dateArr = []
    if (date.includes("/")) {
        dateArr = date.split("/");
    } else {
        dateArr = date.split("-");
    }
    dateArr[1] = months()[dateArr[1] - 1];

    return dateArr.reverse().join(" ")
}

export const fillSavedSearch = async ({ setShowSavedSearches, addToRefactoredPost, savedSearch, e }) => {
    const countryStateRegionArr = {}
    for (var i = 0; i < savedSearch.childNodes.length; i++) {
        for (var b = 0; b < savedSearch.childNodes[i].childNodes.length; b++) {
            if (savedSearch.childNodes[i].childNodes[b] instanceof Element === true) {
                resetSearchWords({ addToRefactoredPost });
                if (savedSearch.childNodes[i].childNodes[b].tagName.toLowerCase() == 'span') {
                    var previousSearchSpanNode = savedSearch.childNodes[i].childNodes[b]

                    if (previousSearchSpanNode.id === "savedCountryReceived") {
                        var country = previousSearchSpanNode.textContent.split(',')[0];
                        var countries = previousSearchSpanNode.textContent.split(',').map(country => ({ country, countryShorthand: '' }));


                        addToRefactoredPost('country', country);
                        addToRefactoredPost("countryShorthand", "");
                        addToRefactoredPost('countries', countries);
                    }

                    if (previousSearchSpanNode.id === "savedCategoryReceived") {
                        var category = capitalizeEachWord(previousSearchSpanNode.textContent);
                        addToRefactoredPost('category', category);
                    }

                    if (previousSearchSpanNode.id === "savedStartReceived") {
                        var date = "2022-01-01";
                        date = getYYYYMMDDFromDate(new Date(previousSearchSpanNode.textContent));
                        addToRefactoredPost('startDate', date);
                        $("#startDate").val(date);

                    }
                    if (previousSearchSpanNode.id === "savedEndReceived") {
                        const date = getYYYYMMDDFromDate(new Date(previousSearchSpanNode.textContent))
                        addToRefactoredPost('endDate', date)
                        $("#endDate").val(date)
                    }

                    if (previousSearchSpanNode.id === "savedSearchedForReceived") {
                        const words = removeEndCommas(previousSearchSpanNode.textContent).split(",");
                        searchTermsTooLong(words, "One or more of your search terms is too long, please reduce.")
                        addToRefactoredPost('searchTerms', removeEndCommas(previousSearchSpanNode.textContent).split(','))
                        document.getElementById("multiSubmitInput").value = removeEndCommas(previousSearchSpanNode.textContent);
                    }
                }
                setShowSavedSearches(false)
            } else { }
        }
    }
}

export const capitalizeEachWord = (inputString) => {
    inputString = inputString.toLowerCase();
    // Check if the input is a non-empty string
    if (typeof inputString !== 'string' || inputString.length === 0) {
        return inputString;
    }

    // Split the input string into an array of words
    const words = inputString.split(' ');

    // Capitalize the first character of each word
    const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));

    // Join the capitalized words back into a string
    const resultString = capitalizedWords.join(' ');

    return resultString;
}


export const showAllSavedSearchesFunc = async ({ sortBy, environment, setShowSavedSearchDelete, setShowSavedSearches, savedSearchDeleteFunc, setSavedSearchesToRender, addToRefactoredPost, setSavedSearchToDelete }) => {
    setTimeout(async () => {

        const id = sessionStorage.getItem("id");
        const email = sessionStorage.getItem("user");
        var accountInfo = {};
        accountInfo.email = email;
        accountInfo.id = id;

        const response = await fetch(environment.url + 'getsavedsearches', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ post: accountInfo }),
        });
        const savedSearches = await response.json();

        var numberOfSearches;
        if (savedSearches.length < 30) {
            numberOfSearches = 0;
        } else {
            numberOfSearches = savedSearches.length - 30;
        }
        if (savedSearches.length > 0) {

            if (sortBy && sortBy === "StartDate") {
                savedSearches.sort((a, b) => new Date(b.start_date) - new Date(a.start_date))
            }
            if (sortBy && sortBy === "Country") {
                savedSearches.sort((a, b) => {
                    let comparison = 0;
                    if (a.country < b.country) {
                        comparison = 1;
                    } else if (a.country > b.country) {
                        comparison = -1;
                    }
                    return comparison;
                })
            }
            if (sortBy && sortBy === "Name") {
                savedSearches.sort((a, b) => {
                    let comparison = 0;
                    if (a.search_name < b.search_name) {
                        comparison = 1;
                    } else if (a.search_name > b.search_name) {
                        comparison = -1;
                    }
                    return comparison;
                })
            }
            const savedSearchesToRenderTemp = []

            for (var x = savedSearches.length - 1; x >= numberOfSearches; x--) {
                var searchId = savedSearches[x].Id;
                var searchName = savedSearches[x].search_name;
                var searchedFor = savedSearches[x].search_terms;
                var searchedCountry = savedSearches[x].country;
                var searchedState = savedSearches[x].state;
                var searchedRegion = savedSearches[x].region;
                var searchedCategory = savedSearches[x].category;
                var searchedStart = savedSearches[x].start_date.substring(0, 10);
                searchedStart = new Date(searchedStart).toJSON().slice(0, 10).split('-');
                searchedStart[1] = months()[parseInt(searchedStart[1]) - 1];
                searchedStart = searchedStart.reverse().join("-");
                var searchedEnd = savedSearches[x].end_date.substring(0, 10);
                searchedEnd = new Date(searchedEnd).toJSON().slice(0, 10).split('-');
                searchedEnd[1] = months()[parseInt(searchedEnd[1]) - 1];
                searchedEnd = searchedEnd.reverse().join("-");
                var searchEndArray = searchedEnd.split("-");
                searchEndArray[2] = searchEndArray[2] - 1;
                var endDay = parseInt(searchEndArray[2]) + 1;
                searchEndArray[2] = endDay.toString();
                var searchProperty = propertyFormatText(savedSearches[x].property);

                const buttonHTML = (
                    <i key={`iKey${x}`} class={`savedSearchBin${x} fa fa-trash-o`} title="Delete" name={searchName} subid={searchId} style={{ color: "black", cursor: "pointer", zIndex: 99 }} onClick={(e) => {
                        setShowSavedSearchDelete(true)
                        setShowSavedSearches(false)
                        savedSearchDeleteFunc({ e, setSavedSearchToDelete })
                    }}></i>
                )

                if (savedSearches[x].SearchType !== Constants.SIMILAR_TYPE) {
                    const htmlToAppend = (
                        <div key={`divKey${x}`} class={`singleSavedSearch${x}`} onClick={(e) => fillSavedSearch({ addToRefactoredPost, savedSearch: e.target, e: e, setShowSavedSearches })} >
                            <p id={'savedSearchName${x}'} style={{ color: "blue" }}><b>{searchName}</b></p>
                            <p style={{ fontSize: 13 }}><b> Country:</b> <span id='savedCountryReceived'>{searchedCountry}</span> | <b> State:</b> <span id='savedStateReceived'>{searchedState}</span> | <b> Region:</b> <span id='savedRegionReceived'>{searchedRegion}</span></p>
                            <p style={{ fontSize: 13 }}><b> Category:</b> <span id='savedCategoryReceived'>{searchedCategory}</span></p>
                            <p style={{ fontSize: 13 }}><b> Start Date:</b> <span id='savedStartReceived'>{searchedStart}</span> | <b> End Date:</b> <span id='savedEndReceived'>{searchEndArray.join("-")}</span></p>
                            <p style={{ fontSize: 13 }}><b>Search Property:</b> <span id='savedSearchedPropertyReceived'>{searchProperty}</span></p>
                            <p style={{ fontSize: 13 }}><b>Search List:</b> <span id='savedSearchedForReceived'>{searchedFor}</span></p>
                        </div >
                    )
                    savedSearchesToRenderTemp.push(htmlToAppend, buttonHTML, <p key={`pKey${x}`} class={`LastSearchBreaker${x}`}></p>)
                }
            }
            setSavedSearchesToRender(savedSearchesToRenderTemp);
        }
    }, 100);
}

export const savedSearchDeleteFunc = ({ e, setSavedSearchToDelete }) => {
    const savedSearchId = e.target.getAttribute('subId');
    const savedSearchName = e.target.getAttribute('name');
    setSavedSearchToDelete({ id: savedSearchId, name: savedSearchName })

    setTimeout(() => {
        $("#searchNameToDelete").text(savedSearchName);
    }, 50);
}

export const saveSearchOk = async ({ saveSearchNameInput, addToRefactoredPost, setSaveSearchSubmit, setShowSaveSearch }) => {
    const savedSearchName = saveSearchNameInput !== "" ? saveSearchNameInput : $("#saveSearchNameInput").val();
    addToRefactoredPost('savedSearchName', savedSearchName)
    setSaveSearchSubmit(true);
    setShowSaveSearch(false);
}

export const editSearchesOk = ({ setShowEditList, setRefactoredPost }) => {
    setShowEditList(false);
    setRefactoredPost(prevState => ({ ...prevState, searchTerms: $("#searchingFor").text().split(",") }));
    if ($("#searchingFor").text().length === 0) {
        $("#editButton").css("display", "none");
    }
}

export const minDate = () => {
    const today = new Date()
    today.setFullYear(today.getFullYear() - 4);
    return `${today.getFullYear()}-${today.toLocaleString("default", { month: "2-digit" })}-${today.toLocaleString("default", { day: "2-digit" })}`
}

export const writeToLogTable = async ({ postObj, environment, searchType }) => {
    const response = await fetch(environment.url + 'savelogsearch', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ post: postObj }),
    });
}

export const resetSearchWords = ({ addToRefactoredPost }) => {
    $("#multiSubmitInput").val("");
    addToRefactoredPost('searchTerms', [])
    $("#searchingFor").text("");
    $("#editButton").css("display", "none");
}

export const handleMultiSubmit = async (
    { environment,
        userInfo,
        numberOfRunSearches,
        setShowGraphText,
        refactoredPost,
        chartRef,
        barChartRef,
        volumeChartRef,
        areaChartRef,
        setSearchDataObj,
        setResponseToPostVolume,
        setChartsLoaded,
        setFormSubmitted,
        setNumberOfRunSearches,
        setChartDataObject,
        setDateArray,
        setVolumeArray,
        searchType
    }) => {
    const checkAllowed = await checkPageAllowed({ environment, userInfo, searchType, refactoredPost })
    if (!checkAllowed.allowed && userInfo.SubscriptionType !== "ADMIN") {
        if (checkAllowed.pageStatus === "OFF") {
            alert(`Module is currently unavailable, please come back later or consider using alternative modules.`)
            window.location.replace("/selector");
            return;
        }
        if (checkAllowed.pageStatus === "NOT RELATED") {
            alert(`Issue detected.`)
            window.location.replace("/selector");
            return;
        }
        alert(`${checkAllowed.dailyLimitReached === Constants.VOLUME_PLUS_TYPE ? "COUNTRY TRENDS" : checkAllowed.dailyLimitReached} - Daily Limit Reached `)
        return;
    }

    setChartsLoaded(false);
    const isIndex = (searchType === Constants.INDEX_TYPE)
    const isVolume = (searchType === Constants.VOLUME_TYPE)
    const isVolumePlus = (searchType === Constants.VOLUME_PLUS_TYPE)
    const isSimilar = (searchType === Constants.SIMILAR_TYPE)

    if (userInfo.isExpired || !userInfo.verified || (isVolume && userInfo.dailyLimitsReached.includes(Constants.VOLUME_TYPE) || (isVolumePlus && userInfo.dailyLimitsReached.includes(Constants.VOLUME_PLUS_TYPE)) || (isSimilar && userInfo.dailyLimitsReached.includes(Constants.SIMILAR_TYPE)))) {
        alert("Not Allowed")
        return;
    }

    setShowGraphText(false);
    if (numberOfRunSearches === 0) {
        $("#loadingIcon").css('display', 'flex');
        resetCharts({ refactoredPost, chartRef, barChartRef, volumeChartRef, areaChartRef, page: Constants.VOLUME_TYPE });
    } else {
        $("#loadingDataText").append('.');
    }

    const getUrl = () => {
        if (isIndex) return environment.localhost ? 'http://localhost:5050/api/searchindex/iotrefactored' : 'https://servershareofsearchindex1.herokuapp.com/api/searchindex/iotrefactored';
        if (isVolume) return environment.localhost ? 'http://localhost:5000/api/searchvolume/searchvolumedata' : 'https://servershareofsearch.herokuapp.com/api/searchvolume/searchvolumedata';
        if (isVolumePlus) return environment.localhost ? 'http://localhost:5000/api/searchvolume/searchvolumedatamulticountry' : 'https://servershareofsearch.herokuapp.com/api/searchvolume/searchvolumedatamulticountry';
        if (isSimilar) return environment.localhost ? 'http://localhost:5051/api/similarsearchindex/similarsearchesredefined' : 'https://servershareofsearchsimilar1.herokuapp.com/api/similarsearchindex/similarsearchesredefined';
        else return null;
    }

    const url = getUrl();
    if (!url) throw new Error("Whoops URL error");


    await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ post: refactoredPost }),
    }).then(response => response.json()).then(body => {
        if (isVolumePlus) {
            body = body.filter(res => res.status !== 'error')
            if (body.length > 0) {
                body.status = "success";
            } else {
                body.status = "error";
            }
        }

        if (isVolumePlus && (body.status === "error" || body[0].status === "error") && numberOfRunSearches < 4) {
            setNumberOfRunSearches(numberOfRunSearches + 1)
            return;
        }
        else if (body.status === "error" && numberOfRunSearches < 4) {
            setNumberOfRunSearches(numberOfRunSearches + 1)
            return;
        } else if (body.status === 'error') {
            const postObj = { 'countryName': refactoredPost.countryName, 'ip': refactoredPost.ip, 'city': refactoredPost.city, 'searchTerms': refactoredPost.searchTerms, 'searchCountry': (isVolumePlus ? refactoredPost.countries.map(e => e.country).join(", ") : refactoredPost.country), 'searchCategory': refactoredPost.category, 'searchStartDate': refactoredPost.startDate, 'searchEndDate': refactoredPost.endDate, 'email': refactoredPost.email, 'searchState': refactoredPost.state, 'searchRegion': refactoredPost.region, 'searchProperty': refactoredPost.property, 'status': body.status, searchType }

            writeToLogTable({ postObj, environment });

            fetch(`https://script.google.com/macros/s/AKfycbwZ3xpZfyU9adFSmokgREhxdkr1LklYD3qu9YA_79DYwLrJ9kKhuMnICLG8BgMoimwc/exec?firstname=${'Search Error - Index'}&&lastname=${sessionStorage.getItem('user')}&&email=${new Date()}&&ip=${refactoredPost.searchTerms}&&creation=${refactoredPost.country + " : " + refactoredPost.category + " : " + refactoredPost.startDate + " - " + refactoredPost.endDate}`)
            alert(!isIndex ? VOLUME_SEARCH_FAILED_TEXT : SEARCH_FAILED_TEXT);
            window.location.reload();
            return;
        }
        const postObj = { 'countryName': refactoredPost.countryName, 'ip': refactoredPost.ip, 'city': refactoredPost.city, 'searchTerms': refactoredPost.searchTerms, 'searchCountry': (isVolumePlus ? refactoredPost.countries.map(e => e.country).join(", ") : refactoredPost.country), 'searchCategory': refactoredPost.category, 'searchStartDate': refactoredPost.startDate, 'searchEndDate': refactoredPost.endDate, 'email': refactoredPost.email, 'searchState': refactoredPost.state, 'searchRegion': refactoredPost.region, 'searchProperty': refactoredPost.property, 'status': body.status, searchType }

        writeToLogTable({ postObj, environment });

        if (isVolumePlus) {
            handleMultiSubmitVolumePlus({
                environment,
                userInfo,
                numberOfRunSearches,
                setShowGraphText,
                refactoredPost,
                chartRef,
                barChartRef,
                volumeChartRef,
                areaChartRef,
                setSearchDataObj,
                setResponseToPostVolume,
                setChartsLoaded,
                setFormSubmitted,
                setNumberOfRunSearches,
                setChartDataObject,
                setDateArray,
                setVolumeArray,
                searchType,
                body
            })
            return;
        }
        if (isIndex) {
            handleMultiSubmitIndex({
                environment,
                userInfo,
                numberOfRunSearches,
                setShowGraphText,
                refactoredPost,
                chartRef,
                barChartRef,
                volumeChartRef,
                areaChartRef,
                setSearchDataObj,
                setResponseToPostVolume,
                setChartsLoaded,
                setFormSubmitted,
                setNumberOfRunSearches,
                setChartDataObject,
                setDateArray,
                setVolumeArray,
                searchType,
                body
            })
            return;
        }

        //Reduce user search volume count;
        const svi = body.response[0].result.map(item => {
            if (!item.monthly_searches) {
                return null
            }
            const sviData = item.monthly_searches.map(data => ({ data: data.search_volume_index, date: `${months()[data.month - 1]} ${data.year}` })).reverse();
            return ({ keyword: item.keyword, sviData })
        }).filter(item => item);

        const returnedSearchTerms = body.response[0].result.map(item => item.keyword);
        setResponseToPostVolume(body.response[0].result);

        setSearchDataObj({ searchedFor: returnedSearchTerms, country: refactoredPost.country, category: refactoredPost.category, startDate: refactoredPost.startDate, endDate: refactoredPost.endDate, stateShorthand: refactoredPost.stateShorthand, regionShorthand: refactoredPost.regionShorthand, stateName: refactoredPost.state, region: refactoredPost.region, property: refactoredPost.property });

        const searchDataObjTemp = { searchedFor: returnedSearchTerms, country: refactoredPost.country, category: refactoredPost.category, startDate: refactoredPost.startDate, endDate: refactoredPost.endDate, stateShorthand: refactoredPost.stateShorthand, regionShorthand: refactoredPost.regionShorthand, stateName: refactoredPost.state, region: refactoredPost.region, property: refactoredPost.property }


        setTimeout(function () {
            var responseArray = body.response[0].result;
            const keywords = [];
            const monthlySearches = [];
            const monthlySearchesConcluded = [];
            const dateArrayConcluded = []
            const dateArray = [];
            const failedKeywords = [];
            let reduceNumber = 0;
            responseArray.forEach((searchData, x) => {
                if (searchData.monthly_searches) {
                    keywords.push(searchData.keyword);
                    searchData.monthly_searches.forEach((item, i) => {
                        if (!monthlySearches[i]) { monthlySearches.push([]) }
                        if (!dateArray[i]) { dateArray.push([]) }
                        monthlySearches[i].push(item.search_volume);
                        if (x === 0) dateArray[i].push(months()[(item.month - 1)] + " " + item.year);
                    })
                } else {
                    // No Data For This Keyword.
                    reduceNumber += 1;
                    failedKeywords.push(searchData.keyword);
                    console.log("no data for: " + searchData.keyword);
                }
            });
            if (body.noResultsWords.length > 0 && !isSimilar) {
                alert("Sorry, no data returned for: " + body.noResultsWords.join(", ") + " - displaying remaining results.");
            }
            monthlySearches.forEach(searches => monthlySearchesConcluded.push(searches.join(",")));
            dateArray.forEach(dates => dateArrayConcluded.push(dates.join(",")));

            if (dateArrayConcluded.length === 0 || monthlySearchesConcluded.length === 0) {
                alert("Sorry, there has been an error. This may be due to a far too restricted/small or far too large (greater than 30 queries) search or an issue with the Google API response.");
                window.location.reload();
            }
            dateArrayConcluded.reverse(); // Needed for searchVolume page charts
            monthlySearchesConcluded.reverse(); // Needed for searchVolume page charts
            setDateArray(dateArrayConcluded);
            setVolumeArray(monthlySearchesConcluded);
            $(".GraphContainer").width('100%');
            setChartDataObject({ nameArray: keywords, volumeArray: monthlySearchesConcluded, dateArray: dateArrayConcluded, property: refactoredPost.property, page: Constants.VOLUME_TYPE, startDate: refactoredPost.startDate, endDate: refactoredPost.endDate })
            if (isVolume) generateTable({ svi, country: refactoredPost.country, start: refactoredPost.startDate, end: refactoredPost.endDate });
            $("#loadingIcon").css('display', 'none');
            setNumberOfRunSearches(0)
            setFormSubmitted(false);
            setChartsLoaded(true)
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }, 4000);
    }).catch(error => console.log("There has been an error: " + error));
};

export const handleMultiSubmitVolumePlus = ({
    environment,
    userInfo,
    numberOfRunSearches,
    setShowGraphText,
    refactoredPost,
    chartRef,
    barChartRef,
    volumeChartRef,
    areaChartRef,
    setSearchDataObj,
    setResponseToPostVolume,
    setChartsLoaded,
    setFormSubmitted,
    setNumberOfRunSearches,
    setChartDataObject,
    setDateArray,
    setVolumeArray,
    searchType,
    body
}) => {

    if ((refactoredPost.countries.length > 0) && refactoredPost.country !== refactoredPost.countries[0].country) refactoredPost.country = refactoredPost.countries[0].country;

    const keywords = [];
    const monthlySearchesConcluded = [];
    const dateArrayConcluded = []
    const svi = []
    const countriesArray = [];

    body.forEach(countryRes => {
        const location = countryRes.response[0].data.location_name || "Worldwide"
        countriesArray.push(location);
        svi.push(countryRes.response[0].result.map(item => {
            if (!item.monthly_searches) {
                return null
            }
            const sviData = item.monthly_searches.map(data => ({ data: data.search_volume_index, date: `${months()[data.month - 1]} ${data.year}` })).reverse();
            return ({ keyword: item.keyword, sviData })
        }).filter(item => item));

        countryRes.svi = svi

        const returnedSearchTerms = countryRes.response[0].result.map(item => item.keyword);
        setResponseToPostVolume(countryRes.response[0].result);

        setSearchDataObj({ searchedFor: returnedSearchTerms, country: refactoredPost.country, category: refactoredPost.category, startDate: refactoredPost.startDate, endDate: refactoredPost.endDate, stateShorthand: refactoredPost.stateShorthand, regionShorthand: refactoredPost.regionShorthand, stateName: refactoredPost.state, region: refactoredPost.region, property: refactoredPost.property });

        setTimeout(function () {
            var responseArray = countryRes.response[0].result;
            const monthlySearches = [];
            const monthlySearchesInnerConc = [];
            const dateArrayInnerConc = [];
            const dateArray = [];
            const failedKeywords = [];
            responseArray.forEach((searchData, x) => {
                if (searchData.monthly_searches) {
                    keywords.push(searchData.keyword);
                    searchData.monthly_searches.forEach((item, i) => {
                        if (!monthlySearches[i]) { monthlySearches.push([]) }
                        if (!dateArray[i]) { dateArray.push([]) }
                        monthlySearches[i].push(item.search_volume);
                        if (x === 0) dateArray[i].push(months()[(item.month - 1)] + " " + item.year);
                    })
                } else {
                    // No Data For This Keyword.
                    failedKeywords.push(searchData.keyword);
                    console.log("no data for: " + searchData.keyword);
                }
            });
            if (countryRes.status === "error") {
                alert('Whoops there has been an error.');
                window.location.reload();
            }
            if (countryRes.noResultsWords.length > 0) {
                alert("Sorry, no data returned for: " + countryRes.noResultsWords.join(", ") + " - displaying remaining results.");
            }
            monthlySearches.forEach(searches => monthlySearchesInnerConc.push(searches.join(",")));
            dateArray.forEach(dates => dateArrayInnerConc.push(dates.join(",")));

            if (dateArrayInnerConc.length === 0 || monthlySearchesInnerConc.length === 0) {
                alert("Sorry, there has been an error. This may be due to a far too restricted/small or far too large (greater than 30 queries) search or an issue with the Google API response.");
                window.location.reload();
            }
            dateArrayInnerConc.reverse(); // Needed for searchVolume page charts
            monthlySearchesInnerConc.reverse(); // Needed for searchVolume page charts
            dateArrayConcluded.push(dateArrayInnerConc);
            monthlySearchesConcluded.push(monthlySearchesInnerConc);
            setNumberOfRunSearches(0);
            // generateTable({ svi, country: refactoredPost.country, start: refactoredPost.startDate, end: refactoredPost.endDate });
        }, 4000);

    })

    const nonReturnedCountries = refactoredPost.countries.filter(country =>
        !countriesArray.some(obj =>
            country.countryShorthand === obj || country.country === obj
        )
    );


    setTimeout(() => {
        setNumberOfRunSearches(0)
        setChartDataObject({ countriesArray, nameArray: [...new Set(keywords)], volumeArray: monthlySearchesConcluded, dateArray: dateArrayConcluded[0], property: refactoredPost.property, page: Constants.VOLUME_PLUS_TYPE, startDate: refactoredPost.startDate, endDate: refactoredPost.endDate, nonReturnedCountries })
        setFormSubmitted(false);
        setChartsLoaded(true)
        setDateArray(dateArrayConcluded);
        setVolumeArray(monthlySearchesConcluded);
        $(".GraphContainer").width('100%');
        $("#loadingIcon").css('display', 'none');
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 5000);

}

export const handleMultiSubmitIndex = ({
    environment,
    userInfo,
    numberOfRunSearches,
    setShowGraphText,
    refactoredPost,
    chartRef,
    barChartRef,
    volumeChartRef,
    areaChartRef,
    setSearchDataObj,
    setResponseToPostVolume,
    setChartsLoaded,
    setFormSubmitted,
    setNumberOfRunSearches,
    setChartDataObject,
    setDateArray,
    setVolumeArray,
    searchType,
    body
}) => {
    setSearchDataObj({ searchedFor: refactoredPost.searchTerms, country: refactoredPost.country, category: refactoredPost.category, startDate: refactoredPost.startDate, endDate: refactoredPost.endDate, stateShorthand: refactoredPost.stateShorthand, regionShorthand: refactoredPost.regionShorthand, stateName: refactoredPost.state, region: refactoredPost.region, property: refactoredPost.property });

    const searchDataObjTemp = { searchedFor: refactoredPost.searchTerms, country: refactoredPost.country, category: refactoredPost.category, startDate: refactoredPost.startDate, endDate: refactoredPost.endDate, stateShorthand: refactoredPost.stateShorthand, regionShorthand: refactoredPost.regionShorthand, stateName: refactoredPost.state, region: refactoredPost.region, property: refactoredPost.property }


    setTimeout(function () {
        var responseArrayVolume = body.mapVolume;
        var dateArrayVolume = [];
        var valuesArrayVolume = [];
        responseArrayVolume.forEach((item, i) => {
            dateArrayVolume.push(item[0]);
            valuesArrayVolume.push(item[1]);
        });
        if (dateArrayVolume.length === 0 || valuesArrayVolume.length === 0) {
            window.location.reload();
        }
        setDateArray(dateArrayVolume);
        setVolumeArray(valuesArrayVolume);
        $(".GraphContainer").width('100%');
        setChartDataObject({ nameArray: refactoredPost.searchTerms, volumeArray: valuesArrayVolume, dateArray: [...dateArrayVolume], property: refactoredPost.property, page: Constants.INDEX_TYPE, startDate: refactoredPost.startDate, endDate: refactoredPost.endDate })
        $("#loadingIcon").css('display', 'none');
        setNumberOfRunSearches(0)
        setFormSubmitted(false);
        setChartsLoaded(true)
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 5000);
}

export const getChartSubTitleString = ({ refactoredPost, page, chart, chartDataObject }) => {
    const volumeChartsWithoutCountryHeader = ["myCountryVolumeTotalChart", "myCountryVolumeChart", "myAreaChart", "myBarChart", "myChart", "myVolumeChart", "myChartForSearchTerm", "myBarChartForSearchTerm"]
    var countryHeader = refactoredPost.country.toUpperCase()
    if (page === Constants.VOLUME_PLUS_TYPE) {
        refactoredPost.category = null;
        if (volumeChartsWithoutCountryHeader.includes(chart)) {
            countryHeader = ""
        }
    }
    return (
        `${countryHeader && `${countryHeader} | `}
      ${refactoredPost.state && refactoredPost.state !== "Not selected" ? refactoredPost.state.toUpperCase() + ' | ' : ''}
        ${refactoredPost.region && refactoredPost.region !== "Not selected" ? refactoredPost.region.toUpperCase() + ' | ' : ''}
          ${refactoredPost.category && (refactoredPost.category !== "Not selected" && refactoredPost.category !== "All") ? refactoredPost?.category?.toUpperCase() + ' | ' : ''}
          ${getDDMMMYYYYGraphDate(chartDataObject?.startDate || refactoredPost.startDate)} - ${getDDMMMYYYYGraphDate(chartDataObject?.endDate || refactoredPost.endDate)}`
    )
}

export const updateSetting = async ({ name, status, environment }) => {
    const response = await fetch(environment.url + 'admin/updatesetting', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ post: { name, status } }),
    });

    var returned = await response.json();
    if (returned.status === "error") {
        alert("Error setting allowed search for reports ");
    }
}
