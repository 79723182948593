import React, { useEffect, useState } from 'react';
import '../Css/Admin.css';
import $ from 'jquery';
import HelperFunctions from '../Helpers/HelperFunctions';
import UserAccountController from '../Components/Admin/UserAccountController';
import { IndexTrendsStatusController } from '../Components/Admin/IndexTrendsStatusController';
import { testEnvironment } from '../Helpers/HelpersConstants';

const AdminNew = () => {

  const environment = testEnvironment(window.location.hostname)
  const isLocal = environment.localhost;
  const user = sessionStorage.getItem('user');
  const sessionId = parseInt(sessionStorage.getItem('id'));
  const currentDate = new Date();
  const [accountInfo, setAccountInfo] = useState()
  const [emailSearch, setEmailSearch] = useState()
  const [settings, setSettings] = useState()
  const [runningManualReports, setRunningManualReports] = useState(false);


  const [totalNumberOfAccountsWithSubscription, setTotalNumberOfAccountsWithSubscription] = useState();

  useEffect(() => {
    if ((user === "ofcharrison@gmail.com" && sessionId === 1000) || (user === "frank@croftanalytics.com" && sessionId === 500) || (user === "wfiharrison@gmail.com" && sessionId === (43 * 500))) {
    } else {
      window.location.replace("/login");
    }

    document.getElementById("reportsInfo").style.display = "none";

    refreshExpired();
    fillLogSearches();
    fillReports();
    getTotalNumberOfUsers();
    downloadAllUsersCsvFile();
    downloadAllLogSearches();
    setReportAlloweds();
    getSettings();
  }, [])

  useEffect(() => {
    if (settings) {
      const slider = document.getElementById("myRange");
      if (settings.some(e => e.Name === "IndexStatus" && e.Status === "ON")) {
        slider.value = 1
        slider.classList.add("IndexStatusSlider");
        slider.classList.add("green");
      }
      if (settings.some(e => e.Name === "IndexStatus" && e.Status === "WARNING")) {
        slider.value = 2
        slider.classList.add("IndexStatusSlider");
        slider.classList.add("orange");
      }
      if (settings.some(e => e.Name === "IndexStatus" && e.Status === "OFF")) {
        slider.value = 3
        slider.classList.add("IndexStatusSlider");
        slider.classList.add("red");
      }
    }
  }, [settings])


  const getTotalNumberOfUsers = async () => {
    const response = await fetch(environment.url + 'getTotalNumberOfUsers');
    const body = await response.json();

    var number = parseInt(body[0].count);

    if (Number.isInteger(number) === false) {
      alert("Cannot retrieve from database (getTotalNumberOfUsers)")
    }

    $("#totalNumberOfAccounts").text("- Total Number of Accounts: " + number);
  }

  const fillLogSearches = async () => {
    const response = await fetch(environment.url + 'readlogsearchtable');

    const body = await response.json();
    const searches = body[0];
    const searchCount = body[1][0].count;
    if (response.status !== 200) throw Error(body.message);
    var countToDate = searches.length
    searches.forEach(item => {
      if (item.SearchedOn != 0) {
        var date = new Date(item.SearchedOn);
        var startDate = new Date(item.SearchStart);
        var endDate = new Date(item.SearchEnd);
        var statusColour = item.Status === "success" ? 'lightgreen' : item.Status === "error" ? 'orangered' : 'transparent';
        date = date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear() + " &nbsp;" + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
        $("#usageDates").append(
          `<p id="date" style="background-color: ${statusColour}">` +
          'Searched on: ' + date + ' | By: ' + item.Email + ' | IP: ' + item.Ip + ' | City: ' +
          item.City + ' | Region: ' + item.Region + '<br />' +
          'Type: ' + (item.SearchType || 'LEGACY') +
          ' | Terms: ' + item.SearchTerms +
          ' | Country: ' + item.SearchCountry +
          ' | State: ' + item.SearchState +
          ' | Region: ' + item.SearchRegion +
          ' | Category: ' + item.SearchCategory +
          ' | Start: ' + startDate.getDate() + "-" + (startDate.getMonth() + 1) + "-" + startDate.getFullYear() +
          ' | End: ' + endDate.getDate() + "-" + (endDate.getMonth() + 1) + "-" + endDate.getFullYear() +
          ` | Status: <b> ${item.Status} </b>` +
          '</p><br />'
        )
      }
    })
    $("#usageTotal").append(
      '<p id="totalSearchCount"><b>Total Search Count:</b> ' + searchCount + '</p>')
    var latestSearch = searches[0];
    if (latestSearch.SearchedOn != null) {
      var statusColour = latestSearch.Status === "success" ? 'lightgreen' : latestSearch.Status === "error" ? 'orangered' : 'transparent';
      var date = new Date(latestSearch.SearchedOn)
      date = date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear() + " &nbsp;" + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
      $("#usageTotal").append(
        `<p id="date" style="background-color: ${statusColour}"><b>Most Recent Search:</b> ` + date + ' | By: ' + latestSearch.Email + ' | IP: ' + latestSearch.Ip + ' | City: ' + latestSearch.City + ' | Region: ' + latestSearch.Region + '<br /><b>Searched For:</b> ' + latestSearch.SearchTerms + ` | Status: <b> ${latestSearch.Status} </b>` + '</p>' +
        '<br />');
    };
  }

  const fillReports = async () => {
    const response = await fetch(environment.url + 'reports/readreportstable');

    const body = await response.json();
    body.reverse().forEach(item => {
      $("#reports").append(
        '<p id="">' +
        ' Email: ' + (item.Email) +
        ' | Created/Last Edited: ' + (item.Date_Created_Edited.substr(0, 10)) +
        ' | Type: ' + (item.Search_Type) +
        ' | Report Name: ' + item.Report_Name +
        ' | Terms: ' + item.Search_Terms +
        ' | Country: ' + item.Search_Country +
        ' | Category: ' + item.Search_Category +
        ' | Search Period: ' + item.Search_Period +
        ' | Report Frequency: ' + item.Report_Frequency +
        ' | Allowed/Sending Report: ' + (item.Allowed === 1 ? "true" : "false") +
        '</p><br />'
      )
    });
    $("#reportsTotal").append(
      '<p id="totalReportsCount"><b>Total Reports Count:</b> ' + body.length + '</p>')
  };

  const getAccountInfo = async (e) => {
    e.preventDefault();
    if (!emailSearch) {
      return;
    }

    const response = await fetch(environment.url + 'getaccountinfobyemail', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ post: { email: emailSearch } }),
    });

    const accountInfoResponse = await response.json();
    if (!accountInfoResponse) {
      alert("Error: email not associated to account.");
      window.location.reload();
    }

    var subEndDate = accountInfoResponse.SubscriptionEndDate;
    var isExpired = function (date1, date2) {
      date1.setHours(0, 0, 0, 0)
      if (date1 > date2) { return (true) }
      return false
    }

    accountInfoResponse.isExpired = isExpired(new Date(), new Date(subEndDate));

    setAccountInfo(accountInfoResponse);
  }

  const getReportsByEmail = async (e) => {
    e.preventDefault();
    const reportsInfoGrid = document.getElementById("reportsInfo");
    reportsInfoGrid.style.display = "grid";
    var email = $("#reportsEmail").val();
    if (email === null || email === "null") {
      return;
    }

    const response = await fetch(environment.url + 'reports/getreportsbyemail', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email: email }),
    });

    const reportsInfoResponse = await response.json();
    if (reportsInfoResponse.length === 0) {
      alert("Error: No reports to show.");
      return
    }
    const reportsInfoDiv = document.getElementById("reportsInfo");
    reportsInfoDiv.innerHTML += `
    <h3 id="reportInfoHeaderText">Report Info: </h3>
    <p id="reportInfoHeaderText">(Id | Title | Type | Terms | Country | Category | Search Period | Allowed/Sending Email)</p>
    `;

    reportsInfoResponse.forEach(report => {
      const reportsToAdd = `<p id="report"><b>${report.Id}</b> | ${report.Report_Name} | ${report.Search_Type} | ${report.Search_Terms} | ${report.Search_Country} | ${report.Search_Category} | ${report.Search_Period} | ${report.Allowed === 1 ? "true" : "false"}</p>`
      reportsInfoDiv.innerHTML += (reportsToAdd);
    })
  }

  const getAllAccountInfoBySubscription = async e => {
    e.preventDefault();
    $("#allAccountsBySubscription").empty();
    var accountInfo = [];
    var subscription = $("#searchUsersWithSubscription").val();
    accountInfo.push(subscription);

    const response = await fetch(environment.url + 'getallaccountsbysubscription', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ post: accountInfo }),
    });
    const allAccountInfoResponse = await response.json();
    if (allAccountInfoResponse.length === 0) return;

    setTotalNumberOfAccountsWithSubscription(allAccountInfoResponse.length)
    var tbodyRef = document.getElementById('allAccountsBySubscriptionTable').getElementsByTagName('tbody')[0];
    tbodyRef.innerHTML = "";


    for (var x = 0; x < allAccountInfoResponse.length; x++) {
      var id = allAccountInfoResponse[x].Id;
      var firstName = allAccountInfoResponse[x].FirstName;
      var lastName = allAccountInfoResponse[x].LastName;
      var email = allAccountInfoResponse[x].Email;
      var country = allAccountInfoResponse[x].Country;
      var company = allAccountInfoResponse[x].Company;
      var jobTitle = allAccountInfoResponse[x].JobTitle;
      var verified = allAccountInfoResponse[x].AllowSearch;
      if (verified === 0) {
        verified = "false";
      } else { verified = "true"; }
      var subscription = allAccountInfoResponse[x].Subscription;
      var subscriptionStartDate = allAccountInfoResponse[x].SubscriptionStartDate.replace("T", " ").replace("Z", " ").slice(0, -5);
      var subscriptionEndDate = allAccountInfoResponse[x].SubscriptionEndDate.replace("T", " ").replace("Z", " ").slice(0, -5);

      var row = tbodyRef.insertRow(0);
      var cell0 = row.insertCell(0);
      var cell1 = row.insertCell(1);
      var cell2 = row.insertCell(2);
      var cell3 = row.insertCell(3);
      var cell4 = row.insertCell(4);
      var cell5 = row.insertCell(5);
      var cell6 = row.insertCell(6);
      cell0.innerHTML = id;
      cell1.innerHTML = firstName + " " + lastName;
      cell2.innerHTML = email;
      cell3.innerHTML = subscription;
      cell4.innerHTML = subscriptionStartDate;
      cell5.innerHTML = subscriptionEndDate;
      cell6.innerHTML = verified;

    }
  };

  const getAllAccountInfoByKeyword = async e => {
    e.preventDefault();
    var accountInfo = { keyword: $("#searchUsersByKeyword").val() };

    const response = await fetch(environment.url + 'getallaccountsbykeyword', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ post: accountInfo }),
    });
    const allAccountInfoResponse = await response.json();
    if (allAccountInfoResponse.length === 0) return;
    setTotalNumberOfAccountsWithSubscription(allAccountInfoResponse.length)

    var tbodyRef = document.getElementById('allAccountsBySubscriptionTable').getElementsByTagName('tbody')[0];
    tbodyRef.innerHTML = "";


    for (var x = 0; x < allAccountInfoResponse.length; x++) {
      var id = allAccountInfoResponse[x].Id;
      var firstName = allAccountInfoResponse[x].FirstName;
      var lastName = allAccountInfoResponse[x].LastName;
      var email = allAccountInfoResponse[x].Email;
      var country = allAccountInfoResponse[x].Country;
      var company = allAccountInfoResponse[x].Company;
      var jobTitle = allAccountInfoResponse[x].JobTitle;
      var verified = allAccountInfoResponse[x].AllowSearch;
      if (verified === 0) {
        verified = "false";
      } else { verified = "true"; }
      var subscription = allAccountInfoResponse[x].Subscription;
      var subscriptionStartDate = allAccountInfoResponse[x].SubscriptionStartDate.replace("T", " ").replace("Z", " ").slice(0, -5);
      var subscriptionEndDate = allAccountInfoResponse[x].SubscriptionEndDate.replace("T", " ").replace("Z", " ").slice(0, -5);

      var row = tbodyRef.insertRow(0);
      var cell0 = row.insertCell(0);
      var cell1 = row.insertCell(1);
      var cell2 = row.insertCell(2);
      var cell3 = row.insertCell(3);
      var cell4 = row.insertCell(4);
      var cell5 = row.insertCell(5);
      var cell6 = row.insertCell(6);
      cell0.innerHTML = id;
      cell1.innerHTML = firstName + " " + lastName;
      cell2.innerHTML = email;
      cell3.innerHTML = subscription;
      cell4.innerHTML = subscriptionStartDate;
      cell5.innerHTML = subscriptionEndDate;
      cell6.innerHTML = verified;

    }
  };

  const setReportAlloweds = async () => {
    const response = await fetch(environment.url + 'reports/editreportsallowed', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const returned = await response.json();
    if (returned.status === "error") {
      alert("Error setting allowed search for reports ");
    }
  }

  const getSettings = async () => {
    const response = await fetch(environment.url + 'admin/getsettings', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    var returned = await response.json();
    returned = returned.map(e => ({ Name: e.Name, Status: e.Status }))
    setSettings(returned);
    if (returned.status === "error") {
      alert("Error setting allowed search for reports ");
    }
  }

  const downloadAllLogSearches = async () => {

    const convertToCSV = (arr) => {
      const array = [Object.keys(arr[0])].concat(arr)

      return array.map(it => {
        return Object.values(it).toString()
      }).join('\n')
    }

    var button = document.createElement("button");
    button.id = "adminCsvDownload"
    button.innerHTML = "Download All Log Searches CSV";
    const onClickUrl = environment.url;

    button.onclick = function () {
      const allLogSearchesCsv = fetch(onClickUrl + 'admin/csvlogsearchtable');
      allLogSearchesCsv.then(function (response) {
        return response.json();
      }).then((data) => {
        var arr = typeof data !== 'object' ? JSON.parse(data) : data;
        var str =
          `${Object.keys(arr[0])
            .map((value) => `"${value}"`)
            .join(',')}` + '\r\n';
        var csvContent = arr.reduce((st, next) => {
          st +=
            `${Object.values(next)
              .map((value) => {
                if (typeof value == 'string') {
                  value = value.replace('#', '');
                }
                return `"${value}"`
              })
              .join(',')}` + '\r\n';
          return st;
        }, str);
        var element = document.createElement('a');
        element.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvContent);
        element.target = '_blank';
        element.download = 'export.csv';
        element.click();
        return null;
      });
    }

    var downloadContainer = document.getElementById("downloadButtonsContainer");
    downloadContainer.appendChild(button);
    return null;

  }

  const refreshExpired = async () => {
    const response = await fetch(environment.url + 'refreshallexpired', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  const downloadAllUsersCsvFile = async () => {
    const response = await fetch(environment.url + 'accessdatabase', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const allUsers = await response.json();

    var button = document.createElement("button");
    button.id = "adminCsvDownload"
    button.innerHTML = "Download All Users CSV";
    button.onclick = function () {

      var rows = [];
      rows.length = (allUsers.length + 1);
      for (var i = 0; i < rows.length; i++) {
        rows[i] = [];
      }
      rows[0].push(["ID", "Email", "FirstName", "LastName", "Country", "Company", "Job Title", "Account Creation Date", "Subscription", "Subscription Start Date", "Subscription End Date", "Allowed Search / Verified", "CreationIpAddress"]);
      for (var x = 1; x < (allUsers.length + 1); x++) {
        var currentUser = allUsers[x - 1];
        rows[x].push(currentUser.Id);
        rows[x].push(currentUser.Email);
        rows[x].push(currentUser.FirstName);
        rows[x].push(currentUser.LastName);
        rows[x].push(currentUser.Country);
        rows[x].push(currentUser.Company);
        rows[x].push(currentUser.JobTitle);
        rows[x].push(currentUser.AccountCreationDate.replace("T", " ").replace("Z", " ").substring(0, 19));
        rows[x].push(currentUser.Subscription);
        rows[x].push(currentUser.SubscriptionStartDate.replace("T", " ").replace("Z", " ").substring(0, 19));
        rows[x].push(currentUser.SubscriptionEndDate.replace("T", " ").replace("Z", " ").substring(0, 19));
        rows[x].push(currentUser.AllowSearch);
        rows[x].push(currentUser.CreationIpAddress);
      }

      let csvContent = "data:text/csv;charset=utf-8,";

      rows.forEach(function (rowArray) {
        let row = rowArray.join(",");
        csvContent += row + "\r\n";
      });

      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "allSOSUsers.csv");
      document.body.appendChild(link); // Required for FF

      link.click(); // This will download the data file named "allSOSUsers.csv".
    }

    var downloadContainer = document.getElementById("downloadButtonsContainer");
    downloadContainer.appendChild(button);
  }

  const individualReports = async (e) => {
    e.preventDefault(); // Prevents the default form submission behavior

    setRunningManualReports(true);

    // Create a FormData object
    const formData = new FormData(e.target);

    // Extract the values
    const email = formData.get('email');
    const frequency = formData.get('frequency');

    // Now you can use these values as needed
    console.log('Email:', email);
    console.log('Frequency:', frequency);

    const response = await fetch('https://servershareofsearchreports.herokuapp.com/manualreports', {
      // const response = await fetch('http://localhost:5053/manualreports', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        type: frequency,
      }),
    });

    // Parse the JSON response
    const data = await response.json();
    if (data.message) {
      setRunningManualReports(false);
    }
    console.log('Server response:', data);

    alert(data.message)

  };


  document.title = "Share Of Searching";
  return (
    <div className="Admin">
      <h3 id="adminPageText">Admin Page <span id="totalNumberOfAccounts"></span></h3>
      <div id="downloadButtonsContainer">
        <button id="adminCsvDownload" onClick={() => window.open('https://servershareofsearchreports.herokuapp.com/', '_blank')} >Run Reports</button><br></br>
      </div>
      <div>
        <form onSubmit={(e) => individualReports(e)}>
          <label>Run reports for individual email (weekly or monthly)</label>
          <input type='text' name='email' placeholder="Customer's email" />
          <select name='frequency' id="adminCsvDownload">
            <option value='WEEKLY'>WEEKLY</option>
            <option value='MONTHLY'>MONTHLY</option>
          </select>
          <button disabled={runningManualReports} type="submit">Submit</button>
        </form>
      </div>
      <IndexTrendsStatusController environment={environment} />
      <div>
        <div className="UsersBySubscriptionSection">
          <form className="Height-50px" id="searchForAllAccountsBySubscriptionForm" onSubmit={(e) => getAllAccountInfoBySubscription(e)}>
            <p className="Margin-0" id="userSearchInputText">Search for all with Subscription Type: </p>
            <select id="searchUsersWithSubscription">
              <option value="FREE">Free</option>
              <option value="PAID">Paid</option>
              <option value="EXPIRED">Expired</option>
              <option value="ADMIN">Admin</option>
            </select>
            <button id="searchForAccountWithSubscription" type="submit">Search</button>
          </form>
          <form className="Height-50px" id="searchForAllAccountsBySubscriptionForm" onSubmit={(e) => getAllAccountInfoByKeyword(e)}>
            <p className="Margin-0" id="userSearchInputText">Search for all by keyword (name/part of email): </p>
            <input id="searchUsersByKeyword" type="text"></input>
            <button id="searchForAccountWithSubscription" type="submit">Search</button>
          </form>
          <div id="tableScroll">
            {totalNumberOfAccountsWithSubscription && <p id="totalNumberOfAccountsWithSubscription">Number of accounts: {totalNumberOfAccountsWithSubscription}</p>}
            {totalNumberOfAccountsWithSubscription &&
              <table id="allAccountsBySubscriptionTable" style={{ width: "100%;" }}>
                <colgroup>
                  <col id="col1" />
                  <col id="col2" />
                  <col id="col3" />
                  <col id="col4" />
                  <col id="col5" />
                  <col id="col6" />
                  <col id="col7" />
                </colgroup>
                <tr id="allAccountsBySubscriptionTableHeaders">
                  <th>ID</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Type</th>
                  <th>Start</th>
                  <th>End</th>
                  <th>Verified</th>
                </tr>
                <tbody>
                </tbody>
              </table>
            }
          </div>
        </div>
        <div className="UserAccountInfoSection">
          <form className="Height-50px" id="searchForAccountForm" onSubmit={(e) => getAccountInfo(e)}>
            <p className="Margin-0" id="userSearchInputText">Search for user by email:</p> &nbsp;
            <input id="userEmail" type="email" onChange={(e) => setEmailSearch(e.target.value)} />
            <button id="searchForAccountByEmail" type="submit">Search</button>
          </form>
          <form className="Height-50px" id="searchForReportsForm" onSubmit={(e) => getReportsByEmail(e)}>
            <p className="Margin-0" id="userSearchInputText">Search for reports by email:</p> &nbsp;
            <input id="reportsEmail" type="email" />
            <button id="searchForReportsByEmail" type="submit">Search</button>
          </form>

          {accountInfo && <UserAccountController accountInfo={accountInfo} environment={environment} />}

          {/* needed --> */}
          <div className="UserReportsController ">
            <div id="reportsInfo">
            </div>
          </div>
        </div>
      </div>

      <div id="usageInfoContainer">
        <h3 id="usageTotalHeader">Latest Search:</h3>
        <div id="usageTotal">
        </div>
        <h3 id="usageDatesHeader">Latest Searches:</h3>
        <div id="usageDates">
        </div>
        <h3 id="reportsHeader">Latest 150 Reports:</h3>
        <div id="reportsTotal">
        </div>
        <div id="reports">
        </div>
      </div>
    </div >
  );
}

export default AdminNew;
