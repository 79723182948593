import '../../Css/Popup.css';

export const PrivacyPolicyPopup = ({ setShowPrivacyPolicyPopup }) => {
    return (
        <div className="Popup">
            <div className="GenericPopupBox" style={{ width: "80%" }}>
                <div id="privacyLoginText">
                    <h1 id="PopupHeaderText">Privacy Policy</h1>
                    <p id="PopupSubHeaderText">The Croft Analytics Share-Of-Searching app stores your email address, session IP address (and city and country derived from it), your search queries, and your other account details which are used for the following purposes:
                    </p>

                    <ul className="GenericBulletPoints">
                        <li>To manage your subscription to Share-Of-Searching.</li>
                        <li>To log your use of Share-Of-Searching so we can better understand how to improve the app.</li>
                        <li>To inform you of major updates.</li>
                    </ul>
                    None of the above information is shared with anyone. You can email <a href="mailto:frank@croftanalytics.com">frank@croftanalytics.com</a> if you would like any of your information to be removed from the list.
                    <br /><br />Croft Analytics does not own any of the data provided within the app, just the code used to make the app function.
                    <br /><br />Users are free to use the app however they like.
                    <br /><br />We are using Google Analytics to track the performance of Share-Of-Searching.
                    <br /><br />For more information about Share-Of-Searching or Croft Analytics please email <a href="mailto:frank@croftanalytics.com">frank@croftanalytics.com</a>
                </div>
                <button type="button" id="closePPButton" onClick={(e) => setShowPrivacyPolicyPopup(false)}>Close</button>
            </div>
        </div>
    );
}