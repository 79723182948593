import PropTypes from 'prop-types';

export const ExpiredSubscriptionPopup = () => (
  <div className="ExpiredSubscriptionPopup">
    <div id="expiredSubscriptionPopupBox">
      <p id="popupExpiredSubscriptionText"><b>YOUR ACCOUNT HAS EXPIRED</b></p>
      <p id="smallExpiredSubscriptionText">You will be taken to your account page where you can extend your access for another 12 months</p><br />
      <button id="cancelOk" onClick={() => {
        window.location.replace("/accountpage");
      }}>OK</button>
    </div>
  </div>
)
