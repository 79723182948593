import React, { useEffect, useRef } from 'react';
import '../Css/ComponentCss/CategoriesSelect.css';
import $ from 'jquery';
import 'selectize/dist/css/selectize.css';
import 'selectize/dist/css/selectize.default.css';
import 'selectize';
import { useLayoutEffect, useState } from 'react';

const CategorySelect = ({ environment, addToRefactoredPost, refactoredPost }) => {
    const selectRef = useRef(null);
    const isVolumeSearchPrem = window.location.pathname === '/countrysearch';
    const selectizeInstanceRef = useRef(null);
    const [currentCategory, setCurrentCategory] = useState('All');
    const [pageJustLoaded, setPageJustLoaded] = useState(true);


    useEffect(() => {
        const fetchDataAndInitializeSelect2 = async () => {
            await getCategories(); // Wait for countries to be fetched

            selectizeInstanceRef.current = $('#category-select').selectize({
                plugins: ["remove_button"],
                maxItems: 1,
                valueField: 'id',
                labelField: 'title',
                searchField: 'title',
                create: false,
                onChange: function (value) {
                    // Get the selected options
                    const selectedOption = () => {
                        var selectedItem = $(selectRef.current).siblings('.selectize-control')[0].innerText;
                        const splitText = selectedItem.split(/[-×]/).map(item => item.trim()).join("");
                        return {
                            categoryId: value,
                            category: splitText
                        };
                    };
                    // If needed, you can call your countryClicked function with the selected values and texts
                    categoryClicked(selectedOption());
                }
            })
        };
        fetchDataAndInitializeSelect2();
    }, []); // Empty dependency array to run the effect only once

    useEffect(() => {
        setTimeout(() => {
            if (currentCategory !== refactoredPost.category) {
                const categorySelectize = $('#category-select')[0]?.selectize;

                if (categorySelectize) {
                    categorySelectize.clear();

                    const existingItems = Object.values(categorySelectize.options) || null;

                    if (existingItems) {
                        var itemToFind = existingItems.find(item => item?.text === refactoredPost.category || item?.title === refactoredPost.category);
                        if (itemToFind) {
                            categorySelectize.addItem(itemToFind.value || itemToFind.id);
                        }
                    }
                } else {
                    console.error('Selectize is not initialized on #category-select');
                }

                setCurrentCategory(refactoredPost.category);
            }
        }, 200);
    }, [refactoredPost])


    const getCategories = async () => {
        const response = await fetch(environment.url + 'allcategoriesjson');
        const body = await response.json();
        if (response.status !== 200) throw Error(body.message);
        var categories = Object.values(body).map(item => Object.values(item))[0];
        categories.forEach(category => {
            $(selectRef.current).append(
                `<option value=${category.id}>${category.name}</option>`
            );
            if (category.children) {
                Object.values(category.children).forEach(subCat => {
                    $(selectRef.current).append(
                        `<option value=${subCat.id}>${subCat.name}</option>`
                    );

                })
            }
        })
    };

    const categoryClicked = (value) => {
        if (currentCategory === value.category) return;
        setCurrentCategory(value.category)
        addToRefactoredPost('category', value.category)
        addToRefactoredPost('categoryId', value.categoryId)
    }

    return (
        <div className="Search-Col-Margins">
            <p id="countryCategoryText">Category:</p>
            <select className="Width-100" id="category-select" ref={selectRef} placeholder="All">
                <option data-value="">All</option>
            </select>

        </div>
    );
};

export default CategorySelect;
