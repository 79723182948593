import React from 'react';
import '../Css/ComponentCss/RatingsImages.css';

const RatingsImages = () => {
    return (
        <div id="ratingImages">
            <a href="https://www.capterra.com/p/10016427/Share-of-Searching/"> <img id="ratingImage" border="0" src="https://assets.capterra.com/badge/13c1c261d86685624bc526a43f2ec2ce.svg?v=2310362&p=362721" alt="capterra rating" /> </a>
            <a href="https://www.getapp.com/business-intelligence-analytics-software/a/share-of-searching/reviews/"> <img id="ratingImage" border="0" src="https://www.getapp.com/ext/reviews_widget/v1/dark/share-of-searching-application" alt="getapp rating" /> </a>
            <a href="https://www.softwareadvice.com/visual-search/share-of-searching-profile/reviews/"> <img id="ratingImage" border="0" src="https://badges.softwareadvice.com/reviews/5aadffa7-ee17-45e7-83ba-5e96a0bc00bc" alt="software advice rating" /> </a>
        </div>
    )
}

export default RatingsImages