import { useState } from 'react';
import '../Css/ComponentCss/EnquireFeedbackForm.css'
import { PropTypes } from 'prop-types';
import $ from 'jquery';

const EnquireFeedbackForm = ({ environmentUrl }) => {

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [alert, setAlert] = useState(false);

    const submitForm = async (e) => {
        e.preventDefault();
        $("#fname, #email, #subject, #message").css("border", "none");
        const stateObj = { firstName, email, subject, message };
        const asArray = Object.entries(stateObj);
        const emptyValuesArray = asArray.filter(([key, value]) => value === null || value === "");
        if (emptyValuesArray.length > 0) {
            setAlert(true);
            setTimeout(() => {
                $('#alertText').text("Empty values: ");
                emptyValuesArray.forEach(element => {
                    $('#alertText').append(`${element[0] === 'firstName' ? 'first name' : element[0]}, `)
                    if (element[0] === 'firstName') {
                        $('#fname').css({ "border": "solid red" })
                    }
                    if (element[0] === 'email') {
                        $('#email').css({ "border": "solid red" })
                    }
                    if (element[0] === 'subject') {
                        $('#subject').css({ "border": "solid red" })
                    }
                    if (element[0] === 'message') {
                        $('#message').css({ "border": "solid red" })
                    }
                });
            }, 100);
        } else {
            setAlert(false);
            const name = firstName + " " + lastName;
            const response = await fetch(environmentUrl + "emails/sendEnquireFeedbackEmail", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ post: { name, email, subject, message } }),
            });
            const body = await response.json();
            if (body === true) {
                setSubmitted(true);
            }
        }
    }

    return (
        <div class="EFcontainer">
            {alert && (
                <div class="AlertDiv">
                    <h3 id="alertText"></h3>
                </div>
            )}
            <form action="action_page.php">

                {!submitted && (
                    <>
                        <div class="EFfirstlastnames">
                            <div class="EFfirstName">
                                <label for="fname" id="fnameLabel">First Name</label>
                                <input required type="text" id="fname" name="firstname" placeholder="First name" onChange={(e) => setFirstName(e.target.value)} />
                            </div>
                            <div class="EFlastName">
                                <label for="lname" id="lnameLabel">Last Name</label>
                                <input type="text" id="lname" name="lastname" placeholder="Last name" onChange={(e) => setLastName(e.target.value)} />
                            </div>
                        </div>

                        <label for="email" id="emailLabel">Email</label>
                        <input required type="text" id="email" name="email" placeholder="Your email" onChange={(e) => setEmail(e.target.value)} />


                        <label for="subject" id="subjectLabel">Subject</label>
                        <input required type="text" id="subject" name="subject" placeholder="Subject" onChange={(e) => setSubject(e.target.value)} />

                        <label for="message" id="messageLabel">Message</label>
                        <textarea required id="message" name="message" placeholder="Enquire/Feedback" onChange={(e) => setMessage(e.target.value)} style={{ height: 75 }}></textarea>
                    </>
                )}

                {!submitted ? (
                    <input id="submitionButton" type="submit" value="Submit" onClick={(e) => submitForm(e)} />
                ) : (
                    <input id="submitionCompleteButton" type="submit" value="Submitted &#10003;" disabled />
                )}

            </form>
        </div>
    )
}

EnquireFeedbackForm.propTypes = {
    environmentUrl: PropTypes.string,
};

export default EnquireFeedbackForm;