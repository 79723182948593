import React, { useState, useEffect } from 'react';
import '../Css/HomePage.css';
import logo from '../../images/ShareOfSearching.png';
import volumePhoneGraph from '../../images/volumeSportsGraph.png';
import indexPhoneGraph from '../../images/percSportsGraph.jpg';
import similarPhoneGraph from '../../images/similarSportsGraph.jpg';
import countryPhoneGraph from '../../images/countrySportsGraph.png';
import athleticFootwareVsSos from '../../images/athleticFootwareVsSos.jpg';
import { Footer } from "../Components/Footer.js";
import { NewFeatureHomepage } from "../Components/NewFeatureHomepage.js";
import EnquireFeedbackForm from '../Components/EnquireFeedbackForm.js';
import Pricing from '../Components/Pricing.js';
import { ClickImagePopup } from '../Components/Popups/ClickImagePopup.js';
import Testimonials from '../Components/Testimonials.js';
import HelperFunctions from '../Helpers/HelperFunctions.js';
import { HeroImage } from '../Components/HeroImage.js';
import RatingsImages from '../Components/RatingsImages.js';

const HomePage = () => {

  const todaysTrendingSearches = [];

  const [environmentUrl, setEnvironmentUrl] = useState("");
  const [showGraphPopup, setShowGraphPopup] = useState({ show: false, image: null, alt: "" });

  const [scrollDown, setScrollDown] = useState(false); // Lazyload lower images


  useEffect(() => {
    const helperFunctions = new HelperFunctions();
    sessionStorage.setItem('user', null);

    setEnvironmentUrl(helperFunctions.testEnvironment(window.location.hostname).url);

    document.getElementById('navBar')?.remove();

    // window.onscroll = () => {
    // setScrollDown(true);
    // };

    // var header = document.getElementById("sideNav");
    // var sticky = header.offsetTop;

    // const myFunction = () => {
    //   if (window.pageYOffset > sticky) {
    //     header.classList.add("StickyHeader");
    //   } else {
    //     header.classList.remove("StickyHeader");
    //   }
    // }
  }, []);



  const createAccountClicked = async (e) => {
    e.preventDefault(e);
    window.location.href = ("/createaccount");

  }

  const loginClicked = async (e) => {
    e.preventDefault(e);
    window.location.href = ("/login");
  }

  // async getTodaysTrendingSearches(){
  //   const response = await fetch('http://localhost:5000/api/todaystrendingsearches', {
  //     method: 'GET',
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //   });
  //   const allSearches = await response.json();
  //   allSearches.forEach((item, i) => {
  //     this.todaysTrendingSearches.push(item);
  //     $("#topTrendingSearches").append(item + "   ,   ");
  //   });
  //   $("#topTrendingSearches").text($("#topTrendingSearches").text().slice(0,-5));
  //   // this.multiIOTSubmit(this);
  // }


  document.title = "Share Of Searching";
  const headerColor = { color: "rgb(47, 85, 151)" };

  return (
    <div className="HomePage">
      <div id="outsideNavHider">
        <div id="sideNav">
          <div id="navContainer">
            <a href="https://www.croftanalytics.com">
              <img src={logo} alt="Logo" id="sosingImageHomePage" />
            </a>
            <div id="navButtons">
              <a href="#aboutSection" id="pageButton">About</a>
              <a href="#pricingDiv" id="pageButton">Pricing</a>
              <a href="#contactDiv" id="pageButton">Contact</a>
              {/* <p id="buttonBreaker" >|</p> */}
              <button type="button" className="Text-green" id="pageButton" onClick={(e) => createAccountClicked(e)}>Free Trial</button>
              <button type="button" className="Text-green" id="pageButton" onClick={(e) => loginClicked(e)}>Log In</button>
            </div>
          </div>
        </div>
        <HeroImage createAccountClicked={createAccountClicked} loginClicked={loginClicked} />
      </div>

      <NewFeatureHomepage show={false} />
      <RatingsImages />

      <div id="aboutSection">
        <header class="AboutShareOfSearchingDiv">
          {/* <h1 id="aboutHeaderTitleText">ABOUT SHARE OF SEARCHING</h1> */}
          <h1 id="aboutShareOfSearchingText"><b>
            Share of Searching is a search trends tool for charting trends in search volumes and % share of search for any list of
            keywords/search terms. Use it to explore search trends in any country or worldwide for brands, companies, events,
            celebrities, topics etc.
          </b><br /></h1>

          <h1 id="aboutShareOfSearchingText"><b>
            Marketers can discover trends in search interest for all brands in their categories. In many categories, a brand’s share of
            category search can be used to predict its market share. Share of Searching also reveals the search terms related to a
            brand (or other search term) and the trend in search interest for all related terms.
          </b><br /></h1>
        </header>
        <div class="HP-Border-Break" />
        <Testimonials />
        <div id="aboutGraphsSection">

          <h1 id="aboutHeaderTitleText"><b>Four ways to explore search trends in Share of Searching</b></h1>

          {showGraphPopup.show && <ClickImagePopup updateParent={() => setShowGraphPopup({ show: false, image: null, alt: '' })} image={showGraphPopup.image} alt={showGraphPopup.image} />}

          <div id="aboutGraphDiv">
            <div id="aboutGraphTextDiv">
              <p id="aboutTextRightTitle" style={headerColor}><b>Volume Trends</b><br /></p>
              <p id="aboutTextRightP">Enter up to 30 search terms at a time to see monthly trends in Google
                search volumes and % share of search for the terms over the last four
                years. Filter by country and date range. Export the data to Excel and
                charts to PowerPoint.</p>
            </div>
            <img id="phoneGraph" src={volumePhoneGraph} alt="Sports Brands Percentage Graph" onClick={() => setShowGraphPopup({ show: true, image: volumePhoneGraph, alt: "Sports Brands Percentage Graph" })} />
          </div>

          <div id="aboutGraphDiv">
            <img id="phoneGraph" src={countryPhoneGraph} alt="Sports Brands Country Graph" onClick={() => setShowGraphPopup({ show: true, image: countryPhoneGraph, alt: "Sports BrandsTotal Percentage Graph" })} />
            <div id="aboutGraphTextDiv">
              <p id="aboutTextRightTitle" style={headerColor}><b>Country Trends</b><br /></p>
              <p id="aboutTextRightP">Select up to five countries and enter up to 30 search terms at
                a time to compare trends in Google search volumes and %
                share of search for the terms across and within countries over
                the last four years. Filter by country and date range. Export
                the data to Excel and charts to PowerPoint.</p>
            </div>
          </div>

          <div id="aboutGraphDiv">
            <div id="aboutGraphTextDiv">
              <p id="aboutTextRightTitle" style={headerColor}><b>Similar Terms</b><br /></p>
              <p id="aboutTextRightP">Enter a single search term (for example a brand name or topic) to
                retrieve related search terms sourced from Google and view charts of
                trends in search volumes and % share of search for all the terms, with
                historic trend data for the last four years. Filter by country and date
                range. Export the data to Excel and charts to PowerPoint.</p>
            </div>
            <img id="phoneGraph" src={similarPhoneGraph} alt="Sports Brands Total Percentage Graph" onClick={() => setShowGraphPopup({ show: true, image: similarPhoneGraph, alt: "Sports BrandsTotal Percentage Graph" })} />
          </div>

          <div id="aboutGraphDiv">
            <img id="phoneGraph" src={indexPhoneGraph} alt="Sports Brands Volume Graph" onClick={() => setShowGraphPopup({ show: true, image: indexPhoneGraph, alt: "Sports Brands Volume Graph" })} />
            <div id="aboutGraphTextDiv">
              <p id="aboutTextRightTitle" style={headerColor}><b> Index Trends</b><br /></p>
              <p id="aboutTextRightP">Enter up to 30 search terms at a time to compare trends in Google or YouTube search volumes (relative index) and % share of search for the terms back to 2004. Filter by country, region, category, and date range. Export the data to Excel and charts to PowerPoint.<br /></p>
            </div>
          </div>

        </div>

        <div class="HP-Border-Break" />

        <h1 id="aboutHeaderTitleTextLower" style={headerColor}>The value of Share of Search data for marketers</h1>

        {/* <p id="aboutTextRightP" style={headerColor}><b>4. My Reports</b><br /></p>
          <p id="aboutTextRightP">Create and manage search trends reports to be automatically sent to your email each week/month, with updated search volumes and % share of search data for up to 100 search terms in each report - specific to any country or worldwide. Marketers can use My Reports to track the daily, weekly, or monthly trend in their brand and competitors search volumes and % share of total category search.</p> */}
        <div id="aboutSectionLower">
          <div id="ValueOfSOSText">
            <p>Analysis of trends in Google search data alongside brand market share data shows that a brand’s % share of category search volume often a) correlates with its market share and b) can also be used to predict its market share (there is a causal relationship between search and purchasing) for brands in many categories. </p>
            <p>The use of share of search data to predict brand market shares particularly applies where search is a key activity in the process of deciding what to buy, for example in categories such as airlines, apparel, automotive, electronic goods, financial services, healthcare, luxury goods, movies, retail, telecoms, travel, and utilities.  </p>
            <p>To download a booklet with category use case examples <a href="https://img1.wsimg.com/blobby/go/10ab9f80-7651-45a5-b821-244016eea05e/downloads/Share%20of%20Searching%20-%20Category%20Use%20Examples.pdf?ver=1669367594089" target="_blank" rel="noreferrer">click here</a>.</p>
          </div>
          <div id="vsSosImageContainer">
            <img id="carSalesGraph" alt="Athletic Footware Graph" src={athleticFootwareVsSos} onClick={() => setShowGraphPopup({ show: true, image: athleticFootwareVsSos, alt: "Athletic Footware Graph" })} />
          </div>
        </div>

        <div class="HP-Border-Break" />
        <Pricing createAccountClicked={createAccountClicked} loginClicked={loginClicked} />

        <div class="HP-Border-Break" />
        <div id="contactDiv">
          <h1 id="contactTitle">CONTACT</h1>
          <p id="contactText">Share of Searching is developed by <a href="https://croftanalytics.com/">Croft Analytics</a>.<br /><br />For more information or to book a demo please contact Frank Harrison - <a href="mailto:frank@croftanalytics.com">frank@croftanalytics.com</a></p>
        </div>

        <EnquireFeedbackForm environmentUrl={environmentUrl} />
        <Footer />
      </div >
    </div>

  );

}

export default HomePage;
